
import Vue from "vue";
import { useStore } from "@/store";
import DialogLavanderia from "../components/lavanderia/DialogLavanderia.vue";
import DialogComodoFinalizado from "../components/DialogComodoFinalizado.vue";
import IlustracaoLavanderia from "../components/lavanderia/IlustracaoLavanderia.vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import CardListUsosLavanderia from "../components/lavanderia/CardListUsosLavanderia.vue";
import CardFormsLavanderia from "../components/lavanderia/CardFormsLavanderia.vue";

export default Vue.extend({
  name: "LavanderiaPage",
  components: {
    DialogLavanderia,
    DialogComodoFinalizado,
    IlustracaoLavanderia,
    ProgressTotalPerCapita,
    CardListUsosLavanderia,
    CardFormsLavanderia,
  },
  data: () => ({
    store: useStore(),
    dialog: false,
    dialogFinalizado: false,
    dialogRedutores: false,
    formSelecionado: null,
  }),
  computed: {
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    lavanderiaFinalizada() {
      return this.store.isLavanderiaDone;
    },
  },
  methods: {
    abreForm(form: string) {
      this.dialog = true;
      this.formSelecionado = form;
    },
    showForm(form: string) {
      this.formSelecionado = form;
    },
    fechaForm() {
      this.dialog = false;
      this.formSelecionado = null;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog && this.lavanderiaFinalizada) {
        this.dialogFinalizado = true;
      }
    },
  },
});
