
import { AcoesEnum } from "@/utils/constants";
import Vue from "vue";
// import ConfirmarButton from "../components/ConfirmarButton.vue";

export default Vue.extend({
  name: "TheDialogConfirmacao",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      acao: null,
    };
  },
  computed: {
    titulo() {
      switch (this.acao) {
        case AcoesEnum.VOLTAR_ETAPA:
          return "Voltar etapa";
        case AcoesEnum.RECOMECAR:
          return "Recomeçar";
        case AcoesEnum.FINALIZAR_ETAPA:
          return "Finalizar etapa";
        default:
          return "";
      }
    },
    icon() {
      switch (this.acao) {
        case AcoesEnum.VOLTAR_ETAPA:
          return "mdi-rewind";
        case AcoesEnum.RECOMECAR:
          return "mdi-refresh";
        case AcoesEnum.FINALIZAR_ETAPA:
          return "mdi-play";
        default:
          return "";
      }
    },
    texto() {
      switch (this.acao) {
        case AcoesEnum.VOLTAR_ETAPA:
          return `Esta ação vai desfazer o preenchimento dos redutores de consumo de água e retornar o aplicativo à etapa de preenchimento de consumo. Deseja prosseguir?`;
        case AcoesEnum.RECOMECAR:
          return `Esta ação vai reiniciar o aplicativo e zerar todo o preenchimento de consumo. Deseja prosseguir?`;
        case AcoesEnum.FINALIZAR_ETAPA:
          return `Deseja finalizar esta etapa e seguir para a próxima?`;
        default:
          return "";
      }
    },
  },
  methods: {
    open(acao: AcoesEnum) {
      this.acao = acao;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
});
