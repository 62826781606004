
import Vue from "vue";
import { useStore } from "@/store";
import PrivadaCaixa from "../../assets/banheiro/privadaCaixa.svg";
import AcionamentoDuplo from "../../assets/redutores/acionamentoDuplo.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import TabsUsuarios from "../TabsUsuarios.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";
import FormLayoutUsuario from "../FormLayoutUsuario.vue";
import FormItem from "../FormItem.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import {
  RedutoresPrivadaEnum,
  UsosBanheiroEnum,
  RedutoresNomes,
  TiposDescargaEnum,
  StatusEtapasEnum,
} from "../../utils/constants";
import { find, some } from "lodash";
import {
  AcionamentosCaixaDupla,
  // AcionamentosRedutorPrivada,
} from "@/utils/dto";
import { getConsumoDescargaMes } from "@/utils/functions";

export default Vue.extend({
  name: "RedutoresListPrivada",
  components: {
    PrivadaCaixa,
    AcionamentoDuplo,
    FormItem,
    FormLayoutUsuario,
    BarrasAntesDepoisRedutor,
    TextSliderInputs,
    TabsUsuarios,
    ConfirmarButton,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresPrivadaEnum,
      redutorSelecionado: null,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
      dialogDistribuir: false,
      pessoas: [],
      form: {
        duplaCompletaDia: 0,
        duplaCurtaDia: 0,
      },
    };
  },
  mounted() {
    this.redutorSelecionado = this.store.getConfigRedutores.privada;
    this.montaPessoas();
    this.selecionaPessoa(this.pessoas[0]);
  },
  computed: {
    pessoasStore() {
      return this.store.getPessoas;
    },
    pessoaSelecionada() {
      return this.pessoas.find((p) => p.selecionada) || null;
    },
    antesRedutor(): number {
      return this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.DESCARGA);
    },
    depoisRedutor(): number {
      // Se tem algum selecionado, calcula valores reduzidos
      if (this.redutorSelecionado) {
        return getConsumoDescargaMes(
          this.store.getConfigRedutores.privadaAcionamentos,
          this.store.getHabitacao,
          this.redutorSelecionado
        );
      } else {
        // senao, valor normal
        return this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.DESCARGA);
      }
    },
    todosCompletos() {
      if (this.pessoas.length === 0) return false;
      return !some(this.pessoas, (p) => p.status !== StatusEtapasEnum.COMPLETA);
    },
  },
  methods: {
    montaPessoas() {
      // esvazia array
      this.pessoas = [];
      // popula array com dados da store
      this.pessoasStore.forEach((ps) => {
        let pessoaRedutor: AcionamentosCaixaDupla;
        // caso ja tenha redutores
        if (this.store.getConfigRedutores.privada) {
          pessoaRedutor = find(
            this.store.getConfigRedutores.privadaAcionamentos,
            (el) => el.pessoaId === ps.id
          );
        }
        this.pessoas.push({
          ...ps,
          status: pessoaRedutor
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
          etapas: [{ status: StatusEtapasEnum.FAZENDO }],
          selecionada: false,
          duplaCompletaDia: pessoaRedutor?.duplaCompletaDia || 0,
          duplaCurtaDia: pessoaRedutor?.duplaCurtaDia || 0,
        });
      });
    },
    selecionaPessoa(pessoa) {
      let indexNovaPessoa;
      this.pessoas.forEach((p, index) => {
        this.$set(this.pessoas[index], "selecionada", false);
        if (pessoa.id === p.id) {
          indexNovaPessoa = index;
        }
      });
      this.form.duplaCompletaDia =
        this.pessoas[indexNovaPessoa].duplaCompletaDia;
      this.form.duplaCurtaDia = this.pessoas[indexNovaPessoa].duplaCurtaDia;
      // Delay para animação
      setTimeout(() => {
        this.$set(this.pessoas[indexNovaPessoa], "selecionada", true);
      }, 200);
    },
    selecionaProxima() {
      const id = this.pessoaSelecionada.id;
      let novaPessoa;
      this.pessoas.forEach((p) => {
        if (p.id !== id && p.status === StatusEtapasEnum.INCOMPLETA) {
          novaPessoa = p;
        }
      });
      // const novaPessoa = this.pessoas.find((p) => p.id === id + 1);
      this.pessoaSelecionada.selecionada = false;
      if (novaPessoa) {
        setTimeout(() => {
          novaPessoa.selecionada = true;
        }, 200);
      }
    },
    completaDistribuicao(pessoa) {
      const totalDistribuido =
        this.form.duplaCompletaDia + this.form.duplaCurtaDia;
      if (totalDistribuido !== this.getDescargasPorDia(pessoa)) {
        this.$root.$emit(
          "toast-warning",
          "Distribuição de descargas incompleta!"
        );
        return;
      }
      pessoa.duplaCompletaDia = this.form.duplaCompletaDia;
      pessoa.duplaCurtaDia = this.form.duplaCurtaDia;
      this.form.duplaCompletaDia = 0;
      this.form.duplaCurtaDia = 0;
      pessoa.status = StatusEtapasEnum.COMPLETA;
      pessoa.etapas[0].status = StatusEtapasEnum.COMPLETA;
      if (!this.todosCompletos) {
        this.selecionaProxima();
      } else {
        const acionamentos = this.pessoas.map(
          (p) =>
            ({
              pessoaId: p.id,
              duplaCompletaDia: p.duplaCompletaDia,
              duplaCurtaDia: p.duplaCurtaDia,
            } as AcionamentosCaixaDupla)
        );
        this.store.setRedutorPrivada(this.redutorSelecionado, acionamentos);
        this.$root.$emit("toast-success", "Distribuição feita");
        this.dialogDistribuir = false;
      }
    },
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
    getDescargasPorDia(pessoa) {
      const tipoDescarga =
        this.store.getConfigHabitacao.tipoDescarga === TiposDescargaEnum.VALVULA
          ? "valvulaDia"
          : "simplesDia";
      return pessoa.dadosBanheiro.descarga[tipoDescarga];
    },
    caixaDuplaClick() {
      if (this.redutorSelecionado) {
        this.dialogDistribuir = true;
      } else {
        this.$root.$emit("toast-error", "Redutor removido");
        this.form.duplaCompletaDia = 0;
        this.form.duplaCurtaDia = 0;
        this.pessoas.forEach((p) => {
          p.status = StatusEtapasEnum.INCOMPLETA;
          p.etapas[0].status = StatusEtapasEnum.FAZENDO;
          p.duplaCompletaDia = 0;
          p.duplaCurtaDia = 0;
        });
        this.store.setRedutorPrivada(null, null);
      }
    },
  },
  watch: {
    dialogDistribuir() {
      if (!this.dialogDistribuir && !this.todosCompletos) {
        this.redutorSelecionado = null;
      }
    },
    pessoaSelecionada() {
      if (this.pessoaSelecionada) {
        this.form.duplaCurtaDia = this.getDescargasPorDia(
          this.pessoaSelecionada
        );
      }
    },
    "form.duplaCompletaDia": {
      handler() {
        this.form.duplaCurtaDia =
          this.getDescargasPorDia(this.pessoaSelecionada) -
          this.form.duplaCompletaDia;
      },
    },
  },
});
