
import Vue from "vue";
import { useStore } from "@/store";
import { getConsumoChuveiroMes } from "@/utils/functions";
import Ducha from "../../assets/banheiro/ducha.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";
import {
  RedutoresDuchaEnum,
  TipoChuveiroEnum,
  UsosBanheiroEnum,
  RedutoresNomes,
} from "../../utils/constants";

export default Vue.extend({
  name: "RedutoresListDucha",
  components: {
    Ducha,
    RestritorVazao,
    BarrasAntesDepoisRedutor,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresDuchaEnum,
      redutorSelecionado: null,
      recemMontado: true,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
    };
  },
  mounted() {
    this.redutorSelecionado = this.store.getConfigRedutores.ducha;
  },
  computed: {
    antesRedutor(): number {
      return this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.CHUVEIRO);
    },
    depoisRedutor(): number {
      const dados = this.store.getPessoas.map(
        (p) => p.dadosBanheiro[UsosBanheiroEnum.CHUVEIRO]
      );
      return getConsumoChuveiroMes(
        TipoChuveiroEnum.DUCHA,
        dados,
        this.store.getHabitacao,
        this.redutorSelecionado
      );
    },
  },
  methods: {
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
  },
  watch: {
    redutorSelecionado() {
      this.store.setRedutorDucha(this.redutorSelecionado || null);
    },
  },
});
