
import Vue from "vue";

export default Vue.extend({
  name: "ButtonFinalizaComodo",
  props: {
    comodoFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    mudaPage() {
      this.$router.push({ name: "SelecionarComodo" });
    },
  },
});
