
import { useStore } from "@/store";
import Vue from "vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import ExpansionResultadosComodos from "../components/ExpansionResultadosComodos.vue";
import StepperResultadosComodos from "../components/StepperResultadosComodos.vue";

export default Vue.extend({
  name: "DialogComodoFinalizado",
  components: {
    ProgressTotalPerCapita,
    ExpansionResultadosComodos,
    StepperResultadosComodos,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
  }),
  computed: {
    todosComodosDone() {
      return this.store.todosComodosDone;
    },
  },
  methods: {
    fecharDialog() {
      this.$emit("input", false);
      this.$router.push({ name: "SelecionarComodo" });
    },
  },
});
