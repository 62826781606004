
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import TemOuNaoTemButtons from "../TemOuNaoTemButtons.vue";
import MaquinaSuperior from "../../assets/lavanderia/maquinaSuperior.svg";
import MaquinaFrontal from "../../assets/lavanderia/maquinaFrontal.svg";
import OpcoesPanel from "../OpcoesPanel.vue";
import SubFormMaquinaRoupas from "./SubFormMaquinaRoupas.vue";
import { useStore } from "@/store";
import { SimOuNaoEnum, TipoMaquinaRoupaEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormMaquinaRoupa",
  components: {
    LayoutCard,
    TemOuNaoTemButtons,
    OpcoesPanel,
    MaquinaSuperior,
    MaquinaFrontal,
    SubFormMaquinaRoupas,
  },
  data() {
    return {
      TipoMaquinaRoupaEnum,
      SimOuNaoEnum,
      selecionado: false,
      store: useStore(),
      itemSelecionado: null,
      fecharPainel: false,
    };
  },
  mounted() {
    this.itemSelecionado = this.store.getConfigHabitacao.tipoMaquinaRoupa;
  },
  computed: {
    tituloOpcoes() {
      if (this.store.getConfigHabitacao.tipoMaquinaRoupa) {
        return "Tipo selecionado";
      }
      return "Selecione um tipo";
    },
    subtitulo() {
      return this.itemSelecionado || null;
    },
    temMaquinaRoupaSimOuNao() {
      if (this.store.getConfigHabitacao.temMaquinaRoupa === true) {
        return SimOuNaoEnum.SIM;
      }
      if (this.store.getConfigHabitacao.temMaquinaRoupa === false) {
        return SimOuNaoEnum.NAO;
      }
      return null;
    },
  },
  methods: {
    setPossuiMaquinaRoupa(valor: boolean) {
      this.store.setMaquinaRoupa(valor);
      this.itemSelecionado = this.store.getConfigHabitacao.tipoMaquinaRoupa;
      if (!valor) {
        this.goToProximoPasso();
      }
    },
    subformClick() {
      if (this.store.getConfigHabitacao.tipoMaquinaRoupa) {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Máquina de lavar roupas completa!");
    },
  },
  watch: {
    itemSelecionado() {
      this.store.setTipoMaquinaRoupa(this.itemSelecionado);
      this.fecharPainel = !this.fecharPainel;
      this.selecionado = true;
    },
  },
});
