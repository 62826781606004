
import Vue from "vue";
import ExpansionRelatorioComodo from "../components/relatorio/ExpansionRelatorioComodo.vue";
import CardResultados from "../components/aguaChuva/CardResultados.vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import DivIconesConsumo from "../components/relatorio/DivIconesConsumo.vue";
import { useStore } from "@/store";
import { formataNumero } from "@/utils/functions";

export default Vue.extend({
  name: "FimPage",
  components: {
    ExpansionRelatorioComodo,
    CardResultados,
    DivIconesConsumo,
    ProgressTotalPerCapita,
  },
  data() {
    return {
      formataNumero,
      store: useStore(),
      litrosPerCapta: 0,
      litrosPorAno: 0,
      galoesPorLinha: 8,
      caminhoesPorLinha: 4,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    numGaloes() {
      return this.litrosPerCapta / 20;
    },
    numCaminhoes() {
      return this.litrosPorAno / 20000;
    },
    fileirasGaloes() {
      const fileiras = [];
      for (const key in [
        ...Array(Math.floor(this.numGaloes / this.galoesPorLinha)).keys(),
      ]) {
        fileiras.push([...Array(this.galoesPorLinha).keys()]);
      }
      if (this.numGaloes % this.galoesPorLinha) {
        fileiras.push([
          ...Array(Math.ceil(this.numGaloes % this.galoesPorLinha)).keys(),
        ]);
      }
      return fileiras;
    },
    fileirasCaminhoes() {
      const fileiras = [];
      for (const key in [
        ...Array(Math.floor(this.numCaminhoes / this.caminhoesPorLinha)).keys(),
      ]) {
        fileiras.push([...Array(this.caminhoesPorLinha).keys()]);
      }
      if (this.numCaminhoes % this.caminhoesPorLinha) {
        fileiras.push([
          ...Array(
            Math.ceil(this.numCaminhoes % this.caminhoesPorLinha)
          ).keys(),
        ]);
      }
      return fileiras;
    },
  },
});
