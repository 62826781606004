
import Vue from "vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import RedutoresListTorneiraLavatorio from "./RedutoresListTorneiraLavatorio.vue";
import RedutoresListPrivada from "./RedutoresListPrivada.vue";
import RedutoresListDucha from "./RedutoresListDucha.vue";
import RedutoresListTorneiraPia from "./RedutoresListTorneiraPia.vue";
import RedutoresListTanque from "./RedutoresListTanque.vue";
import RedutoresListMangueira from "./RedutoresListMangueira.vue";
import RedutoresListCarro from "./RedutoresListCarro.vue";
import RedutoresListDuchaExterna from "./RedutoresListDuchaExterna.vue";
import {
  FormsBanheiroEnum,
  FormsCozinhaEnum,
  FormsLavanderiaEnum,
  FormsAreaExternaEnum,
} from "../../utils/constants";

export default Vue.extend({
  name: "CardRedutoresLists",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    usoSelecionado: {
      type: String,
      default: null,
    },
    comHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RedutoresListTanque,
    RedutoresListTorneiraLavatorio,
    RedutoresListPrivada,
    RedutoresListDucha,
    RedutoresListTorneiraPia,
    RedutoresListMangueira,
    RedutoresListCarro,
    RedutoresListDuchaExterna,
    ConfirmarButton,
  },
  data() {
    return {
      FormsBanheiroEnum,
      FormsCozinhaEnum,
      FormsLavanderiaEnum,
      FormsAreaExternaEnum,
    };
  },
});
