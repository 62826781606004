
import Vue from "vue";
import { useStore } from "@/store";
import StepUsos from "./StepUsos.vue";
import StepLocalizacao from "./StepLocalizacao.vue";
import StepAreaCaptacao from "./StepAreaCaptacao.vue";
import StepReservatorios from "./StepReservatorios.vue";
import DialogInmetInfo from "./DialogInmetInfo.vue";

export default Vue.extend({
  name: "StepperAguaChuva",
  components: {
    StepReservatorios,
    StepUsos,
    StepLocalizacao,
    StepAreaCaptacao,
    DialogInmetInfo,
  },
  props: {
    stepperKey: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    store: useStore(),
    etapas: 1,
    dialogInmet: false,
  }),
  methods: {
    mostraInfoInmet() {
      this.dialogInmet = true;
    },
  },
  watch: {
    stepperKey() {
      if (this.etapas === 0) {
        this.etapas = 1;
      }
    },
  },
});
