
import Vue from "vue";
import { useStore } from "@/store";
import {
  RedutoresTorneiraExternaEnum,
  RedutoresNomes,
} from "../../utils/constants";
import Mangueira from "../../assets/areaExterna/mangueira.svg";
import Pressurizador from "../../assets/redutores/pressurizador.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";

export default Vue.extend({
  name: "RedutoresListMangueira",
  components: {
    Mangueira,
    Pressurizador,
    BarrasAntesDepoisRedutor,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresTorneiraExternaEnum,
      redutorSelecionado: null,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
    };
  },
  mounted() {
    // TODO: Aqui é preciso checar se já tem pressurizadora
    this.redutorSelecionado = this.store.getConfigRedutores.mangueira;
  },
  computed: {
    antesRedutor(): number {
      const soma =
        this.store.getConsumoLavagemPatioMes() +
        this.store.getConsumoLavagemCalcadaMes();
      return soma;
    },
    depoisRedutor(): number {
      const soma =
        this.store.getConsumoLavagemPatioMes(this.redutorSelecionado) +
        this.store.getConsumoLavagemCalcadaMes(this.redutorSelecionado);
      return soma;
    },
  },
  methods: {
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
  },
  watch: {
    redutorSelecionado() {
      this.store.setRedutorMangueira(this.redutorSelecionado || null);
    },
  },
});
