
import Vue from "vue";
import { useStore } from "@/store";
import {
  TipoChuveiroEnum,
  UsosBanheiroEnum,
  FormsBanheiroEnum,
} from "@/utils/constants";
import { formataNumero } from "@/utils/functions";
import { sum } from "lodash";
import TotalDoComodoCard from "../TotalDoComodoCard.vue";
// import TotalDoComodoCardV2 from "../TotalDoComodoCardV2.vue";
import ResultadosCard from "../ResultadosCard.vue";
import Banheira from "../../assets/banheiro/banheira.svg";
import Bide from "../../assets/banheiro/bide.svg";
import Chuveiro from "../../assets/banheiro/chuveiro.svg";
import Ducha from "../../assets/banheiro/ducha.svg";
import Privada from "../../assets/banheiro/privada.svg";
import CubaPia from "../../assets/banheiro/cubaPia.svg";

export default Vue.extend({
  name: "BanheiroResultados",
  components: {
    TotalDoComodoCard,
    // TotalDoComodoCardV2,
    ResultadosCard,
    Banheira,
    Bide,
    Chuveiro,
    Ducha,
    Privada,
    CubaPia,
  },
  data: () => ({
    TipoChuveiroEnum,
    FormsBanheiroEnum,
    formataNumero,
    store: useStore(),
    usoSelecionado: null,
  }),
  computed: {
    possuiBanheira() {
      return this.store.getConfigHabitacao.temBanheira;
    },
    possuiBide() {
      return this.store.getConfigHabitacao.temBide;
    },
    tipoChuveiro() {
      return this.store.getConfigHabitacao.tipoChuveiro;
    },
    banheira() {
      return this.possuiBanheira
        ? parseInt(
            this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BANHEIRA)
          )
        : 0;
    },
    descarga() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.DESCARGA)
      );
    },
    chuveiro() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.CHUVEIRO)
      );
    },
    bide() {
      return this.possuiBide
        ? parseInt(this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BIDE))
        : 0;
    },
    piaLavatorio() {
      const sumVal = sum([
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BARBEAMENTO),
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.LAVAGEM_MAOS),
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.ESCOVACAO),
      ]);
      return parseInt(`${sumVal}`);
    },
    // COM REDUTOR
    descargaRedutor() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.DESCARGA, true)
      );
    },
    chuveiroRedutor() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.CHUVEIRO, true)
      );
    },
    piaLavatorioRedutor() {
      const sumVal = sum([
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BARBEAMENTO, true),
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.LAVAGEM_MAOS,
          true
        ),
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.ESCOVACAO, true),
      ]);
      return parseInt(`${sumVal}`);
    },
    total() {
      const consumo: number =
        (this.possuiBanheira ? this.banheira : 0) +
        this.piaLavatorio +
        this.descarga +
        this.chuveiro +
        (this.possuiBide ? this.bide : 0);
      return consumo;
    },
    totalRedutor() {
      const consumo: number =
        (this.possuiBanheira ? this.banheira : 0) +
        this.piaLavatorioRedutor +
        this.descargaRedutor +
        this.chuveiroRedutor +
        (this.possuiBide ? this.bide : 0);
      return consumo;
    },
  },
});
