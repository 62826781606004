import Vue from "vue";
import VueRouter from "vue-router";
import BanheiroPage from "../pages/BanheiroPage.vue";
import CozinhaPage from "../pages/CozinhaPage.vue";
import LavanderiaPage from "../pages/LavanderiaPage.vue";
import AreaExternaPage from "../pages/AreaExternaPage.vue";
import BanheiroRedutoresPage from "../pages/BanheiroRedutoresPage.vue";
import CozinhaRedutoresPage from "../pages/CozinhaRedutoresPage.vue";
import LavanderiaRedutoresPage from "../pages/LavanderiaRedutoresPage.vue";
import AreaExternaRedutoresPage from "../pages/AreaExternaRedutoresPage.vue";
import HabitacaoPage from "../pages/HabitacaoPage.vue";
import TipoUsoPage from "../pages/TipoUsoPage.vue";
import SelecionarComodo from "../pages/SelecionarComodo.vue";
import ResultadosPage from "../pages/ResultadosPage.vue";
import LandingPage from "../pages/LandingPage.vue";
import RedutoresInfo from "../pages/RedutoresInfo.vue";
import AguaChuva from "../pages/AguaChuva.vue";
import FimPage from "../pages/FimPage.vue";
import InfoPage from "../pages/InfoPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/info",
    name: "InfoPage",
    component: InfoPage,
  },
  {
    path: "/habitacao",
    name: "HabitacaoPage",
    component: HabitacaoPage,
  },
  {
    path: "/tipo_uso",
    name: "TipoUsoPage",
    component: TipoUsoPage,
  },
  {
    path: "/banheiro",
    name: "BanheiroPage",
    component: BanheiroPage,
  },
  {
    path: "/cozinha",
    name: "CozinhaPage",
    component: CozinhaPage,
  },
  {
    path: "/lavanderia",
    name: "LavanderiaPage",
    component: LavanderiaPage,
  },
  {
    path: "/area_externa",
    name: "AreaExternaPage",
    component: AreaExternaPage,
  },
  {
    path: "/banheiro_redutores",
    name: "BanheiroRedutoresPage",
    component: BanheiroRedutoresPage,
  },
  {
    path: "/cozinha_redutores",
    name: "CozinhaRedutoresPage",
    component: CozinhaRedutoresPage,
  },
  {
    path: "/lavanderia_redutores",
    name: "LavanderiaRedutoresPage",
    component: LavanderiaRedutoresPage,
  },
  {
    path: "/area_externa_redutores",
    name: "AreaExternaRedutoresPage",
    component: AreaExternaRedutoresPage,
  },
  {
    path: "/selecionar_comodo",
    name: "SelecionarComodo",
    component: SelecionarComodo,
  },
  {
    path: "/resultados",
    name: "ResultadosPage",
    component: ResultadosPage,
  },
  {
    path: "/redutores",
    name: "RedutoresInfo",
    component: RedutoresInfo,
  },
  {
    path: "/agua_chuva",
    name: "AguaChuva",
    component: AguaChuva,
  },
  {
    path: "/fim",
    name: "FimPage",
    component: FimPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
