
import Vue from "vue";
import CozinhaBase from "../../assets/cozinha/base.svg";
import MaquinaLouca from "../../assets/cozinha/maquinaLouca.svg";
import Pia from "../../assets/cozinha/pia.svg";
import PiaAgua from "../../assets/cozinha/piaAgua.svg";
import Geladeira from "../../assets/cozinha/geladeira.svg";
import { FormsCozinhaEnum, EtapasDoPreenchimento } from "../../utils/constants";
import { useStore } from "@/store";
import { mapeiaCoords, montaBaseDim } from "@/utils/functions";

const opacidadeAgua = 75;

export default Vue.extend({
  name: "IlustracaoCozinha",
  props: {
    etapaPreenchimento: {
      type: String,
      default: null,
    },
  },
  components: {
    CozinhaBase,
    MaquinaLouca,
    Pia,
    PiaAgua,
    Geladeira,
  },
  data() {
    return {
      redutores: true,
      // redutores
      redutoresIconSize: 25,
      redutoresIconBefore: "mdi-water-alert",
      redutoresIconAfter: "mdi-water-check",
      redutoresIconColorBefore: "accent", // blue darken-2",
      redutoresIconColorAfter: "green darken-2",
      animationClassIcons:
        "animate__animated animate__shakeY animate__slower animate__infinite",
      // outros
      FormsCozinhaEnum,
      animationClass: "animate__animated animate__bounce",
      opacidadeAgua,
      store: useStore(),
      baseRendered: false,
      hoverImg: null,
      baseDim: {
        left: 0,
        height: 0,
        width: 0,
        scale: 0,
        grayscale: 0,
      },
      mapa: {
        maquinaLouca: [
          367, 461, 393, 446, 499, 509, 504, 606, 547, 642, 578, 696, 491, 745,
          369, 691,
        ],
        pia: [630, 560, 762, 483, 824, 600, 758, 638],
      },
    };
  },
  created() {
    window.addEventListener("resize", this.setBaseDimensions);
  },
  mounted() {
    this.setBaseDimensions();
  },
  computed: {
    tamanhoBase() {
      if (this.$vuetify.breakpoint.mdOnly) {
        return "65vh";
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return "72vh";
      }
      if (this.$vuetify.breakpoint.xlOnly) {
        return "75vh";
      }
      return "auto";
    },
    configRedutores() {
      return this.store.getConfigRedutores;
    },
    etapaRedutores() {
      return this.etapaPreenchimento === EtapasDoPreenchimento.REDUTORES;
    },
    maquinaLouca() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isMaquinaLoucaDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "maquinaLouca" &&
          !this.store.isMaquinaLoucaDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        opacity:
          this.store.getConfigHabitacao.temMaquinaLouca !== false ? 1 : 0.3,
        width: this.baseDim.width * 0.281,
        left: 276 * this.baseDim.scale + this.baseDim.left,
        top: 432 * this.baseDim.scale,
      };
    },
    geladeira() {
      return {
        visivel: true,
        grayscale: 0,
        width: this.baseDim.width * 0.254,
        left: 99 * this.baseDim.scale + this.baseDim.left,
        top: 290 * this.baseDim.scale,
      };
    },
    pia() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isTorneiraPiaDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "pia" && !this.store.isTorneiraPiaDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.187,
        left: 627 * this.baseDim.scale + this.baseDim.left,
        top: 471 * this.baseDim.scale,
        iconLeft: 714 * this.baseDim.scale + this.baseDim.left,
        iconTop: 400 * this.baseDim.scale,
      };
    },
    piaAgua() {
      return {
        visivel: this.store.isTorneiraPiaDone,
        grayscale:
          this.hoverImg === "pia" || this.store.isTorneiraPiaDone ? 0 : 100,
        width: this.baseDim.width * 0.043,
        left: 702 * this.baseDim.scale + this.baseDim.left,
        top: 495 * this.baseDim.scale,
      };
    },
  },
  methods: {
    setBaseDimensions() {
      this.baseRendered = false;
      setTimeout(() => {
        const base = document.getElementById("CozinhaBase");
        if (base) {
          this.baseDim = montaBaseDim(base, 1002);
          this.baseRendered = true;
        }
      }, 100);
    },
    mapeiaCoords(shape: string) {
      return mapeiaCoords(this.mapa[shape], this.baseDim);
    },
  },
});
