
import Vue from "vue";
import BanheiroResultados from "../components/banheiro/BanheiroResultados.vue";
import CozinhaResultados from "../components/cozinha/CozinhaResultados.vue";
import LavanderiaResultados from "../components/lavanderia/LavanderiaResultados.vue";
import AreaExternaResultados from "../components/areaExterna/AreaExternaResultados.vue";
import BanheiroIcon from "../assets/icones/iconeBanheiro.svg";
import CozinhaIcon from "../assets/icones/iconeCozinha.svg";
import LavanderiaIcon from "../assets/icones/iconeLavanderia.svg";
import QuintalIcon from "../assets/icones/iconeQuintal.svg";
import { useStore } from "@/store";
import { HabitacoesEnum } from "@/utils/constants";

export default Vue.extend({
  name: "ExpansionResultadosComodos",
  components: {
    BanheiroIcon,
    CozinhaIcon,
    LavanderiaIcon,
    QuintalIcon,
    BanheiroResultados,
    CozinhaResultados,
    LavanderiaResultados,
    AreaExternaResultados,
  },
  data() {
    return {
      store: useStore(),
      HabitacoesEnum,
    };
  },
});
