
import Vue from "vue";
import { formataNumero } from "../utils/functions";

export default Vue.extend({
  name: "BarrasAntesDepoisRedutor",
  props: {
    antesRedutor: {
      type: Number,
      default: 1,
    },
    depoisRedutor: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formataNumero,
    };
  },
  computed: {
    percentual() {
      return (this.depoisRedutor / this.antesRedutor) * 100;
    },
  },
});
