
import Vue from "vue";
import LavanderiaBase from "../../assets/lavanderia/base.svg";
import Torneira from "../../assets/lavanderia/torneira.svg";
import TorneiraAgua from "../../assets/lavanderia/torneiraAgua.svg";
import TanqueAgua from "../../assets/lavanderia/tanqueAgua.svg";
import Tanque from "../../assets/lavanderia/tanque.svg";
import Balde from "../../assets/lavanderia/balde.svg";
import MaquinaSuperior from "../../assets/lavanderia/maquinaSuperior.svg";
import MaquinaFrontal from "../../assets/lavanderia/maquinaFrontal.svg";
import {
  FormsLavanderiaEnum,
  EtapasDoPreenchimento,
  TipoMaquinaRoupaEnum,
} from "../../utils/constants";
import { useStore } from "@/store";
import { mapeiaCoords, montaBaseDim } from "@/utils/functions";

const opacidadeAgua = 75;

export default Vue.extend({
  name: "IlustracaoLavanderia",
  props: {
    etapaPreenchimento: {
      type: String,
      default: null,
    },
  },
  components: {
    LavanderiaBase,
    MaquinaSuperior,
    MaquinaFrontal,
    Tanque,
    Torneira,
    TorneiraAgua,
    TanqueAgua,
    Balde,
  },
  data() {
    return {
      redutores: true,
      // redutores
      redutoresIconSize: 25,
      redutoresIconBefore: "mdi-water-alert",
      redutoresIconAfter: "mdi-water-check",
      redutoresIconColorBefore: "accent", // blue darken-2",
      redutoresIconColorAfter: "green darken-2",
      animationClassIcons:
        "animate__animated animate__shakeY animate__slower animate__infinite",
      // outros
      FormsLavanderiaEnum,
      animationClass: "animate__animated animate__bounce",
      opacidadeAgua,
      store: useStore(),
      baseRendered: false,
      hoverImg: null,
      baseDim: {
        left: 0,
        height: 0,
        width: 0,
        scale: 0,
        grayscale: 0,
      },
      mapa: {
        maquinaRoupa: [
          333, 566, 360, 611, 492, 690, 495, 908, 343, 991, 181, 898, 181, 655,
        ],
        tanque: [
          927, 564, 1005, 698, 1007, 782, 1061, 912, 1046, 1003, 969, 1002, 793,
          699, 806, 658,
        ],
      },
    };
  },
  created() {
    window.addEventListener("resize", this.setBaseDimensions);
  },
  mounted() {
    this.setBaseDimensions();
  },
  computed: {
    tamanhoBase() {
      if (this.$vuetify.breakpoint.mdOnly) {
        return "65vh";
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return "72vh";
      }
      if (this.$vuetify.breakpoint.xlOnly) {
        return "75vh";
      }
      return "auto";
    },
    configRedutores() {
      return this.store.getConfigRedutores;
    },
    etapaRedutores() {
      return this.etapaPreenchimento === EtapasDoPreenchimento.REDUTORES;
    },
    maquinaSuperior() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.getConsumoHabitacao.maquinaRoupas.feito) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "maquinaRoupa" &&
          !this.store.getConsumoHabitacao.maquinaRoupas.feito
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel:
          this.store.getConfigHabitacao.tipoMaquinaRoupa !==
          TipoMaquinaRoupaEnum.FRONTAL,
        class: classe,
        opacity:
          this.store.getConfigHabitacao.temMaquinaRoupa !== false ? 1 : 0.3,
        width: this.baseDim.width * 0.27,
        left: 180 * this.baseDim.scale + this.baseDim.left,
        top: 570 * this.baseDim.scale,
      };
    },
    maquinaFrontal() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.getConsumoHabitacao.maquinaRoupas.feito) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "maquinaRoupa" &&
          !this.store.getConsumoHabitacao.maquinaRoupas.feito
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel:
          this.store.getConfigHabitacao.tipoMaquinaRoupa ===
          TipoMaquinaRoupaEnum.FRONTAL,
        class: classe,
        opacity:
          this.store.getConfigHabitacao.temMaquinaRoupa !== false ? 1 : 0.3,
        width: this.baseDim.width * 0.27,
        left: 180 * this.baseDim.scale + this.baseDim.left,
        top: 600 * this.baseDim.scale,
      };
    },
    balde() {
      return {
        visivel: true,
        grayscale:
          this.hoverImg === "tanque" || this.store.isTorneiraTanqueDone
            ? 0
            : 100,
        width: this.baseDim.width * 0.087,
        left: 960 * this.baseDim.scale + this.baseDim.left,
        top: 890 * this.baseDim.scale,
      };
    },
    tanque() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isTorneiraTanqueDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "tanque" &&
          !this.store.isTorneiraTanqueDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.181,
        left: 800 * this.baseDim.scale + this.baseDim.left,
        top: 620 * this.baseDim.scale,
        iconLeft: 894 * this.baseDim.scale + this.baseDim.left,
        iconTop: 500 * this.baseDim.scale,
      };
    },
    torneira() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isTorneiraTanqueDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "tanque" &&
          !this.store.isTorneiraTanqueDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.034,
        left: 902 * this.baseDim.scale + this.baseDim.left,
        top: 576 * this.baseDim.scale,
      };
    },
    torneiraAgua() {
      return {
        visivel: this.store.isTorneiraTanqueDone,
        grayscale: 0,
        width: this.baseDim.width * 0.039,
        left: 883 * this.baseDim.scale + this.baseDim.left,
        top: 623 * this.baseDim.scale,
      };
    },
    tanqueAgua() {
      return {
        visivel: this.store.isTorneiraTanqueDone,
        grayscale: 0,
        width: this.baseDim.width * 0.113,
        left: 850 * this.baseDim.scale + this.baseDim.left,
        top: 650 * this.baseDim.scale,
      };
    },
  },
  methods: {
    setBaseDimensions() {
      this.baseRendered = false;
      setTimeout(() => {
        const base = document.getElementById("LavanderiaBase");
        if (base) {
          this.baseDim = montaBaseDim(base, 1159);
          this.baseRendered = true;
        }
      }, 100);
    },
    mapeiaCoords(shape: string) {
      return mapeiaCoords(this.mapa[shape], this.baseDim);
    },
  },
});
