import { render, staticRenderFns } from "./CardRedutoresLists.vue?vue&type=template&id=9c1cdeba&scoped=true&"
import script from "./CardRedutoresLists.vue?vue&type=script&lang=ts&"
export * from "./CardRedutoresLists.vue?vue&type=script&lang=ts&"
import style0 from "./CardRedutoresLists.vue?vue&type=style&index=0&id=9c1cdeba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c1cdeba",
  null
  
)

export default component.exports