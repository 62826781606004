
import Vue from "vue";
import _, { sortBy } from "lodash";
import { estrutura } from "../../scripts/jsFiles/estruturaArquivosChuva";
import { useStore } from "@/store";
import StepperButtons from "./StepperButtons.vue";

export default Vue.extend({
  name: "StepLocalizacao",
  components: {
    StepperButtons,
  },
  data: () => ({
    store: useStore(),
    form: {
      regiao: null,
      estado: null,
      localizacao: null,
    },
    mountOk: false,
    estruturaArquivosInmet: estrutura,
  }),
  mounted() {
    this.form.regiao = this.store.getConfigCaptacaoAgua.regiao;
    this.form.estado = _.find(
      this.estados,
      (el) => el.estado === this.store.getConfigCaptacaoAgua.estado
    );
    const localizacao = _.find(
      this.localizacoes,
      (el) => el.estacao === this.store.getConfigCaptacaoAgua.estacaoInmet
    );
    if (localizacao) {
      this.form.localizacao = localizacao;
    }
    this.mountOk = true;
  },
  computed: {
    estados() {
      const estados = [];
      for (const regiao in this.estruturaArquivosInmet) {
        for (const estado in this.estruturaArquivosInmet[regiao]) {
          estados.push({ regiao, estado });
        }
      }
      return sortBy(estados, ["estado"]);
    },
    localizacoes() {
      if (!this.form.estado) return [];
      const localizacoes =
        this.estruturaArquivosInmet[this.form.regiao][this.form.estado.estado];
      return sortBy(localizacoes, ["estacao"]);
    },
  },
  methods: {
    atualizaForm() {
      this.form.regiao = this.form.estado ? this.form.estado.regiao : null;
      this.form.localizacao = null;
    },
    selecionaLocalizacao() {
      if (!this.form.localizacao) return;
      this.$emit("click-ok");
      this.store.setLocalizacao(
        this.form.estado.regiao,
        this.form.estado.estado,
        this.form.localizacao.estacao,
        this.form.localizacao.arquivo
      );
    },
  },
});
