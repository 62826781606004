
import Vue from "vue";
import AreaExternaBase from "../../assets/areaExterna/base.svg";
import Carro from "../../assets/areaExterna/carro.svg";
import Mangueira from "../../assets/areaExterna/mangueira.svg";
import Chuveiro from "../../assets/areaExterna/chuveiro.svg";
import ChuveiroAgua from "../../assets/areaExterna/chuveiroAgua.svg";
import Plantas from "../../assets/areaExterna/plantas.svg";
import Piscina from "../../assets/areaExterna/piscina.svg";
import {
  FormsAreaExternaEnum,
  EtapasDoPreenchimento,
} from "../../utils/constants";
import { useStore } from "@/store";
import { mapeiaCoords, montaBaseDim } from "@/utils/functions";

const opacidadeAgua = 85;

export default Vue.extend({
  name: "IlustracaoAreaExterna",
  props: {
    etapaPreenchimento: {
      type: String,
      default: null,
    },
  },
  components: {
    AreaExternaBase,
    Carro,
    Mangueira,
    Chuveiro,
    ChuveiroAgua,
    Plantas,
    Piscina,
  },
  data() {
    return {
      redutores: true,
      // redutores
      redutoresIconSize: 25,
      redutoresIconBefore: "mdi-water-alert",
      redutoresIconAfter: "mdi-water-check",
      redutoresIconColorBefore: "accent", // blue darken-2",
      redutoresIconColorAfter: "green darken-2",
      animationClassIcons:
        "animate__animated animate__shakeY animate__slower animate__infinite",
      // outros
      FormsAreaExternaEnum,
      animationClass: "animate__animated animate__bounce",
      opacidadeAgua,
      store: useStore(),
      baseRendered: false,
      hoverImg: null,
      baseDim: {
        left: 0,
        height: 0,
        width: 0,
        scale: 0,
        grayscale: 0,
      },
      mapa: {
        plantas: [
          325, 1006, 396, 936, 505, 894, 587, 935, 677, 1003, 502, 1112,
        ],
        piscina: [288, 610, 435, 690, 429, 804, 215, 925, 68, 843, 64, 737],
        chuveiro: [562, 346, 491, 405, 470, 614, 521, 644, 571, 618],
        mangueira: [597, 487, 591, 597, 686, 636, 688, 520],
        carro: [
          634, 655, 530, 714, 532, 768, 794, 924, 934, 843, 907, 723, 771, 657,
        ],
      },
    };
  },
  created() {
    window.addEventListener("resize", this.setBaseDimensions);
  },
  mounted() {
    this.setBaseDimensions();
  },
  computed: {
    tamanhoBase() {
      if (this.$vuetify.breakpoint.mdOnly) {
        return "65vh";
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return "72vh";
      }
      if (this.$vuetify.breakpoint.xlOnly) {
        return "75vh";
      }
      return "auto";
    },
    configRedutores() {
      return this.store.getConfigRedutores;
    },
    etapaRedutores() {
      return this.etapaPreenchimento === EtapasDoPreenchimento.REDUTORES;
    },
    carro() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isLavagemCarroDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "carro" &&
          !this.store.isLavagemCarroDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.39,
        opacity: this.store.getConfigHabitacao.temCarro !== false ? 1 : 0.3,
        left: 535 * this.baseDim.scale + this.baseDim.left,
        top: 660 * this.baseDim.scale,
        iconLeft: 750 * this.baseDim.scale + this.baseDim.left,
        iconTop: 640 * this.baseDim.scale,
      };
    },
    mangueira() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isMangueiraDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "mangueira" &&
          !this.store.isMangueiraDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.086,
        left: 597 * this.baseDim.scale + this.baseDim.left,
        top: 507 * this.baseDim.scale,
        iconLeft: 605 * this.baseDim.scale + this.baseDim.left,
        iconTop: 430 * this.baseDim.scale,
      };
    },
    chuveiro() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isDuchaExternaDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "chuveiro" &&
          !this.store.isDuchaExternaDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.063,
        opacity:
          this.store.getConfigHabitacao.temDuchaExterna !== false ? 1 : 0.3,
        left: 498 * this.baseDim.scale + this.baseDim.left,
        top: 352 * this.baseDim.scale,
        iconLeft: 484 * this.baseDim.scale + this.baseDim.left,
        iconTop: 300 * this.baseDim.scale,
      };
    },
    chuveiroAgua() {
      return {
        visivel: this.store.isDuchaExternaDone,
        grayscale: 0,
        width: this.baseDim.width * 0.099,
        left: 471 * this.baseDim.scale + this.baseDim.left,
        top: 423 * this.baseDim.scale,
      };
    },
    plantas() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isRegaJardimDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "plantas" &&
          !this.store.isRegaJardimDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.351,
        opacity: this.store.getConfigHabitacao.temJardim !== false ? 1 : 0.3,
        left: 325 * this.baseDim.scale + this.baseDim.left,
        top: 895 * this.baseDim.scale,
      };
    },
    piscina() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isPiscinaDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "piscina" && !this.store.isPiscinaDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.361,
        opacity: this.store.getConfigHabitacao.temPiscina !== false ? 1 : 0.3,
        left: 68 * this.baseDim.scale + this.baseDim.left,
        top: 612 * this.baseDim.scale,
      };
    },
  },
  methods: {
    setBaseDimensions() {
      this.baseRendered = false;
      setTimeout(() => {
        const base = document.getElementById("AreaExternaBase");
        if (base) {
          this.baseDim = montaBaseDim(base, 1000);
          this.baseRendered = true;
        }
      }, 100);
    },
    mapeiaCoords(shape: string) {
      return mapeiaCoords(this.mapa[shape], this.baseDim);
    },
  },
});
