
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import TemOuNaoTemButtons from "../TemOuNaoTemButtons.vue";
import SubFormBide from "./SubFormBide.vue";
import { useStore } from "@/store";
import { SimOuNaoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormBide",
  components: {
    LayoutCard,
    TemOuNaoTemButtons,
    SubFormBide,
  },
  data() {
    return {
      SimOuNaoEnum,
      selecionado: false,
      store: useStore(),
    };
  },
  computed: {
    temBideSimOuNao() {
      if (this.store.getConfigHabitacao.temBide === true) {
        return SimOuNaoEnum.SIM;
      }
      if (this.store.getConfigHabitacao.temBide === false) {
        return SimOuNaoEnum.NAO;
      }
      return null;
    },
  },
  methods: {
    subformClick() {
      if (this.temBideSimOuNao === SimOuNaoEnum.SIM) {
        this.selecionado = true;
      }
    },
    setBide(valor: boolean) {
      this.store.setBide(valor);
      if (!valor) {
        this.selecionado = false;
        this.goToProximoPasso();
      } else {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Bidê completo!");
    },
  },
});
