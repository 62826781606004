
import Vue from "vue";
import { StatusEtapasEnum } from "../utils/constants";

export default Vue.extend({
  name: "EtapasBar",
  props: {
    etapas: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "primary",
    },
    mb: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      StatusEtapasEnum,
      completa: "mdi-checkbox-marked-circle-outline",
      fazendo: "mdi-circle-edit-outline",
      incompleta: "mdi-checkbox-blank-circle-outline",
    };
  },
});
