
import Vue from "vue";

export default Vue.extend({
  name: "IconeFooter",
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
    preenchido: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rota: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    colorBadge() {
      return this.preenchido ? "success" : "orange";
    },
    colorIcon() {
      return "white";
    },
    classBadge() {
      return this.preenchido ? "mx-3 my-2 pt-1" : "mx-3 my-2 pt-1";
    },
    exibirBadge() {
      return this.selecionado || this.preenchido;
    },
  },
  methods: {
    goTo() {
      this.$router.push({
        name: this.rota,
      });
    },
  },
});
