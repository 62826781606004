
import Vue from "vue";
import { useStore } from "@/store";
import StepperButtons from "./StepperButtons.vue";

export default Vue.extend({
  name: "StepAreaCaptacao",
  components: {
    StepperButtons,
  },
  data: () => ({
    store: useStore(),
    area: 0,
  }),
  mounted() {
    this.area = this.store.getConfigCaptacaoAgua.areaCaptacao;
  },
  methods: {
    setArea() {
      this.store.setAreaCaptacao(Number(this.area) || 0);
      this.$emit("ok");
    },
  },
});
