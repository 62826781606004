
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import { getConsumoRegaJardimMes } from "@/utils/functions";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SubFormRegarJardim",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      regaJardimSemana: 0,
      regaJardimMinutos: 0,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.regaJardim.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.regaJardim.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.regaJardimSemana =
      this.store.getConsumoHabitacao.regaJardim.vezesPorSemana;
    this.form.regaJardimMinutos = Number(
      this.store.getConsumoHabitacao.regaJardim.minutosPorUso
    );
  },
  computed: {
    regaJardimCompleta() {
      return this.store.getConsumoHabitacao.regaJardim.feito;
    },
    totalRegaJardim() {
      return getConsumoRegaJardimMes(
        this.form.regaJardimSemana,
        Number(this.form.regaJardimMinutos)
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaJardim() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setConsumoRegaJardim(
        this.form.regaJardimSemana,
        Number(this.form.regaJardimMinutos)
      );
      this.$root.$emit("toast-success");
    },
  },
});
