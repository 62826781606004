
import Vue from "vue";
import CozinhaIcon from "../assets/icones/iconeCozinha.svg";
import LavanderiaIcon from "../assets/icones/iconeLavanderia.svg";
import QuintalIcon from "../assets/icones/iconeQuintal.svg";
import BanheiroIcon from "../assets/icones/iconeBanheiro.svg";
import ButtonProximaEtapa from "../components/ButtonProximaEtapa.vue";
import DialogRedutoresFinalizados from "../components/DialogRedutoresFinalizados.vue";
import {
  HabitacoesEnum,
  ComodosEnum,
  EtapasDoPreenchimento,
  AcoesEnum,
} from "@/utils/constants";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SelecionarComodo",
  components: {
    CozinhaIcon,
    LavanderiaIcon,
    QuintalIcon,
    BanheiroIcon,
    ButtonProximaEtapa,
    DialogRedutoresFinalizados,
  },
  data() {
    return {
      HabitacoesEnum,
      ComodosEnum,
      store: useStore(),
      dialogFimRedutores: false,
    };
  },
  computed: {
    habitacaoStore() {
      return this.store.getHabitacao || null;
    },
    colSize() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 6;
      }
      return this.habitacaoStore === HabitacoesEnum.CASA ? 3 : 4;
    },
    isEtapaRedutores() {
      return (
        this.store.getEtapaPreenchimento === EtapasDoPreenchimento.REDUTORES
      );
    },
    cardTitleClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex justify-center"
        : "d-flex justify-center text-h4";
    },
  },
  methods: {
    selecionaComodo(comodo: string) {
      let route;
      if (this.store.getEtapaPreenchimento === EtapasDoPreenchimento.CONSUMO) {
        switch (comodo) {
          case ComodosEnum.BANHEIRO:
            route = "BanheiroPage";
            break;
          case ComodosEnum.COZINHA:
            route = "CozinhaPage";
            break;
          case ComodosEnum.LAVANDERIA:
            route = "LavanderiaPage";
            break;
          case ComodosEnum.AREA_EXTERNA:
            route = "AreaExternaPage";
            break;
          default:
            break;
        }
      } else if (
        this.store.getEtapaPreenchimento === EtapasDoPreenchimento.REDUTORES
      ) {
        switch (comodo) {
          case ComodosEnum.BANHEIRO:
            route = "BanheiroRedutoresPage";
            break;
          case ComodosEnum.COZINHA:
            route = "CozinhaRedutoresPage";
            break;
          case ComodosEnum.LAVANDERIA:
            route = "LavanderiaRedutoresPage";
            break;
          case ComodosEnum.AREA_EXTERNA:
            route = "AreaExternaRedutoresPage";
            break;
          default:
            break;
        }
      }
      if (route) {
        this.$router.push({ name: route });
      }
    },
    async finalizarEtapa() {
      this.acao = AcoesEnum.FINALIZAR_ETAPA;
      if (
        this.store.getEtapaPreenchimento === EtapasDoPreenchimento.REDUTORES
      ) {
        this.dialogFimRedutores = true;
        return;
      }
      if (await this.$root.$confirm(AcoesEnum.FINALIZAR_ETAPA)) {
        if (
          this.store.getEtapaPreenchimento === EtapasDoPreenchimento.CONSUMO
        ) {
          this.store.iniciaEtapaRedutores();
          this.$router.push({ name: "RedutoresInfo" });
        }
      }
    },
  },
});
