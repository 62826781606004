
import Vue from "vue";
import { useStore } from "@/store";
import { formataNumero } from "@/utils/functions";
import { FormsLavanderiaEnum, TipoMaquinaRoupaEnum } from "@/utils/constants";
import TotalDoComodoCard from "../TotalDoComodoCard.vue";
import ResultadosCard from "../ResultadosCard.vue";
import MaquinaSuperior from "../../assets/lavanderia/maquinaSuperior.svg";
import MaquinaFrontal from "../../assets/lavanderia/maquinaFrontal.svg";
import Torneira from "../../assets/lavanderia/torneira.svg";

export default Vue.extend({
  name: "LavanderiaResultadosStep",
  components: {
    TotalDoComodoCard,
    ResultadosCard,
    MaquinaSuperior,
    MaquinaFrontal,
    Torneira,
  },
  data: () => ({
    formataNumero,
    TipoMaquinaRoupaEnum,
    FormsLavanderiaEnum,
    store: useStore(),
    usoSelecionado: null,
  }),
  computed: {
    possuiMaquinaRoupa() {
      return this.store.getConfigHabitacao.temMaquinaRoupa;
    },
    tipoMaquinaRoupa() {
      return this.store.getConfigHabitacao.tipoMaquinaRoupa;
    },
    maquinaRoupa() {
      return parseFloat(this.store.getConsumoMaquinaRoupaMes);
    },
    tanque() {
      return (
        parseFloat(this.store.getConsumoLavagemRoupasMaoMes()) +
        parseFloat(this.store.getConsumoLavagemPisoMes())
      );
    },
    tanqueRedutor() {
      return (
        parseFloat(this.store.getConsumoLavagemRoupasMaoMes(true)) +
        parseFloat(this.store.getConsumoLavagemPisoMes(true))
      );
    },
    total() {
      return this.tanque + this.maquinaRoupa;
    },
    totalRedutor() {
      return this.tanqueRedutor + this.maquinaRoupa;
    },
  },
});
