import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#065889",
        secondary: "#424242",
        accent: "#AF0050",
        error: "#D73400",
        info: "#2196F3",
        success: "#00974A",
        warning: "#D79E00",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
