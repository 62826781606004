
import Vue from "vue";
import { useStore } from "@/store";
import { RedutoresTanqueEnum, RedutoresNomes } from "../../utils/constants";
import Torneira from "../../assets/lavanderia/torneira.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import Pulverizador from "../../assets/redutores/pulverizador.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";

export default Vue.extend({
  name: "RedutoresListTanque",
  components: {
    Torneira,
    RestritorVazao,
    Pulverizador,
    BarrasAntesDepoisRedutor,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresTanqueEnum,
      redutorSelecionado: null,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
    };
  },
  mounted() {
    this.redutorSelecionado = this.store.getConfigRedutores.tanque;
  },
  computed: {
    antesRedutor(): number {
      const soma =
        this.store.getConsumoLavagemPisoMes() +
        this.store.getConsumoLavagemRoupasMaoMes();
      return soma;
    },
    depoisRedutor(): number {
      const soma =
        this.store.getConsumoLavagemPisoMes(this.redutorSelecionado) +
        this.store.getConsumoLavagemRoupasMaoMes(this.redutorSelecionado);
      return soma;
    },
  },
  methods: {
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
  },
  watch: {
    redutorSelecionado() {
      this.store.setRedutorTanque(this.redutorSelecionado || null);
    },
  },
});
