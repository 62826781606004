var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('DialogRedutoresFinalizados',{on:{"input":function($event){_vm.dialogFimRedutores = false}},model:{value:(_vm.dialogFimRedutores),callback:function ($$v) {_vm.dialogFimRedutores=$$v},expression:"dialogFimRedutores"}}),_c('h1',{class:`text-center ${
      _vm.$vuetify.breakpoint.smAndDown ? 'text-h5 mb-8' : 'text-h3 mb-8'
    }`},[_vm._v(" Selecione um cômodo ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.colSize}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"text-center pa-3",attrs:{"outlined":"","hover":"","height":"100%"},on:{"click":function($event){return _vm.selecionaComodo(_vm.ComodosEnum.BANHEIRO)}}},[_c('v-badge',{attrs:{"overlap":"","color":"success","value":(!_vm.isEtapaRedutores && _vm.store.isBanheiroDone) ||
              (_vm.isEtapaRedutores &&
                _vm.store.getConfigRedutores.redutoresBanheiroDone)},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{staticStyle:{"padding-bottom":"2px"},attrs:{"color":"white","small":""}},[_vm._v(" mdi-check-bold ")])]},proxy:true}],null,true)},[_c('BanheiroIcon',{style:(hover ? 'filter: grayscale(0%)' : 'filter: grayscale(100%)'),attrs:{"width":"15vw","height":"15vw"}})],1),_c('v-card-title',{class:_vm.cardTitleClass,staticStyle:{"white-space":"nowrap"}},[_vm._v(" Banheiro ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.colSize}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"text-center pa-3",attrs:{"outlined":"","hover":"","height":"100%"},on:{"click":function($event){return _vm.selecionaComodo(_vm.ComodosEnum.COZINHA)}}},[_c('v-badge',{attrs:{"overlap":"","color":"success","value":(!_vm.isEtapaRedutores && _vm.store.isCozinhaDone) ||
              (_vm.isEtapaRedutores &&
                _vm.store.getConfigRedutores.redutoresCozinhaDone)},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{staticStyle:{"padding-bottom":"2px"},attrs:{"color":"white","small":""}},[_vm._v(" mdi-check-bold ")])]},proxy:true}],null,true)},[_c('CozinhaIcon',{style:(hover ? 'filter: grayscale(0%)' : 'filter: grayscale(100%)'),attrs:{"width":"15vw","height":"15vw"}})],1),_c('v-card-title',{class:_vm.cardTitleClass,staticStyle:{"white-space":"nowrap"}},[_vm._v(" Cozinha ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.colSize}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"text-center pa-3",attrs:{"outlined":"","hover":"","height":"100%"},on:{"click":function($event){return _vm.selecionaComodo(_vm.ComodosEnum.LAVANDERIA)}}},[_c('v-badge',{attrs:{"overlap":"","color":"success","value":(!_vm.isEtapaRedutores && _vm.store.isLavanderiaDone) ||
              (_vm.isEtapaRedutores &&
                _vm.store.getConfigRedutores.redutoresLavanderiaDone)},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{staticStyle:{"padding-bottom":"2px"},attrs:{"color":"white","small":""}},[_vm._v(" mdi-check-bold ")])]},proxy:true}],null,true)},[_c('LavanderiaIcon',{style:(hover ? 'filter: grayscale(0%)' : 'filter: grayscale(100%)'),attrs:{"width":"15vw","height":"15vw"}})],1),_c('v-card-title',{class:_vm.cardTitleClass,staticStyle:{"white-space":"nowrap"}},[_vm._v(" Lavanderia ")])],1)]}}])})],1),(_vm.habitacaoStore === _vm.HabitacoesEnum.CASA)?_c('v-col',{attrs:{"cols":_vm.colSize}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"text-center pa-3",attrs:{"outlined":"","hover":"","height":"100%"},on:{"click":function($event){return _vm.selecionaComodo(_vm.ComodosEnum.AREA_EXTERNA)}}},[_c('v-badge',{attrs:{"overlap":"","color":"success","value":(!_vm.isEtapaRedutores && _vm.store.isAreaExternaDone) ||
              (_vm.isEtapaRedutores &&
                _vm.store.getConfigRedutores.redutoresAreaExternaDone)},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{staticStyle:{"padding-bottom":"2px"},attrs:{"color":"white","small":""}},[_vm._v(" mdi-check-bold ")])]},proxy:true}],null,true)},[_c('QuintalIcon',{style:(hover ? 'filter: grayscale(0%)' : 'filter: grayscale(100%)'),attrs:{"width":"15vw","height":"15vw"}})],1),_c('v-card-title',{class:_vm.cardTitleClass,staticStyle:{"white-space":"nowrap"}},[_vm._v(" Área externa ")])],1)]}}],null,false,292653909)})],1):_vm._e()],1),(
      (!_vm.isEtapaRedutores && _vm.store.todosComodosDone) ||
      (_vm.isEtapaRedutores && _vm.store.etapaRedutoresDone)
    )?_c('ButtonProximaEtapa',{staticClass:"mt-4",on:{"click":function($event){return _vm.finalizarEtapa()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }