import { render, staticRenderFns } from "./CozinhaResultados.vue?vue&type=template&id=a5c56f6a&scoped=true&"
import script from "./CozinhaResultados.vue?vue&type=script&lang=ts&"
export * from "./CozinhaResultados.vue?vue&type=script&lang=ts&"
import style0 from "./CozinhaResultados.vue?vue&type=style&index=0&id=a5c56f6a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5c56f6a",
  null
  
)

export default component.exports