
import Vue from "vue";
import BanheiroResultadosStep from "./banheiro/BanheiroResultadosStep.vue";
import CozinhaResultadosStep from "./cozinha/CozinhaResultadosStep.vue";
import LavanderiaResultadosStep from "./lavanderia/LavanderiaResultadosStep.vue";
import AreaExternaResultadosStep from "./areaExterna/AreaExternaResultadosStep.vue";
import { useStore } from "@/store";
import { HabitacoesEnum } from "@/utils/constants";

export default Vue.extend({
  name: "StepperResultadosComodos",
  components: {
    BanheiroResultadosStep,
    CozinhaResultadosStep,
    LavanderiaResultadosStep,
    AreaExternaResultadosStep,
  },
  data() {
    return {
      stepper: "0",
      store: useStore(),
      HabitacoesEnum,
    };
  },
  computed: {
    isHabitacaoCasa() {
      return this.store.getHabitacao === HabitacoesEnum.CASA;
    },
  },
  methods: {
    clickStep(event, step: string) {
      if (this.stepper === step) this.stepper = 0;
      else this.stepper = step;
    },
  },
});
