
import Vue from "vue";

import MaquinaLouca from "../../assets/cozinha/maquinaLouca.svg";
import Pia from "../../assets/cozinha/pia.svg";
import { useStore } from "@/store";
import { FormsCozinhaEnum } from "@/utils/constants";

export default Vue.extend({
  name: "CardListUsosCozinha",
  components: {
    MaquinaLouca,
    Pia,
  },
  data() {
    return {
      FormsCozinhaEnum,
      store: useStore(),
    };
  },
});
