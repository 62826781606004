import { defineStore } from "pinia";
import {
  AcionamentosCaixaDupla,
  ConfigCaptacaoAgua,
  ConfigHabitacao,
  ConfigRedutores,
  ConsumoHabitacao,
  PessoaInterface,
  ValoresBanheira,
  ValoresBarbeamento,
  ValoresBide,
  ValoresChuveiro,
  ValoresDescarga,
  ValoresEscovacaoDentes,
  ValoresLavagemMaos,
} from "@/utils/dto";
import {
  ComodosEnum,
  EtapasDoPreenchimento,
  HabitacoesEnum,
  RedutoresDuchaEnum,
  RedutoresPrivadaEnum,
  RedutoresTanqueEnum,
  RedutoresTorneiraExternaEnum,
  RedutoresTorneiraLavatorioEnum,
  RedutoresTorneiraPiaEnum,
  TipoChuveiroEnum,
  TipoMaquinaRoupaEnum,
  TiposDescargaEnum,
  UsosBanheiroEnum,
  UsosSubstituiveisEnum,
} from "@/utils/constants";
import {
  getConsumoBanheiraMes,
  getConsumoBarbearMes,
  getConsumoBideMes,
  getConsumoChuveiroMes,
  getConsumoDescargaMes,
  getConsumoDuchaExternaMes,
  getConsumoEscovarDentesMes,
  getConsumoLavagemAlimentosMes,
  getConsumoLavagemCalcadaMes,
  getConsumoLavagemCarroMes,
  getConsumoLavagemLoucaMes,
  getConsumoLavagemPatioMes,
  getConsumoLavagemPisoMes,
  getConsumoLavagemRoupasMaoMes,
  getConsumoLavarMaoMes,
  getConsumoMaquinaLoucaMes,
  getConsumoMaquinaRoupasMes,
  getConsumoPiscinaMes,
  getConsumoRegaJardimMes,
  getPessoaIndexById,
} from "@/utils/functions";
import { every } from "lodash";

export const useStore = defineStore("store", {
  persist: true,
  state: () => ({
    etapaPreenchimento: EtapasDoPreenchimento.CONSUMO,
    habitacao: null,
    tipoUso: null,
    configHabitacao: {
      temMaquinaLouca: null,
      temBide: null,
      temBanheira: null,
      tipoChuveiro: null,
      tipoDescarga: null,
      temMaquinaRoupa: null,
      tipoMaquinaRoupa: null,
      temCarro: null,
      temPiscina: null,
      temJardim: null,
      temDuchaExterna: null,
      pressurizadorCarro: false,
      pressurizadorPatio: false,
      pressurizadorCalcada: false,
    } as ConfigHabitacao,
    configRedutores: {
      torneiraLavatorio: null,
      ducha: null,
      privada: null,
      piaCozinha: null,
      tanque: null,
      carro: null,
      mangueira: null,
      duchaExterna: null,
      redutoresBanheiroDone: false,
      redutoresCozinhaDone: false,
      redutoresAreaExternaDone: false,
      redutoresLavanderiaDone: false,
      privadaAcionamentos: [],
    } as ConfigRedutores,
    configCaptacaoAgua: {
      areaCaptacao: null,
      regiao: null,
      estado: null,
      estacaoInmet: null,
      arquivoDadosChuva: null,
      reservatorioInferior: null,
      reservatorioSuperior: null,
      usosSelecionados: [],
    } as ConfigCaptacaoAgua,
    pessoas: [],
    consumoHabitacao: {
      maquinaLouca: {
        feito: false,
        vezesPorSemana: 0,
      },
      maquinaRoupas: {
        feito: false,
        vezesPorSemana: 0,
      },
      lavagemPiso: {
        feito: false,
        vezesPorSemana: 0,
        minutosPorUso: 0,
      },
      lavagemRoupasMao: {
        feito: false,
        vezesPorSemana: 0,
        minutosPorUso: 0,
      },
      lavagemLouca: {
        feito: false,
        vezesPorSemana: 0,
        minutosPorUso: 0,
      },
      lavagemAlimentos: {
        feito: false,
        vezesPorSemana: 0,
        minutosPorUso: 0,
      },
      regaJardim: {
        feito: false,
        vezesPorSemana: 0,
        minutosPorUso: 0,
      },
      lavagemCarro: {
        feito: false,
        vezesPorMes: 0,
        minutosPorUso: 0,
      },
      piscina: {
        feito: false,
        volume: 0,
        vezesPorAno: 0,
      },
      duchaExterna: {
        feito: false,
        vezesPorMes: 0,
        minutosPorUso: 0,
      },
      lavagemPatio: {
        feito: false,
        vezesPorMes: 0,
        minutosPorUso: 0,
      },
      lavagemCalcada: {
        feito: false,
        vezesPorMes: 0,
        minutosPorUso: 0,
      },
    } as ConsumoHabitacao,
  }),
  getters: {
    getEtapaPreenchimento: (state) => state.etapaPreenchimento,
    getHabitacao: (state) => state.habitacao,
    getTipoUso: (state) => state.tipoUso,
    getPessoas: (state) => state.pessoas,
    getConfigHabitacao: (state) => state.configHabitacao,
    getConfigRedutores: (state) => state.configRedutores,
    getConsumoHabitacao: (state) => state.consumoHabitacao,
    getConfigCaptacaoAgua: (state) => state.configCaptacaoAgua,
    // VERIFICA ETAPA REDUTORES
    etapaRedutoresDone: (state) => {
      return (
        state.configRedutores.redutoresBanheiroDone &&
        state.configRedutores.redutoresCozinhaDone &&
        state.configRedutores.redutoresAreaExternaDone &&
        state.configRedutores.redutoresLavanderiaDone
      );
    },
    // VERIFICA COMODOS
    todosComodosDone() {
      return (
        this.isBanheiroDone &&
        this.isLavanderiaDone &&
        this.isCozinhaDone &&
        this.isAreaExternaDone
      );
    },
    isLavanderiaDone() {
      return (
        this.isMaquinaRoupaDone &&
        this.isLavagemPisoDone &&
        this.isLavagemRoupasMaoDone
      );
    },
    isCozinhaDone() {
      return this.isMaquinaLoucaDone && this.isTorneiraPiaDone;
    },
    isBanheiroDone() {
      return (
        this.isBanheiraDone &&
        this.isBideDone &&
        this.isChuveiroDone &&
        this.isDescargaDone &&
        this.isTorneiraLavatorioDone
      );
    },
    isAreaExternaDone() {
      if (this.habitacao === HabitacoesEnum.APARTAMENTO) return true;
      return (
        this.isRegaJardimDone &&
        this.isLavagemCarroDone &&
        this.isPiscinaDone &&
        this.isDuchaExternaDone &&
        this.isMangueiraDone
      );
    },
    // GETTERS BANHEIRO
    isUsoBanheiroDone: (state) => {
      return (uso: UsosBanheiroEnum) => {
        if (state.pessoas.length === 0) return false;
        return every(
          state.pessoas,
          (p: PessoaInterface) => p.dadosBanheiro[uso].feito
        );
      };
    },
    isBanheiraDone() {
      if (this.configHabitacao.temBanheira !== false) {
        return this.isUsoBanheiroDone(UsosBanheiroEnum.BANHEIRA);
      }
      return true;
    },
    isBideDone() {
      if (this.configHabitacao.temBide !== false) {
        return this.isUsoBanheiroDone(UsosBanheiroEnum.BIDE);
      }
      return true;
    },
    isChuveiroDone() {
      return this.isUsoBanheiroDone(UsosBanheiroEnum.CHUVEIRO);
    },
    isDescargaDone() {
      return this.isUsoBanheiroDone(UsosBanheiroEnum.DESCARGA);
    },
    isBarbeamentoDone() {
      return this.isUsoBanheiroDone(UsosBanheiroEnum.BARBEAMENTO);
    },
    isLavagemMaosDone() {
      return this.isUsoBanheiroDone(UsosBanheiroEnum.LAVAGEM_MAOS);
    },
    isEscovacaoDentesDone() {
      return this.isUsoBanheiroDone(UsosBanheiroEnum.ESCOVACAO);
    },
    isTorneiraLavatorioDone() {
      return (
        this.isLavagemMaosDone &&
        this.isBarbeamentoDone &&
        this.isEscovacaoDentesDone
      );
    },
    getConsumoUsoBanheiroMes(state) {
      return (uso: UsosBanheiroEnum, comRedutor = false) => {
        switch (uso) {
          case UsosBanheiroEnum.BANHEIRA:
            return getConsumoBanheiraMes(
              state.pessoas.map((p) => p.dadosBanheiro[uso]),
              state.habitacao
            );
          case UsosBanheiroEnum.BIDE:
            return getConsumoBideMes(
              state.pessoas.map((p) => p.dadosBanheiro[uso]),
              state.habitacao
            );
          case UsosBanheiroEnum.CHUVEIRO:
            return getConsumoChuveiroMes(
              state.configHabitacao.tipoChuveiro as TipoChuveiroEnum,
              state.pessoas.map((p) => p.dadosBanheiro[uso]),
              state.habitacao,
              comRedutor &&
                state.configHabitacao.tipoChuveiro === TipoChuveiroEnum.DUCHA
                ? state.configRedutores.ducha
                : null
            );
          case UsosBanheiroEnum.DESCARGA:
            // aplica redutores se estiverem setados
            if (comRedutor && state.configRedutores.privada) {
              return getConsumoDescargaMes(
                state.configRedutores.privadaAcionamentos,
                state.habitacao,
                comRedutor
              );
            } else {
              return getConsumoDescargaMes(
                state.pessoas.map((p) => p.dadosBanheiro[uso]),
                state.habitacao
              );
            }
          case UsosBanheiroEnum.BARBEAMENTO:
            return getConsumoBarbearMes(
              state.pessoas.map((p) => p.dadosBanheiro[uso]),
              state.habitacao,
              comRedutor ? state.configRedutores.torneiraLavatorio : null
            );
          case UsosBanheiroEnum.ESCOVACAO:
            return getConsumoEscovarDentesMes(
              state.pessoas.map((p) => p.dadosBanheiro[uso]),
              state.habitacao,
              comRedutor ? state.configRedutores.torneiraLavatorio : null
            );
          case UsosBanheiroEnum.LAVAGEM_MAOS:
            return getConsumoLavarMaoMes(
              state.pessoas.map((p) => p.dadosBanheiro[uso]),
              state.habitacao,
              comRedutor ? state.configRedutores.torneiraLavatorio : null
            );
          default:
            break;
        }
      };
    },
    // GETTERS LAVANDERIA
    isMaquinaRoupaDone: (state) => state.consumoHabitacao.maquinaRoupas.feito,
    isLavagemPisoDone: (state) => state.consumoHabitacao.lavagemPiso.feito,
    isLavagemRoupasMaoDone: (state) =>
      state.consumoHabitacao.lavagemRoupasMao.feito,
    isTorneiraTanqueDone: (state) =>
      state.consumoHabitacao.lavagemPiso.feito &&
      state.consumoHabitacao.lavagemRoupasMao.feito,
    getConsumoMaquinaRoupaMes: (state) => {
      if (
        state.configHabitacao.temMaquinaRoupa &&
        state.configHabitacao.tipoMaquinaRoupa
      ) {
        return getConsumoMaquinaRoupasMes(
          state.consumoHabitacao.maquinaRoupas.vezesPorSemana,
          state.configHabitacao.tipoMaquinaRoupa
        );
      }
      return 0;
    },
    getConsumoLavagemRoupasMaoMes(state) {
      return (comRedutor = false) => {
        return getConsumoLavagemRoupasMaoMes(
          state.consumoHabitacao.lavagemRoupasMao.vezesPorSemana,
          state.consumoHabitacao.lavagemRoupasMao.minutosPorUso,
          state.habitacao,
          comRedutor ? state.configRedutores.tanque : null
        );
      };
    },
    getConsumoLavagemPisoMes(state) {
      return (comRedutor = false) => {
        return getConsumoLavagemPisoMes(
          state.consumoHabitacao.lavagemPiso.vezesPorSemana,
          state.consumoHabitacao.lavagemPiso.minutosPorUso,
          state.habitacao,
          comRedutor ? state.configRedutores.tanque : null
        );
      };
    },
    // GETTERS COZINHA
    isLavagemLoucaDone: (state) => state.consumoHabitacao.lavagemLouca.feito,
    isLavagemAlimentosDone: (state) =>
      state.consumoHabitacao.lavagemAlimentos.feito,
    isMaquinaLoucaDone: (state) => state.consumoHabitacao.maquinaLouca.feito,
    isTorneiraPiaDone: (state) =>
      state.consumoHabitacao.lavagemLouca.feito &&
      state.consumoHabitacao.lavagemAlimentos.feito,
    getConsumoMaquinaLoucaMes: (state) => {
      if (state.configHabitacao.temMaquinaLouca) {
        return getConsumoMaquinaLoucaMes(
          state.consumoHabitacao.maquinaLouca.vezesPorSemana,
          state.habitacao
        );
      }
      return 0;
    },
    getConsumoLavagemLoucaMes(state) {
      return (comRedutor = false) => {
        return getConsumoLavagemLoucaMes(
          state.consumoHabitacao.lavagemLouca.vezesPorSemana,
          state.consumoHabitacao.lavagemLouca.minutosPorUso,
          state.habitacao,
          comRedutor ? state.configRedutores.piaCozinha : null
        );
      };
    },
    getConsumoLavagemAlimentosMes(state) {
      return (comRedutor = false) => {
        return getConsumoLavagemAlimentosMes(
          state.consumoHabitacao.lavagemAlimentos.vezesPorSemana,
          state.consumoHabitacao.lavagemAlimentos.minutosPorUso,
          state.habitacao,
          comRedutor ? state.configRedutores.piaCozinha : null
        );
      };
    },
    // GETTERS AREA EXTERNA
    isRegaJardimDone: (state) => state.consumoHabitacao.regaJardim.feito,
    isLavagemCarroDone: (state) => state.consumoHabitacao.lavagemCarro.feito,
    isPiscinaDone: (state) => state.consumoHabitacao.piscina.feito,
    isDuchaExternaDone: (state) => state.consumoHabitacao.duchaExterna.feito,
    isMangueiraDone: (state) =>
      state.consumoHabitacao.lavagemPatio.feito &&
      state.consumoHabitacao.lavagemCalcada.feito,
    getConsumoRegaJardimMes: (state) => {
      if (state.configHabitacao.temJardim) {
        return getConsumoRegaJardimMes(
          state.consumoHabitacao.regaJardim.vezesPorSemana,
          state.consumoHabitacao.regaJardim.minutosPorUso
        );
      }
      return 0;
    },
    getConsumoPiscinaMes: (state) => {
      if (state.configHabitacao.temPiscina) {
        return getConsumoPiscinaMes(
          state.consumoHabitacao.piscina.volume,
          state.consumoHabitacao.piscina.vezesPorAno
        );
      }
      return 0;
    },
    getConsumoDuchaExternaMes(state) {
      return (comRedutor = false) => {
        if (state.configHabitacao.temDuchaExterna) {
          return getConsumoDuchaExternaMes(
            state.consumoHabitacao.duchaExterna.vezesPorMes,
            state.consumoHabitacao.duchaExterna.minutosPorUso,
            comRedutor ? !!state.configRedutores.duchaExterna : false
          );
        }
        return 0;
      };
    },
    getConsumoLavagemCarroMes(state) {
      return (comRedutor = false) => {
        if (state.configHabitacao.temCarro) {
          return getConsumoLavagemCarroMes(
            state.consumoHabitacao.lavagemCarro.vezesPorMes,
            state.consumoHabitacao.lavagemCarro.minutosPorUso,
            comRedutor && state.configRedutores.carro
              ? true
              : state.configHabitacao.pressurizadorCarro
          );
        }
        return 0;
      };
    },
    getConsumoLavagemPatioMes(state) {
      return (comRedutor = false) => {
        return getConsumoLavagemCalcadaMes(
          state.consumoHabitacao.lavagemPatio.vezesPorMes,
          state.consumoHabitacao.lavagemPatio.minutosPorUso,
          comRedutor && state.configRedutores.mangueira
            ? true
            : state.configHabitacao.pressurizadorPatio
        );
      };
    },
    getConsumoLavagemCalcadaMes(state) {
      return (comRedutor = false) => {
        return getConsumoLavagemCalcadaMes(
          state.consumoHabitacao.lavagemCalcada.vezesPorMes,
          state.consumoHabitacao.lavagemCalcada.minutosPorUso,
          comRedutor && state.configRedutores.mangueira
            ? true
            : state.configHabitacao.pressurizadorCalcada
        );
      };
    },
  },
  actions: {
    desfazerRedutores() {
      this.configRedutores = {
        torneiraLavatorio: null,
        ducha: null,
        privada: null,
        piaCozinha: null,
        tanque: null,
        carro: null,
        mangueira: null,
        duchaExterna: null,
        redutoresBanheiroDone: false,
        redutoresCozinhaDone: false,
        redutoresAreaExternaDone: false,
        redutoresLavanderiaDone: false,
        privadaAcionamentos: [],
      };
      this.etapaPreenchimento = EtapasDoPreenchimento.CONSUMO;
    },
    desfazerColetaAguaChuva() {
      this.configCaptacaoAgua = {
        areaCaptacao: null,
        regiao: null,
        estado: null,
        estacaoInmet: null,
        arquivoDadosChuva: null,
        reservatorioInferior: null,
        reservatorioSuperior: null,
        usosSelecionados: [],
      };
      this.etapaPreenchimento = EtapasDoPreenchimento.REDUTORES;
    },
    desfazerFim() {
      this.etapaPreenchimento = EtapasDoPreenchimento.AGUACHUVA;
    },
    zerarStore() {
      this.desfazerColetaAguaChuva();
      this.desfazerRedutores();
      this.habitacao = null;
      this.tipoUso = null;
      this.pessoas = [];
      this.configHabitacao = {
        temMaquinaLouca: null,
        temBide: null,
        temBanheira: null,
        tipoChuveiro: null,
        tipoDescarga: null,
        temMaquinaRoupa: null,
        tipoMaquinaRoupa: null,
        temCarro: null,
        temPiscina: null,
        temJardim: null,
        temDuchaExterna: null,
        pressurizadorCarro: false,
        pressurizadorPatio: false,
        pressurizadorCalcada: false,
      };
      this.consumoHabitacao = {
        maquinaRoupas: {
          feito: false,
          vezesPorSemana: 0,
        },
        maquinaLouca: {
          feito: false,
          vezesPorSemana: 0,
        },
        lavagemPiso: {
          feito: false,
          vezesPorSemana: 0,
          minutosPorUso: 0,
        },
        lavagemRoupasMao: {
          feito: false,
          vezesPorSemana: 0,
          minutosPorUso: 0,
        },
        lavagemLouca: {
          feito: false,
          vezesPorSemana: 0,
          minutosPorUso: 0,
        },
        lavagemAlimentos: {
          feito: false,
          vezesPorSemana: 0,
          minutosPorUso: 0,
        },
        regaJardim: {
          feito: false,
          vezesPorSemana: 0,
          minutosPorUso: 0,
        },
        lavagemCarro: {
          feito: false,
          vezesPorMes: 0,
          minutosPorUso: 0,
        },
        piscina: {
          feito: false,
          volume: 0,
          vezesPorAno: 0,
        },
        duchaExterna: {
          feito: false,
          vezesPorMes: 0,
          minutosPorUso: 0,
        },
        lavagemPatio: {
          feito: false,
          vezesPorMes: 0,
          minutosPorUso: 0,
        },
        lavagemCalcada: {
          feito: false,
          vezesPorMes: 0,
          minutosPorUso: 0,
        },
      };
      this.etapaPreenchimento = EtapasDoPreenchimento.CONSUMO;
    },
    iniciaEtapaRedutores() {
      if (this.habitacao === HabitacoesEnum.APARTAMENTO) {
        this.configRedutores.redutoresAreaExternaDone = true;
      }
      this.etapaPreenchimento = EtapasDoPreenchimento.REDUTORES;
    },
    finalizaEtapaRedutores() {
      if (this.habitacao === HabitacoesEnum.APARTAMENTO) {
        this.etapaPreenchimento = EtapasDoPreenchimento.FIM;
      } else {
        this.etapaPreenchimento = EtapasDoPreenchimento.AGUACHUVA;
      }
    },
    finalizaEtapaChuva() {
      this.etapaPreenchimento = EtapasDoPreenchimento.FIM;
    },
    setHabitacao(habitacao) {
      this.habitacao = habitacao;
    },
    setTipoUso(tipoUsoObj) {
      this.tipoUso = tipoUsoObj.tipo;
      // implementacao para reatividade
      const pessoasForm = JSON.parse(JSON.stringify(tipoUsoObj.pessoas));
      pessoasForm.forEach((p) => {
        const pessoaInicial = {
          dadosBanheiro: {
            chuveiro: {
              feito: false,
              chuveiroMin: 0,
              chuveiroSemana: 0,
            },
            banheira: {
              feito: false,
              banheiraMes: 0,
            },
            descarga: {
              feito: false,
              simplesDia: 0,
              duplaCompletaDia: 0,
              duplaCurtaDia: 0,
              valvulaDia: 0,
            },
            bide: {
              feito: false,
              bideMin: 0,
              bideSemana: 0,
            },
            lavagemMaos: {
              feito: false,
              lavagemMin: 0,
              lavagemDia: 0,
            },
            barbeamento: {
              feito: false,
              barbaMin: 0,
              barbaSemana: 0,
            },
            escovacaoDentes: {
              feito: false,
              escovacaoMin: 0,
              escovacaoDia: 0,
            },
          },
        };
        const novaPessoa = Object.assign(p, pessoaInicial);
        this.pessoas.push(novaPessoa);
      });
    },
    setLavagemPressurizadaPatio(valor: boolean) {
      this.configHabitacao.pressurizadorPatio = valor;
    },
    setLavagemPressurizadaCalcada(valor: boolean) {
      this.configHabitacao.pressurizadorCalcada = valor;
    },
    setLavagemPressurizadaCarro(valor: boolean) {
      this.configHabitacao.pressurizadorCarro = valor;
    },
    setMaquinaLouca(valor: boolean) {
      this.configHabitacao.temMaquinaLouca = valor;
      if (!valor) {
        this.consumoHabitacao.maquinaLouca.feito = true;
      }
    },
    setBanheira(valor: boolean) {
      this.configHabitacao.temBanheira = valor;
    },
    setBide(valor: boolean) {
      this.configHabitacao.temBide = valor;
    },
    setChuveiro(valor: TipoChuveiroEnum) {
      this.configHabitacao.tipoChuveiro = valor;
    },
    setMaquinaRoupa(valor: boolean) {
      this.configHabitacao.temMaquinaRoupa = valor;
      // se não tiver maquina, 'zera' o tipo
      if (!valor) {
        this.consumoHabitacao.maquinaRoupas.feito = true;
        this.configHabitacao.tipoMaquinaRoupa = null;
      }
    },
    setCarro(valor: boolean) {
      this.configHabitacao.temCarro = valor;
      if (!valor) {
        this.consumoHabitacao.lavagemCarro.feito = true;
      }
    },
    setJardim(valor: boolean) {
      this.configHabitacao.temJardim = valor;
      if (!valor) {
        this.consumoHabitacao.regaJardim.feito = true;
      }
    },
    setDuchaExterna(valor: boolean) {
      this.configHabitacao.temDuchaExterna = valor;
      if (!valor) {
        this.consumoHabitacao.duchaExterna.feito = true;
      }
    },
    setPiscina(valor: boolean) {
      this.configHabitacao.temPiscina = valor;
      if (!valor) {
        this.consumoHabitacao.piscina.feito = true;
      }
    },
    setTipoMaquinaRoupa(valor: TipoMaquinaRoupaEnum) {
      this.configHabitacao.tipoMaquinaRoupa = valor;
    },
    setDescarga(valor: TiposDescargaEnum) {
      this.configHabitacao.tipoDescarga = valor;
    },
    setConsumoMaquinaRoupas(vezesPorSemana: number) {
      this.consumoHabitacao.maquinaRoupas.vezesPorSemana = vezesPorSemana;
      this.consumoHabitacao.maquinaRoupas.feito = true;
    },
    setConsumoLavagemRoupasMao(vezesPorSemana: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemRoupasMao.vezesPorSemana = vezesPorSemana;
      this.consumoHabitacao.lavagemRoupasMao.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemRoupasMao.feito = true;
    },
    setConsumoLavagemPiso(vezesPorSemana: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemPiso.vezesPorSemana = vezesPorSemana;
      this.consumoHabitacao.lavagemPiso.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemPiso.feito = true;
    },
    setConsumoMaquinaLouca(val: number) {
      this.consumoHabitacao.maquinaLouca.vezesPorSemana = val;
      this.consumoHabitacao.maquinaLouca.feito = true;
    },
    setConsumoLavagemLouca(vezesPorSemana: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemLouca.vezesPorSemana = vezesPorSemana;
      this.consumoHabitacao.lavagemLouca.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemLouca.feito = true;
    },
    setConsumoLavagemAlimentos(vezesPorSemana: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemAlimentos.vezesPorSemana = vezesPorSemana;
      this.consumoHabitacao.lavagemAlimentos.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemAlimentos.feito = true;
    },
    setConsumoBanheira(id: number, vals: ValoresBanheira) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.banheira.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.banheira.banheiraMes =
        vals.banheiraMes;
    },
    setConsumoBide(id: number, vals: ValoresBide) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.bide.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.bide.bideSemana = vals.bideSemana;
      this.pessoas[pessoaIndex].dadosBanheiro.bide.bideMin = vals.bideMin;
    },
    setConsumoChuveiro(id: number, vals: ValoresChuveiro) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.chuveiro.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.chuveiro.chuveiroSemana =
        vals.chuveiroSemana;
      this.pessoas[pessoaIndex].dadosBanheiro.chuveiro.chuveiroMin =
        vals.chuveiroMin;
    },
    setConsumoDescarga(id: number, vals: ValoresDescarga) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.descarga.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.descarga.simplesDia =
        vals.simplesDia;
      this.pessoas[pessoaIndex].dadosBanheiro.descarga.duplaCompletaDia =
        vals.duplaCompletaDia;
      this.pessoas[pessoaIndex].dadosBanheiro.descarga.duplaCurtaDia =
        vals.duplaCurtaDia;
      this.pessoas[pessoaIndex].dadosBanheiro.descarga.valvulaDia =
        vals.valvulaDia;
    },
    setConsumoBarba(id: number, vals: ValoresBarbeamento) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.barbeamento.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.barbeamento.barbaMin =
        vals.barbaMin;
      this.pessoas[pessoaIndex].dadosBanheiro.barbeamento.barbaSemana =
        vals.barbaSemana;
    },
    setConsumoEscovacao(id: number, vals: ValoresEscovacaoDentes) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.escovacaoDentes.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.escovacaoDentes.escovacaoMin =
        vals.escovacaoMin;
      this.pessoas[pessoaIndex].dadosBanheiro.escovacaoDentes.escovacaoDia =
        vals.escovacaoDia;
    },
    setConsumoLavagem(id: number, vals: ValoresLavagemMaos) {
      const pessoaIndex = getPessoaIndexById(this.pessoas, Number(id));
      this.pessoas[pessoaIndex].dadosBanheiro.lavagemMaos.feito = true;
      this.pessoas[pessoaIndex].dadosBanheiro.lavagemMaos.lavagemMin =
        vals.lavagemMin;
      this.pessoas[pessoaIndex].dadosBanheiro.lavagemMaos.lavagemDia =
        vals.lavagemDia;
    },
    setConsumoRegaJardim(vezesPorSemana: number, minutosPorUso: number) {
      this.consumoHabitacao.regaJardim.vezesPorSemana = vezesPorSemana;
      this.consumoHabitacao.regaJardim.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.regaJardim.feito = true;
    },
    setConsumoLavagemCarro(vezesPorMes: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemCarro.vezesPorMes = vezesPorMes;
      this.consumoHabitacao.lavagemCarro.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemCarro.feito = true;
    },
    setConsumoDuchaExterna(vezesPorMes: number, minutosPorUso: number) {
      this.consumoHabitacao.duchaExterna.vezesPorMes = vezesPorMes;
      this.consumoHabitacao.duchaExterna.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.duchaExterna.feito = true;
    },
    setConsumoLavagemPatio(vezesPorMes: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemPatio.vezesPorMes = vezesPorMes;
      this.consumoHabitacao.lavagemPatio.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemPatio.feito = true;
    },
    setConsumoLavagemCalcada(vezesPorMes: number, minutosPorUso: number) {
      this.consumoHabitacao.lavagemCalcada.vezesPorMes = vezesPorMes;
      this.consumoHabitacao.lavagemCalcada.minutosPorUso = minutosPorUso;
      this.consumoHabitacao.lavagemCalcada.feito = true;
    },
    setDadosPiscina(volumeM3: number, vezesPorAno: number) {
      this.consumoHabitacao.piscina.volume = volumeM3;
      this.consumoHabitacao.piscina.vezesPorAno = vezesPorAno;
      this.consumoHabitacao.piscina.feito = true;
    },
    // REDUTORES
    finalizaComodo(comodo: ComodosEnum) {
      switch (comodo) {
        case ComodosEnum.BANHEIRO:
          this.configRedutores.redutoresBanheiroDone = true;
          break;
        case ComodosEnum.COZINHA:
          this.configRedutores.redutoresCozinhaDone = true;
          break;
        case ComodosEnum.AREA_EXTERNA:
          this.configRedutores.redutoresAreaExternaDone = true;
          break;
        case ComodosEnum.LAVANDERIA:
          this.configRedutores.redutoresLavanderiaDone = true;
          break;
        default:
          break;
      }
    },
    setRedutorDucha(redutor: RedutoresDuchaEnum | null) {
      this.configRedutores.ducha = redutor;
    },
    setRedutorPrivada(
      redutor: RedutoresPrivadaEnum | null,
      acionamentos: AcionamentosCaixaDupla[] | null
    ) {
      this.configRedutores.privadaAcionamentos = [];
      this.configRedutores.privada = null;
      if (redutor && acionamentos) {
        this.configRedutores.privada = redutor;
        const acionamentosParsed = JSON.parse(JSON.stringify(acionamentos));
        acionamentosParsed.forEach((ac) => {
          const novoAcionamento = Object.assign({}, ac);
          this.configRedutores.privadaAcionamentos.push(novoAcionamento);
        });
      }
    },
    setRedutorTorneiraLavatorio(
      redutor: RedutoresTorneiraLavatorioEnum | null
    ) {
      this.configRedutores.torneiraLavatorio = redutor;
    },
    setRedutorTorneiraPia(redutor: RedutoresTorneiraPiaEnum | null) {
      this.configRedutores.piaCozinha = redutor;
    },
    setRedutorTanque(redutor: RedutoresTanqueEnum | null) {
      this.configRedutores.tanque = redutor;
    },
    setRedutorMangueira(redutor: RedutoresTorneiraExternaEnum | null) {
      this.configRedutores.mangueira = redutor;
    },
    setRedutorCarro(redutor: RedutoresTorneiraExternaEnum | null) {
      this.configRedutores.carro = redutor;
    },
    setRedutorDuchaExterna(redutor: RedutoresDuchaEnum | null) {
      this.configRedutores.duchaExterna = redutor;
    },
    // AGUA CHUVA
    setUsosSubstituidos(usos: UsosSubstituiveisEnum[]) {
      this.configCaptacaoAgua.usosSelecionados.splice(
        0,
        this.configCaptacaoAgua.usosSelecionados.length
      );
      this.configCaptacaoAgua.usosSelecionados.push(...usos);
    },
    setLocalizacao(
      regiao: string,
      estado: string,
      estacaoInmet: string,
      arquivoDadosChuva: string
    ) {
      this.configCaptacaoAgua.regiao = regiao;
      this.configCaptacaoAgua.estado = estado;
      this.configCaptacaoAgua.estacaoInmet = estacaoInmet;
      this.configCaptacaoAgua.arquivoDadosChuva = arquivoDadosChuva;
    },
    setReservatorios(inferior: number, superior: number) {
      this.configCaptacaoAgua.reservatorioInferior = inferior;
      this.configCaptacaoAgua.reservatorioSuperior = superior;
    },
    setAreaCaptacao(area: number) {
      this.configCaptacaoAgua.areaCaptacao = area;
    },
  },
});
