
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import SubFormLavarRoupa from "./SubFormLavarRoupa.vue";
import SubFormLavarPisos from "./SubFormLavarPisos.vue";
import { useStore } from "@/store";

enum SubForms {
  LAVAR_ROUPA = "LAVAR_ROUPA",
  LAVAR_PISO = "LAVAR_PISO",
}

export default Vue.extend({
  name: "FormTorneira",
  components: {
    LayoutCard,
    SubFormLavarRoupa,
    SubFormLavarPisos,
  },
  data() {
    return {
      SubForms,
      selecionado: SubForms.LAVAR_ROUPA,
      store: useStore(),
    };
  },
  methods: {
    subformSelecionado(subform: string) {
      this.selecionado = subform;
    },
    goToProximoPasso(subform: string) {
      switch (subform) {
        case SubForms.LAVAR_ROUPA:
          if (!this.store.isLavagemPisoDone) {
            this.selecionado = SubForms.LAVAR_PISO;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Tanque completo!");
          }
          break;
        case SubForms.LAVAR_PISO:
          if (!this.store.isLavagemRoupasMaoDone) {
            this.selecionado = SubForms.LAVAR_ROUPA;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Tanque completo!");
          }
          break;
        default:
      }
    },
  },
});
