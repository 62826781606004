
import Vue from "vue";

export default Vue.extend({
  name: "SimOuNaoButtons",
  props: {
    textoSim: {
      type: String,
      default: null,
    },
    textoNao: {
      type: String,
      default: null,
    },
  },
});
