var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center",staticStyle:{"position":"relative"}},[_c('LavanderiaBase',{style:(`filter: grayscale(${_vm.baseDim.grayscale}%); position: absolute; height: ${_vm.tamanhoBase}`),attrs:{"id":"LavanderiaBase"}}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(_vm.etapaRedutores)?_c('v-icon',{class:`${hover ? '' : _vm.animationClassIcons} baseIcones`,style:(`
      top: ${_vm.tanque.iconTop}px;
      left: ${_vm.tanque.iconLeft}px;`),attrs:{"size":_vm.redutoresIconSize,"color":_vm.configRedutores.tanque
          ? _vm.redutoresIconColorAfter
          : _vm.redutoresIconColorBefore},on:{"click":function($event){return _vm.$emit('click-redutores', _vm.FormsLavanderiaEnum.TORNEIRA)}}},[_vm._v(" "+_vm._s(_vm.configRedutores.tanque ? _vm.redutoresIconAfter : _vm.redutoresIconBefore)+" ")]):_vm._e()]}}])}),(_vm.baseRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tanque.visivel),expression:"tanque.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.tanque.top}px;
      left: ${_vm.tanque.left}px;
      height: auto;
      z-index: 1;`)},[_c('Tanque',{class:_vm.tanque.class,attrs:{"width":`${_vm.tanque.width}px`}})],1):_vm._e(),(_vm.baseRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.torneira.visivel),expression:"torneira.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.torneira.top}px;
      left: ${_vm.torneira.left}px;
      height: auto;
      z-index: 1;`)},[_c('Torneira',{class:_vm.torneira.class,attrs:{"width":`${_vm.torneira.width}px`}})],1):_vm._e(),(_vm.baseRendered)?_c('TorneiraAgua',{directives:[{name:"show",rawName:"v-show",value:(_vm.torneiraAgua.visivel),expression:"torneiraAgua.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.torneiraAgua.top}px;
      left: ${_vm.torneiraAgua.left}px;
      filter: grayscale(${_vm.torneiraAgua.grayscale}%) opacity(${_vm.opacidadeAgua}%);
      height: auto;
      z-index: 1;`),attrs:{"width":`${_vm.torneiraAgua.width}px`}}):_vm._e(),(_vm.baseRendered)?_c('TanqueAgua',{directives:[{name:"show",rawName:"v-show",value:(_vm.tanqueAgua.visivel),expression:"tanqueAgua.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.tanqueAgua.top}px;
      left: ${_vm.tanqueAgua.left}px;
      filter: grayscale(${_vm.tanqueAgua.grayscale}%) opacity(${_vm.opacidadeAgua}%);
      height: auto;
      z-index: 1;`),attrs:{"width":`${_vm.tanqueAgua.width}px`}}):_vm._e(),(_vm.baseRendered)?_c('Balde',{directives:[{name:"show",rawName:"v-show",value:(_vm.balde.visivel),expression:"balde.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.balde.top}px;
      left: ${_vm.balde.left}px;
      filter: grayscale(${_vm.balde.grayscale}%);
      height: auto;
      z-index: 1;`),attrs:{"width":`${_vm.balde.width}px`}}):_vm._e(),(_vm.baseRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maquinaSuperior.visivel),expression:"maquinaSuperior.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.maquinaSuperior.top}px;
      left: ${_vm.maquinaSuperior.left}px;
      height: auto;
      z-index: 1;`)},[_c('MaquinaSuperior',{class:_vm.maquinaSuperior.class,attrs:{"width":`${_vm.maquinaSuperior.width}px`,"fill-opacity":_vm.maquinaSuperior.opacity}})],1):_vm._e(),(_vm.baseRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maquinaFrontal.visivel),expression:"maquinaFrontal.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.maquinaFrontal.top}px;
      left: ${_vm.maquinaFrontal.left}px;
      height: auto;
      z-index: 1;`)},[_c('MaquinaFrontal',{class:_vm.maquinaFrontal.class,attrs:{"width":`${_vm.maquinaFrontal.width}px`,"fill-opacity":_vm.maquinaFrontal.opacity}})],1):_vm._e(),_c('img',{style:(`width: 100%; z-index: 2; position: absolute;top: 0;left: 0;`),attrs:{"src":require("../../assets/lavanderia/lavanderia-vazia.png"),"usemap":"#image-map"}}),_c('map',{attrs:{"name":"image-map"}},[_c('area',{style:(_vm.etapaRedutores ? '' : 'cursor: pointer'),attrs:{"title":"maquinaRoupa","coords":_vm.mapeiaCoords('maquinaRoupa'),"shape":"poly"},on:{"click":function($event){return _vm.$emit('click-lavanderia', _vm.FormsLavanderiaEnum.MAQUINA_ROUPA)},"mouseenter":function($event){_vm.hoverImg = 'maquinaRoupa'},"mouseleave":function($event){_vm.hoverImg = null}}}),_c('area',{style:(_vm.etapaRedutores ? '' : 'cursor: pointer'),attrs:{"title":"tanque","coords":_vm.mapeiaCoords('tanque'),"shape":"poly"},on:{"click":function($event){return _vm.$emit('click-lavanderia', _vm.FormsLavanderiaEnum.TORNEIRA)},"mouseenter":function($event){_vm.hoverImg = 'tanque'},"mouseleave":function($event){_vm.hoverImg = null}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }