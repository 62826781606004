
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import TemOuNaoTemButtons from "../TemOuNaoTemButtons.vue";
import SubFormBanheira from "./SubFormBanheira.vue";
import { useStore } from "@/store";
import { SimOuNaoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormBanheira",
  components: {
    LayoutCard,
    TemOuNaoTemButtons,
    SubFormBanheira,
  },
  data() {
    return {
      SimOuNaoEnum,
      selecionado: false,
      store: useStore(),
    };
  },
  computed: {
    temBanheiraSimOuNao() {
      if (this.store.getConfigHabitacao.temBanheira === true) {
        return SimOuNaoEnum.SIM;
      }
      if (this.store.getConfigHabitacao.temBanheira === false) {
        return SimOuNaoEnum.NAO;
      }
      return null;
    },
  },
  methods: {
    subformClick() {
      if (this.temBanheiraSimOuNao === SimOuNaoEnum.SIM) {
        this.selecionado = true;
      }
    },
    setBanheira(valor: boolean) {
      this.store.setBanheira(valor);
      if (!valor) {
        this.selecionado = false;
        this.goToProximoPasso();
      } else {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Banheira completa!");
    },
  },
});
