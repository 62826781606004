
import Vue from "vue";
import FormMaquinaLouca from "./FormMaquinaLouca.vue";
import FormTorneira from "./FormTorneira.vue";
import { FormsCozinhaEnum } from "../../utils/constants";

export default Vue.extend({
  name: "DialogCozinha",
  components: {
    FormMaquinaLouca,
    FormTorneira,
  },
  props: {
    formSelecionado: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    FormsCozinhaEnum,
  }),
});
