
import Vue from "vue";
import { useStore } from "@/store";
import {
  RedutoresTorneiraLavatorioEnum,
  UsosBanheiroEnum,
  RedutoresNomes,
} from "../../utils/constants";
import CubaPia from "../../assets/banheiro/cubaPia.svg";
import Arejador from "../../assets/redutores/arejador.svg";
import Pulverizador from "../../assets/redutores/pulverizador.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";
import {
  getConsumoBarbearMes,
  getConsumoEscovarDentesMes,
  getConsumoLavarMaoMes,
} from "@/utils/functions";

export default Vue.extend({
  name: "RedutoresListTorneiraLavatorio",
  components: {
    CubaPia,
    Arejador,
    Pulverizador,
    RestritorVazao,
    BarrasAntesDepoisRedutor,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresTorneiraLavatorioEnum,
      redutorSelecionado: null,
      recemMontado: true,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
    };
  },
  mounted() {
    this.redutorSelecionado = this.store.getConfigRedutores.torneiraLavatorio;
  },
  computed: {
    antesRedutor(): number {
      const soma =
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BARBEAMENTO) +
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.LAVAGEM_MAOS) +
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.ESCOVACAO);
      return soma;
    },
    depoisRedutor(): number {
      const pessoas = this.store.getPessoas;
      const hab = this.store.getHabitacao;
      const dadosMaos = pessoas.map(
        (p) => p.dadosBanheiro[UsosBanheiroEnum.LAVAGEM_MAOS]
      );
      const dadosEscovacao = pessoas.map(
        (p) => p.dadosBanheiro[UsosBanheiroEnum.ESCOVACAO]
      );
      const dadosBarba = pessoas.map(
        (p) => p.dadosBanheiro[UsosBanheiroEnum.BARBEAMENTO]
      );
      const consumoMaos = getConsumoLavarMaoMes(
        dadosMaos,
        hab,
        this.redutorSelecionado
      );
      const consumoEscovacao = getConsumoEscovarDentesMes(
        dadosEscovacao,
        hab,
        this.redutorSelecionado
      );
      const consumoBarba = getConsumoBarbearMes(
        dadosBarba,
        hab,
        this.redutorSelecionado
      );

      return consumoMaos + consumoEscovacao + consumoBarba;
    },
  },
  methods: {
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
  },
  watch: {
    redutorSelecionado() {
      this.store.setRedutorTorneiraLavatorio(this.redutorSelecionado || null);
    },
  },
});
