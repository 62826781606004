
import Vue from "vue";
export default Vue.extend({
  name: "TheToastSnackbar",
  data: () => ({
    show: false,
    color: "info",
    text: [""],
    timeout: 1000,
  }),
  computed: {
    // bottom() {
    //   return this.$vuetify.breakpoint.xsOnly;
    // },
    // top() {
    //   return this.$vuetify.breakpoint.smAndUp;
    // },
  },
  created() {
    this.$root.$on(
      "show-toast",
      (options: {
        text?: string | string[];
        color?: string;
        timeout?: number;
      }) => {
        this.showToast(options);
      }
    );
    this.$root.$on(
      "toast-success",
      (text: string | string[] = "OK", timeout = 1000) => {
        this.showToast({
          text,
          color: "success",
          timeout,
        });
      }
    );
    this.$root.$on("toast-error", (text: string | string[], timeout = 1500) => {
      this.showToast({
        text,
        color: "error",
        timeout,
      });
    });

    this.$root.$on(
      "toast-warning",
      (text: string | string[], timeout = 5000) => {
        this.showToast({
          text,
          color: "warning",
          timeout,
        });
      }
    );
  },
  methods: {
    showToast(options: {
      text?: string | string[];
      color?: string;
      timeout?: number;
    }) {
      this.text = Array.isArray(options.text) ? options.text : [options.text];
      this.color = options.color ?? "info";
      this.timeout = options.timeout ?? 3000;
      this.show = true;
    },
  },
});
