
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import TemOuNaoTemButtons from "../TemOuNaoTemButtons.vue";
import SubFormMaquinaLouca from "./SubFormMaquinaLouca.vue";
import { useStore } from "@/store";
import { SimOuNaoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormMaquinaLouca",
  components: {
    LayoutCard,
    TemOuNaoTemButtons,
    SubFormMaquinaLouca,
  },
  data() {
    return {
      SimOuNaoEnum,
      exibirSubform: false,
      store: useStore(),
    };
  },
  mounted() {
    this.exibirSubform = this.store.getConfigHabitacao.temMaquinaLouca;
  },
  computed: {
    temMaquinaLoucaSimOuNao() {
      if (this.store.getConfigHabitacao.temMaquinaLouca === true) {
        return SimOuNaoEnum.SIM;
      }
      if (this.store.getConfigHabitacao.temMaquinaLouca === false) {
        return SimOuNaoEnum.NAO;
      }
      return null;
    },
  },
  methods: {
    subformClick() {
      if (this.temMaquinaLoucaSimOuNao === SimOuNaoEnum.SIM) {
        this.exibirSubform = true;
      }
    },
    setMaquinaLouca(valor: boolean) {
      this.store.setMaquinaLouca(valor);
      if (!valor) {
        this.exibirSubform = false;
        this.goToProximoPasso();
      } else {
        this.exibirSubform = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Lava-louça completa!");
    },
  },
});
