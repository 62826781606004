
import Vue from "vue";
import IconeFooter from "../components/IconeFooter.vue";
import { useStore } from "../store";
import {
  EtapasDoPreenchimento,
  HabitacoesEnum,
  TiposUsoEnum,
} from "../utils/constants";
import CasaIcon from "../assets/icones/iconeCasa.svg";
import PredioIcon from "../assets/icones/iconeApartamento.svg";
import PessoaIcon from "../assets/icones/iconePessoa.svg";
import GrupoPessoasIcon from "../assets/icones/iconeGrupoPessoas.svg";
import CozinhaIcon from "../assets/icones/iconeCozinha.svg";
import LavanderiaIcon from "../assets/icones/iconeLavanderia.svg";
import QuintalIcon from "../assets/icones/iconeQuintal.svg";
import BanheiroIcon from "../assets/icones/iconeBanheiro.svg";

export default Vue.extend({
  name: "TheAppFooter",
  components: {
    IconeFooter,
    CasaIcon,
    PredioIcon,
    PessoaIcon,
    GrupoPessoasIcon,
    CozinhaIcon,
    LavanderiaIcon,
    QuintalIcon,
    BanheiroIcon,
  },
  data: () => ({
    HabitacoesEnum,
    TiposUsoEnum,
    iconPixelHeight: "36px",
    store: useStore(),
  }),
  computed: {
    mostraFooter() {
      return !["LandingPage", "InfoPage"].includes(this.$route.name);
    },
    mostraIconesComodos() {
      return !["AguaChuva", "FimPage"].includes(this.$route.name);
    },
    inicioOk() {
      return !!this.habitacaoStore && !!this.tipoUsoStore;
    },
    habitacaoStore() {
      return this.store.getHabitacao || null;
    },
    tipoUsoStore() {
      return this.store.getTipoUso || null;
    },
    banheiroFinalizado() {
      return this.store.isBanheiroDone;
    },
    cozinhaFinalizada() {
      return this.store.isCozinhaDone;
    },
    lavanderiaFinalizada() {
      return this.store.isLavanderiaDone;
    },
    areaExternaFinalizada() {
      return this.store.isAreaExternaDone;
    },
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    isEtapaRedutores() {
      return this.etapaPreenchimento === EtapasDoPreenchimento.REDUTORES;
    },
  },
  methods: {
    selecionado(rota: string) {
      return rota === this.$route.name;
    },
  },
});
