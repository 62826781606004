
import Vue from "vue";
import { useStore } from "@/store";
import CasaIcon from "../assets/icones/iconeCasa.svg";
import PredioIcon from "../assets/icones/iconeApartamento.svg";
import PessoaIcon from "../assets/icones/iconePessoa.svg";
import GrupoPessoasIcon from "../assets/icones/iconeGrupoPessoas.svg";
import BanheiroResultados from "../components/banheiro/BanheiroResultados.vue";
import { HabitacoesEnum, TiposUsoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "ResultadosPage",
  components: {
    CasaIcon,
    PredioIcon,
    PessoaIcon,
    GrupoPessoasIcon,
    BanheiroResultados,
  },
  data: () => ({
    HabitacoesEnum,
    TiposUsoEnum,
    store: useStore(),
  }),
  computed: {
    tipoHabitacao() {
      return this.store.getHabitacao;
    },
    numHabitantes() {
      return this.store.getNumHabitantes;
    },
    tipoUso() {
      return this.store.getTipoUso;
    },
    total() {
      return "TOTAL";
    },
  },
});
