
import { StatusEtapasEnum, TiposDescargaEnum } from "@/utils/constants";
import Vue from "vue";
import TabsUsuarios from "../TabsUsuarios.vue";
import FormLayoutUsuario from "../FormLayoutUsuario.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import { getConsumoDescargaMes } from "@/utils/functions";
import { useStore } from "@/store";
import { ValoresDescarga } from "@/utils/dto";
import { some } from "lodash";

export default Vue.extend({
  name: "SubFormDescarga",
  components: {
    TabsUsuarios,
    FormLayoutUsuario,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    TiposDescargaEnum,
    store: useStore(),
    pessoas: [],
  }),
  mounted() {
    this.montaPessoas();
  },
  computed: {
    pessoaSelecionada() {
      return this.pessoas.find((p) => p.selecionada) || null;
    },
    pessoasStore() {
      return this.store.getPessoas;
    },
    todosCompletos() {
      return !some(this.pessoas, (p) => p.status !== StatusEtapasEnum.COMPLETA);
    },
    tipoDescarga() {
      return this.store.getConfigHabitacao.tipoDescarga;
    },
  },
  methods: {
    montaPessoas() {
      // esvazia array
      this.pessoas = [];
      // flag para indicar quando o "proximo" foi encontrado (pessoa q deve estar selecionada)
      let selecionadoEncontrado = false;
      // popula array com dados da store
      this.pessoasStore.forEach((ps) => {
        const form = {
          simplesDia: 0,
          valvulaDia: 0,
          duplaCompletaDia: 0,
          duplaCurtaDia: 0,
        } as ValoresDescarga;
        switch (this.tipoDescarga) {
          case TiposDescargaEnum.CAIXA_DUPLA:
            form.duplaCompletaDia = ps.dadosBanheiro.descarga.duplaCompletaDia;
            form.duplaCurtaDia = ps.dadosBanheiro.descarga.duplaCurtaDia;
            break;
          case TiposDescargaEnum.CAIXA_SIMPLES:
            form.simplesDia = ps.dadosBanheiro.descarga.simplesDia;
            break;
          case TiposDescargaEnum.VALVULA:
            form.valvulaDia = ps.dadosBanheiro.descarga.valvulaDia;
            break;
          default:
            break;
        }
        this.pessoas.push({
          ...ps,
          status: ps.dadosBanheiro.descarga.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
          selecionada:
            !ps.dadosBanheiro.descarga.feito && !selecionadoEncontrado,
          etapas: [
            {
              status: ps.dadosBanheiro.descarga.feito
                ? StatusEtapasEnum.COMPLETA
                : StatusEtapasEnum.FAZENDO,
            },
            {
              status: ps.dadosBanheiro.descarga.feito
                ? StatusEtapasEnum.COMPLETA
                : StatusEtapasEnum.INCOMPLETA,
            },
          ],
          form,
        });
        selecionadoEncontrado = !ps.dadosBanheiro.descarga.feito;
      });
    },
    selecionaPessoa(pessoa) {
      let indexNovaPessoa;
      this.pessoas.forEach((p, index) => {
        this.$set(this.pessoas[index], "selecionada", false);
        if (pessoa.id === p.id) {
          indexNovaPessoa = index;
        }
      });
      // Delay para animação
      setTimeout(() => {
        this.$set(this.pessoas[indexNovaPessoa], "selecionada", true);
      }, 200);
    },
    totalDescarga() {
      if (!this.pessoaSelecionada) {
        return 0;
      }
      return getConsumoDescargaMes(
        [this.getValoresDescargaObj()],
        this.store.habitacao
      );
    },
    selecionaProxima() {
      const id = this.pessoaSelecionada.id;
      const novaPessoa = this.pessoas.find((p) => p.id === id + 1);
      this.pessoaSelecionada.selecionada = false;
      if (novaPessoa) {
        setTimeout(() => {
          novaPessoa.selecionada = true;
        }, 200);
      }
    },
    voltar() {
      // TODO resetar valores
      this.pessoaSelecionada.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.pessoaSelecionada.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
      this.pessoaSelecionada.status = StatusEtapasEnum.FAZENDO;
    },
    finalizaDescarga() {
      this.store.setConsumoDescarga(
        this.pessoaSelecionada.id,
        this.getValoresDescargaObj()
      );
      this.pessoaSelecionada.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.pessoaSelecionada.etapas[1].status = StatusEtapasEnum.FAZENDO;
      this.pessoaSelecionada.status = StatusEtapasEnum.COMPLETA;
      this.$root.$emit("toast-success");
    },
    getValoresDescargaObj(): ValoresDescarga {
      const valoresDescarga: ValoresDescarga = {
        simplesDia: 0,
        duplaCompletaDia: 0,
        duplaCurtaDia: 0,
        valvulaDia: 0,
      };
      if (this.pessoaSelecionada) {
        switch (this.tipoDescarga) {
          case TiposDescargaEnum.CAIXA_DUPLA:
            valoresDescarga.duplaCompletaDia =
              this.pessoaSelecionada.form.duplaCompletaDia;
            valoresDescarga.duplaCurtaDia =
              this.pessoaSelecionada.form.duplaCurtaDia;
            break;
          case TiposDescargaEnum.CAIXA_SIMPLES:
            valoresDescarga.simplesDia = this.pessoaSelecionada.form.simplesDia;
            break;
          case TiposDescargaEnum.VALVULA:
            valoresDescarga.valvulaDia = this.pessoaSelecionada.form.valvulaDia;
            break;
          default:
            break;
        }
      }
      return valoresDescarga;
    },
  },
  watch: {
    selecionado(newValue) {
      if (newValue && !this.pessoaSelecionada) {
        this.selecionaPessoa(this.pessoas[0]);
      }
    },
  },
});
