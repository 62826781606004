
import Vue from "vue";
import CardRedutoresLists from "../components/redutores/CardRedutoresLists.vue";
import {
  FormsBanheiroEnum,
  FormsCozinhaEnum,
  FormsLavanderiaEnum,
  FormsAreaExternaEnum,
} from "../utils/constants";

export default Vue.extend({
  name: "DialogRedutoresConsumo",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    usoSelecionado: {
      type: String,
      default: null,
    },
  },
  components: {
    CardRedutoresLists,
  },
  data() {
    return {
      FormsBanheiroEnum,
      FormsCozinhaEnum,
      FormsLavanderiaEnum,
      FormsAreaExternaEnum,
    };
  },
});
