
import { StatusEtapasEnum } from "@/utils/constants";
import Vue from "vue";

export default Vue.extend({
  name: "TabsUsuarios",
  props: {
    title: {
      type: String,
      default: "",
    },
    pessoas: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      StatusEtapasEnum,
    };
  },
  methods: {
    iconPessoa(pessoa) {
      switch (pessoa.status) {
        case StatusEtapasEnum.COMPLETA:
          return "mdi-checkbox-marked-circle-outline";
        default:
          return "mdi-account-circle";
      }
    },
  },
});
