
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import SubFormBarbeamento from "./SubFormBarbeamento.vue";
import SubFormLavagemMaos from "./SubFormLavagemMaos.vue";
import SubFormEscovacaoDentes from "./SubFormEscovacaoDentes.vue";
import { useStore } from "@/store";
import { UsosBanheiroEnum } from "@/utils/constants";

enum SubForms {
  BARBEAMENTO = "BARBEAMENTO",
  LAVAGEM = "LAVAGEM",
  ESCOVACAO = "ESCOVACAO",
}

export default Vue.extend({
  name: "FormTorneira",
  components: {
    LayoutCard,
    SubFormBarbeamento,
    SubFormLavagemMaos,
    SubFormEscovacaoDentes,
  },
  data() {
    return {
      SubForms,
      selecionado: SubForms.ESCOVACAO,
      store: useStore(),
    };
  },
  methods: {
    subformSelecionado(subform: string) {
      this.selecionado = subform;
    },
    goToProximoPasso(subform: string) {
      switch (subform) {
        case SubForms.ESCOVACAO:
          if (!this.store.isUsoBanheiroDone(UsosBanheiroEnum.LAVAGEM_MAOS)) {
            this.selecionado = SubForms.LAVAGEM;
          } else if (
            !this.store.isUsoBanheiroDone(UsosBanheiroEnum.BARBEAMENTO)
          ) {
            this.selecionado = SubForms.BARBEAMENTO;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Torneira completa!");
          }
          break;
        case SubForms.LAVAGEM:
          if (!this.store.isUsoBanheiroDone(UsosBanheiroEnum.BARBEAMENTO)) {
            this.selecionado = SubForms.BARBEAMENTO;
          } else if (
            !this.store.isUsoBanheiroDone(UsosBanheiroEnum.ESCOVACAO)
          ) {
            this.selecionado = SubForms.ESCOVACAO;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Torneira completa!");
          }
          break;
        case SubForms.BARBEAMENTO:
          if (!this.store.isUsoBanheiroDone(UsosBanheiroEnum.ESCOVACAO)) {
            this.selecionado = SubForms.ESCOVACAO;
          } else if (
            !this.store.isUsoBanheiroDone(UsosBanheiroEnum.LAVAGEM_MAOS)
          ) {
            this.selecionado = SubForms.LAVAGEM;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Torneira completa!");
          }
          break;
        default:
      }
    },
  },
});
