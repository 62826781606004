
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import { getConsumoLavagemCarroMes } from "@/utils/functions";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SubFormLavarCarro",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      lavagemCarroMes: 0,
      lavagemCarroMinutos: 0,
      pressurizador: false,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.lavagemCarro.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.lavagemCarro.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.lavagemCarroMes =
      this.store.getConsumoHabitacao.lavagemCarro.vezesPorMes;
    this.form.lavagemCarroMinutos = Number(
      this.store.getConsumoHabitacao.lavagemCarro.minutosPorUso
    );
    this.form.pressurizador = this.store.getConfigHabitacao.pressurizadorCarro;
  },
  computed: {
    labelPressurizador() {
      return this.form.pressurizador
        ? "Sim, utilizo lavadora pressurizada"
        : "Não utilizo lavadora pressurizada";
    },
    lavagemCarroCompleta() {
      return this.store.getConsumoHabitacao.lavagemCarro.feito;
    },
    totalLavagemCarro() {
      return getConsumoLavagemCarroMes(
        this.form.lavagemCarroMes,
        this.form.lavagemCarroMinutos,
        this.form.pressurizador
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaCarro() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setConsumoLavagemCarro(
        this.form.lavagemCarroMes,
        Number(this.form.lavagemCarroMinutos)
      );
      this.$root.$emit("toast-success");
    },
  },
  watch: {
    "form.pressurizador": {
      handler() {
        this.store.setLavagemPressurizadaCarro(this.form.pressurizador);
      },
    },
  },
});
