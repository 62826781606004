import { render, staticRenderFns } from "./DialogRedutoresInfo.vue?vue&type=template&id=1503b9fc&scoped=true&"
import script from "./DialogRedutoresInfo.vue?vue&type=script&lang=ts&"
export * from "./DialogRedutoresInfo.vue?vue&type=script&lang=ts&"
import style0 from "./DialogRedutoresInfo.vue?vue&type=style&index=0&id=1503b9fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1503b9fc",
  null
  
)

export default component.exports