
import Vue from "vue";

export default Vue.extend({
  name: "UltimoPassoButton",
  props: {
    todosCompletos: {
      type: Boolean,
      default: false,
    },
  },
});
