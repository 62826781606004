
import Vue from "vue";
import DialogCozinha from "../components/cozinha/DialogCozinha.vue";
import DialogComodoFinalizado from "../components/DialogComodoFinalizado.vue";
import CardFormsCozinha from "../components/cozinha/CardFormsCozinha.vue";
import IlustracaoCozinha from "../components/cozinha/IlustracaoCozinha.vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import CardListUsosCozinha from "../components/cozinha/CardListUsosCozinha.vue";
import { useStore } from "@/store";

export default Vue.extend({
  name: "CozinhaPage",
  components: {
    DialogCozinha,
    DialogComodoFinalizado,
    IlustracaoCozinha,
    ProgressTotalPerCapita,
    CardFormsCozinha,
    CardListUsosCozinha,
  },
  data: () => ({
    store: useStore(),
    dialog: false,
    dialogFinalizado: false,
    dialogRedutores: false,
    formSelecionado: null,
  }),
  computed: {
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    cozinhaFinalizada() {
      return this.store.isCozinhaDone;
    },
  },
  methods: {
    abreForm(form: string) {
      this.dialog = true;
      this.formSelecionado = form;
    },
    fechaForm() {
      this.formSelecionado = null;
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog && this.cozinhaFinalizada) {
        this.dialogFinalizado = true;
      }
    },
  },
});
