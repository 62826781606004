
import Vue from "vue";
import { TAMANHOS_RESERVATORIOS } from "@/utils/constants";
import StepperButtons from "./StepperButtons.vue";
import { useStore } from "@/store";

export default Vue.extend({
  name: "StepReservatorios",
  components: {
    StepperButtons,
  },
  data: () => ({
    store: useStore(),
    TAMANHOS_RESERVATORIOS,
    inferior: null,
    superior: null,
  }),
  computed: {
    inferiorComputed() {
      return TAMANHOS_RESERVATORIOS.inferior.map((el) => ({
        text: `${el}L`,
        value: Number(el),
      }));
    },
    superiorComputed() {
      return TAMANHOS_RESERVATORIOS.superior.map((el) => ({
        text: `${el}L`,
        value: Number(el),
      }));
    },
  },
  mounted() {
    this.inferior = this.store.getConfigCaptacaoAgua.reservatorioInferior;
    this.superior = this.store.getConfigCaptacaoAgua.reservatorioSuperior;
  },
  methods: {
    setReservatorios() {
      this.store.setReservatorios(
        Number(this.inferior) || 0,
        Number(this.superior) || 0
      );
      this.$emit("ok");
    },
  },
});
