
import Vue from "vue";
import FormBanheira from "./FormBanheira.vue";
import FormChuveiro from "./FormChuveiro.vue";
import FormDescarga from "./FormDescarga.vue";
import FormTorneira from "./FormTorneira.vue";
import FormBide from "./FormBide.vue";
import { FormsBanheiroEnum } from "../../utils/constants";

export default Vue.extend({
  name: "DialogBanheiro",
  components: {
    FormBanheira,
    FormChuveiro,
    FormDescarga,
    FormTorneira,
    FormBide,
  },
  props: {
    formSelecionado: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    FormsBanheiroEnum,
  }),
});
