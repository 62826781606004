
import Vue from "vue";
import { useStore } from "@/store";
import { formataNumero } from "@/utils/functions";
import { FormsCozinhaEnum } from "@/utils/constants";
import TotalDoComodoCard from "../TotalDoComodoCard.vue";
import ResultadosCard from "../ResultadosCard.vue";
import MaquinaLouca from "../../assets/cozinha/maquinaLouca.svg";
import Pia from "../../assets/cozinha/pia.svg";

export default Vue.extend({
  name: "CozinhaResultados",
  components: {
    TotalDoComodoCard,
    ResultadosCard,
    MaquinaLouca,
    Pia,
  },
  data: () => ({
    formataNumero,
    store: useStore(),
    usoSelecionado: null,
    FormsCozinhaEnum,
  }),
  computed: {
    possuiMaquinaLouca() {
      return this.store.getConfigHabitacao.temMaquinaLouca;
    },
    maquinaLouca() {
      if (this.possuiMaquinaLouca) {
        return parseFloat(this.store.getConsumoMaquinaLoucaMes);
      }
      return 0;
    },
    piaCozinha() {
      return (
        parseFloat(this.store.getConsumoLavagemLoucaMes()) +
        parseFloat(this.store.getConsumoLavagemAlimentosMes())
      );
    },
    piaCozinhaRedutor() {
      return (
        parseFloat(this.store.getConsumoLavagemLoucaMes(true)) +
        parseFloat(this.store.getConsumoLavagemAlimentosMes(true))
      );
    },
    total() {
      return this.piaCozinha + this.maquinaLouca;
    },
    totalRedutor() {
      return this.piaCozinhaRedutor + this.maquinaLouca;
    },
  },
});
