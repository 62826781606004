
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import EtapasBar from "./EtapasBar.vue";
import CustomAlert from "./CustomAlert.vue";

export default Vue.extend({
  name: "FormLayoutHabitacao",
  components: {
    EtapasBar,
    CustomAlert,
  },
  props: {
    etapas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      windowModel: 0,
    };
  },
  mounted() {
    this.windowModel = this.etapaAtual;
  },
  computed: {
    etapaAtual() {
      const etapaAtual = this.etapas.findIndex(
        (et) => et.status === StatusEtapasEnum.FAZENDO
      );
      return etapaAtual < 0 ? this.etapas.length - 1 : etapaAtual;
    },
  },
  watch: {
    etapaAtual() {
      this.windowModel = this.etapaAtual;
    },
  },
});
