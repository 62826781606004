
import Vue from "vue";

export default Vue.extend({
  name: "CustomText",
  props: {
    texto: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textClass() {
      if (this.big) {
        return "text-h5";
      }
      return this.$vuetify.breakpoint.smAndDown ? "body-2" : "body-1";
    },
  },
});
