
import Vue from "vue";

import Banheira from "../../assets/banheiro/banheira.svg";
import Bide from "../../assets/banheiro/bide.svg";
import Chuveiro from "../../assets/banheiro/chuveiro.svg";
import Ducha from "../../assets/banheiro/ducha.svg";
import Privada from "../../assets/banheiro/privada.svg";
import CubaPia from "../../assets/banheiro/cubaPia.svg";
import { useStore } from "@/store";
import { FormsBanheiroEnum, TipoChuveiroEnum } from "@/utils/constants";

export default Vue.extend({
  name: "CardListUsosBanheiro",
  components: {
    Banheira,
    Bide,
    Chuveiro,
    Ducha,
    Privada,
    CubaPia,
  },
  data() {
    return {
      FormsBanheiroEnum,
      TipoChuveiroEnum,
      store: useStore(),
    };
  },
});
