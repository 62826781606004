
import Vue from "vue";
import BanheiroIcon from "../../assets/icones/iconeBanheiro.svg";
import CozinhaIcon from "../../assets/icones/iconeCozinha.svg";
import LavanderiaIcon from "../../assets/icones/iconeLavanderia.svg";
import QuintalIcon from "../../assets/icones/iconeQuintal.svg";
import ExpansionContentRelatorioBanheiro from "./ExpansionContentRelatorioBanheiro.vue";
import ExpansionContentRelatorioCozinha from "./ExpansionContentRelatorioCozinha.vue";
import ExpansionContentRelatorioLavanderia from "./ExpansionContentRelatorioLavanderia.vue";
import ExpansionContentRelatorioAreaExterna from "./ExpansionContentRelatorioAreaExterna.vue";
import { useStore } from "@/store";
import { HabitacoesEnum } from "@/utils/constants";

export default Vue.extend({
  name: "ExpansionRelatorioComodo",
  components: {
    BanheiroIcon,
    CozinhaIcon,
    LavanderiaIcon,
    QuintalIcon,
    ExpansionContentRelatorioBanheiro,
    ExpansionContentRelatorioCozinha,
    ExpansionContentRelatorioAreaExterna,
    ExpansionContentRelatorioLavanderia,
  },
  data() {
    return {
      store: useStore(),
      HabitacoesEnum,
    };
  },
  computed: {
    classPanelContent() {
      return "pa-0 toplessBorder mx-0 lowPadding";
    },
    colorPanelContent() {
      return "primary lighten-2";
    },
  },
});
