
import { useStore } from "@/store";
import Vue from "vue";
import { cores, TiposUsoEnum } from "../utils/constants";
import CustomText from "../components/CustomText.vue";
import PessoaIcon from "../assets/icones/iconePessoa.svg";
import GrupoPessoasIcon from "../assets/icones/iconeGrupoPessoas.svg";
import ConfirmarButton from "../components/ConfirmarButton.vue";
import { max } from "lodash";

export default Vue.extend({
  name: "TipoUsoPage",
  components: {
    ConfirmarButton,
    CustomText,
    PessoaIcon,
    GrupoPessoasIcon,
  },
  data: () => ({
    TiposUsoEnum,
    tipoUso: null,
    proximoId: 1,
    dialog: false,
    store: useStore(),
    form: [],
    cores,
    isFormValid: false,
    rules: {
      required: [(v: string) => !!v || "Campo obrigatório"],
    },
  }),
  computed: {
    tipoUsoStore() {
      return this.store.getTipoUso || null;
    },
    pessoasStore() {
      return this.store.getPessoas.length > 0 ? this.store.getPessoas : [];
    },
    mainDivClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex flex-column align-center"
        : "d-flex mt-9";
    },
    imgHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 100 : 250;
    },
    cardClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex flex-column align-center mt-10"
        : "d-flex flex-column align-center mt-10 mx-8";
    },
    cardTitleClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex justify-center"
        : "d-flex justify-center text-h4";
    },
  },
  mounted() {
    this.tipoUso = this.tipoUsoStore;
    if (this.pessoasStore.length > 0) {
      this.populaForm();
    }
  },
  methods: {
    populaForm() {
      this.pessoasStore.forEach((p) => {
        this.form.push({ id: Number(p.id), nome: p.nome, cor: p.cor });
      });
      this.proximoId = Number(max(this.form.map((el) => Number(el.id)))) + 1;
    },
    isUnique(nome: string) {
      let vezes = 0;
      this.form.forEach((p) => {
        if (p && p.nome === nome) {
          vezes += 1;
        }
      });
      return vezes === 1 || "Nome deve ser único";
    },
    clickBotaoTipoUso(tipo) {
      this.tipoUso = tipo;
      this.form.splice(0, this.form.length);
      if (tipo === TiposUsoEnum.COLETIVO) {
        if (this.pessoasStore.length === 0) {
          this.adicionaPessoa();
          this.adicionaPessoa();
        } else {
          this.populaForm();
        }
        this.dialog = true;
      } else {
        this.adicionaPessoa("Habitante");
        this.selecionaTipoUso();
      }
    },
    focaNoProximo(index) {
      if (index < this.form.length - 1) {
        this.$refs[`nomeInput${index + 1}`][0].focus();
      }
      this.$refs.formPessoas.validate();
    },
    adicionaPessoa(nome = null) {
      this.form.push({
        id: this.proximoId,
        nome,
        cor: this.cores[this.form.length],
      });
      this.proximoId += 1;
    },
    removePessoa(index: number) {
      this.form.splice(index, 1);
    },
    selecionaTipoUso() {
      this.dialog = false;
      this.store.setTipoUso({
        tipo: this.tipoUso,
        pessoas: this.form,
      });
      // this.$root.$emit("toast-success", "Tipo de uso selecionado");
      setTimeout(() => {
        this.$router.push({ name: "SelecionarComodo" });
      }, 300);
    },
  },
});
