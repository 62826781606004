
import Vue from "vue";
import DialogAreaExterna from "../components/areaExterna/DialogAreaExterna.vue";
import DialogComodoFinalizado from "../components/DialogComodoFinalizado.vue";
import IlustracaoAreaExterna from "../components/areaExterna/IlustracaoAreaExterna.vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import CardFormsAreaExterna from "../components/areaExterna/CardFormsAreaExterna.vue";
import CardListUsosAreaExterna from "../components/areaExterna/CardListUsosAreaExterna.vue";
import { useStore } from "@/store";

export default Vue.extend({
  name: "AreaExternaPage",
  components: {
    DialogAreaExterna,
    DialogComodoFinalizado,
    IlustracaoAreaExterna,
    ProgressTotalPerCapita,
    CardFormsAreaExterna,
    CardListUsosAreaExterna,
  },
  data: () => ({
    store: useStore(),
    dialog: false,
    dialogFinalizado: false,
    dialogRedutores: false,
    formSelecionado: null,
  }),
  computed: {
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    areaExternaFinalizada() {
      return this.store.isAreaExternaDone;
    },
  },
  methods: {
    abreForm(form: string) {
      this.dialog = true;
      this.formSelecionado = form;
    },
    fechaForm() {
      this.formSelecionado = null;
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog && this.areaExternaFinalizada) {
        this.dialogFinalizado = true;
      }
    },
  },
});
