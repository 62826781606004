
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import SubFormLavarLouca from "./SubFormLavarLouca.vue";
import SubFormLavarAlimentos from "./SubFormLavarAlimentos.vue";
import { useStore } from "@/store";

enum SubForms {
  LAVAR_LOUCA = "LAVAR_LOUCA",
  LAVAR_ALIMENTOS = "LAVAR_ALIMENTOS",
}

export default Vue.extend({
  name: "FormTorneira",
  components: {
    LayoutCard,
    SubFormLavarLouca,
    SubFormLavarAlimentos,
  },
  data() {
    return {
      SubForms,
      selecionado: SubForms.LAVAR_LOUCA,
      store: useStore(),
    };
  },
  methods: {
    subformSelecionado(subform: string) {
      this.selecionado = subform;
    },
    goToProximoPasso(subform: string) {
      switch (subform) {
        case SubForms.LAVAR_LOUCA:
          if (!this.store.isLavagemAlimentosDone) {
            this.selecionado = SubForms.LAVAR_ALIMENTOS;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Torneira completa!");
          }
          break;
        case SubForms.LAVAR_ALIMENTOS:
          if (!this.store.isLavagemLoucaDone) {
            this.selecionado = SubForms.LAVAR_LOUCA;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Torneira completa!");
          }
          break;
        default:
      }
    },
  },
});
