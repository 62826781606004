
import Vue from "vue";
import { useStore } from "@/store";
import { formataNumero } from "@/utils/functions";

export default Vue.extend({
  name: "DivIconesConsumo",
  props: {
    litrosPerCapta: {
      type: Number,
      default: 0,
    },
    litrosPorAno: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formataNumero,
      store: useStore(),
      galoesPorLinha: 8,
      caminhoesPorLinha: 4,
    };
  },
  computed: {
    numGaloes() {
      return this.litrosPerCapta / 20;
    },
    numCaminhoes() {
      return this.litrosPorAno / 20000;
    },
    fileirasGaloes() {
      const fileiras = [];
      if (Math.floor(this.numGaloes / this.galoesPorLinha) > 0) {
        for (const key in [
          ...Array(Math.floor(this.numGaloes / this.galoesPorLinha)).keys(),
        ]) {
          fileiras.push([...Array(this.galoesPorLinha).keys()]);
        }
      }
      if (this.numGaloes % this.galoesPorLinha) {
        fileiras.push([
          ...Array(Math.ceil(this.numGaloes % this.galoesPorLinha)).keys(),
        ]);
      }
      return fileiras;
    },
    fileirasCaminhoes() {
      const fileiras = [];
      if (Math.floor(this.numCaminhoes / this.caminhoesPorLinha) > 0) {
        for (const key in [
          ...Array(
            Math.floor(this.numCaminhoes / this.caminhoesPorLinha)
          ).keys(),
        ]) {
          fileiras.push([...Array(this.caminhoesPorLinha).keys()]);
        }
      }
      if (this.numCaminhoes % this.caminhoesPorLinha) {
        fileiras.push([
          ...Array(
            Math.ceil(this.numCaminhoes % this.caminhoesPorLinha)
          ).keys(),
        ]);
      }
      return fileiras;
    },
  },
});
