
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import {
  segToMin,
  minToSeg,
  getConsumoLavagemRoupasMaoMes,
} from "@/utils/functions";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SubFormLavarRoupa",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      lavagemRoupasSemana: 0,
      lavagemRoupasSegundos: 0,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.lavagemRoupasMao.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.lavagemRoupasMao.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.lavagemRoupasSemana =
      this.store.getConsumoHabitacao.lavagemRoupasMao.vezesPorSemana;
    this.form.lavagemRoupasSegundos = minToSeg(
      Number(this.store.getConsumoHabitacao.lavagemRoupasMao.minutosPorUso)
    );
  },
  computed: {
    lavagemRoupasCompleta() {
      return this.store.getConsumoHabitacao.lavagemRoupasMao.feito;
    },
    totalLavagemRoupas() {
      return getConsumoLavagemRoupasMaoMes(
        this.form.lavagemRoupasSemana,
        segToMin(Number(this.form.lavagemRoupasSegundos)),
        this.store.getHabitacao
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaRoupas() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setConsumoLavagemRoupasMao(
        this.form.lavagemRoupasSemana,
        segToMin(Number(this.form.lavagemRoupasSegundos))
      );
      this.$root.$emit("toast-success");
    },
  },
});
