
import Vue from "vue";

export default Vue.extend({
  name: "LayoutCard",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
});
