
import Vue from "vue";

import Carro from "../../assets/areaExterna/carro.svg";
import Piscina from "../../assets/areaExterna/piscina.svg";
import Chuveiro from "../../assets/areaExterna/chuveiro.svg";
import Plantas from "../../assets/areaExterna/plantas.svg";
import Mangueira from "../../assets/areaExterna/mangueira.svg";
import { useStore } from "@/store";
import { FormsAreaExternaEnum } from "@/utils/constants";

export default Vue.extend({
  name: "CardListUsosAreaExterna",
  components: {
    Carro,
    Piscina,
    Chuveiro,
    Plantas,
    Mangueira,
  },
  data() {
    return {
      FormsAreaExternaEnum,
      store: useStore(),
    };
  },
});
