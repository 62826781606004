
import { StatusEtapasEnum } from "@/utils/constants";
import Vue from "vue";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import {
  segToMin,
  minToSeg,
  getConsumoLavagemLoucaMes,
} from "@/utils/functions";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SubFormLavarLouca",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      lavagemLoucaSemana: 0,
      lavagemLoucaSegundos: 0,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.lavagemLouca.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.lavagemLouca.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.lavagemLoucaSemana =
      this.store.getConsumoHabitacao.lavagemLouca.vezesPorSemana;
    this.form.lavagemLoucaSegundos = minToSeg(
      Number(this.store.getConsumoHabitacao.lavagemLouca.minutosPorUso)
    );
  },
  computed: {
    lavagemLoucaCompleta() {
      return this.store.getConsumoHabitacao.lavagemLouca.feito;
    },
    totalLavagemLouca() {
      return getConsumoLavagemLoucaMes(
        this.form.lavagemLoucaSemana,
        segToMin(Number(this.form.lavagemLoucaSegundos)),
        this.store.getHabitacao
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaLouca() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setConsumoLavagemLouca(
        this.form.lavagemLoucaSemana,
        segToMin(Number(this.form.lavagemLoucaSegundos))
      );
      this.$root.$emit("toast-success");
    },
  },
});
