var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',{class:`text-center ${
      _vm.$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h3 mb-5'
    }`},[_vm._v(" Selecione o tipo de habitação ")]),_c('div',{class:_vm.mainDivClass},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:_vm.cardClass,staticStyle:{"cursor":"pointer"},attrs:{"outlined":"","ripple":"","width":"70%","hover":""},on:{"click":function($event){return _vm.selecionaHabitacao(_vm.HabitacoesEnum.CASA)}}},[_c('CasaIcon',{staticClass:"mt-4",style:(_vm.habitacao === _vm.HabitacoesEnum.CASA || hover
              ? 'filter: grayscale(0%)'
              : 'filter: grayscale(100%)'),attrs:{"height":_vm.imgHeight}}),_c('v-card-title',{class:_vm.cardTitleClass},[_vm._v(" Casa ")])],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"d-flex flex-column align-center mt-10",staticStyle:{"cursor":"pointer"},attrs:{"outlined":"","ripple":"","hover":"","width":"70%"},on:{"click":function($event){return _vm.selecionaHabitacao(_vm.HabitacoesEnum.APARTAMENTO)}}},[_c('PredioIcon',{staticClass:"mt-4",style:(_vm.habitacao === _vm.HabitacoesEnum.APARTAMENTO || hover
              ? 'filter: grayscale(0%)'
              : 'filter: grayscale(100%)'),attrs:{"height":_vm.imgHeight}}),_c('v-card-title',{class:_vm.cardTitleClass},[_vm._v(" Apartamento ")])],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }