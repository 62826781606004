
import Vue from "vue";
import CustomText from "../CustomText.vue";

export default Vue.extend({
  name: "DialogInmetInfo",
  components: {
    CustomText,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
});
