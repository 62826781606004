
import Vue from "vue";

import MaquinaSuperior from "../../assets/lavanderia/maquinaSuperior.svg";
import MaquinaFrontal from "../../assets/lavanderia/maquinaFrontal.svg";
import Torneira from "../../assets/lavanderia/torneira.svg";
import { useStore } from "@/store";
import { FormsLavanderiaEnum, TipoMaquinaRoupaEnum } from "@/utils/constants";

export default Vue.extend({
  name: "CardListUsosLavanderia",
  components: {
    MaquinaSuperior,
    MaquinaFrontal,
    Torneira,
  },
  data() {
    return {
      FormsLavanderiaEnum,
      TipoMaquinaRoupaEnum,
      store: useStore(),
    };
  },
});
