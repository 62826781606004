
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import SubFormPiscina from "./SubFormPiscina.vue";
import TemOuNaoTemButtons from "../TemOuNaoTemButtons.vue";
import { useStore } from "@/store";
import { SimOuNaoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormPiscina",
  components: {
    LayoutCard,
    SubFormPiscina,
    TemOuNaoTemButtons,
  },
  data() {
    return {
      selecionado: false,
      store: useStore(),
    };
  },
  computed: {
    temPiscinaSimOuNao() {
      if (this.store.getConfigHabitacao.temPiscina === true) {
        return SimOuNaoEnum.SIM;
      }
      if (this.store.getConfigHabitacao.temPiscina === false) {
        return SimOuNaoEnum.NAO;
      }
      return null;
    },
  },
  methods: {
    subformSelecionado() {
      if (this.temPiscinaSimOuNao === SimOuNaoEnum.SIM) {
        this.selecionado = true;
      }
    },
    setPiscina(valor: boolean) {
      this.store.setPiscina(valor);
      if (!valor) {
        this.selecionado = false;
        this.goToProximoPasso();
      } else {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Piscina completa!");
    },
  },
});
