import { findIndex, sum } from "lodash";
import {
  DIAS_MES,
  DIAS_SEMANA,
  HabitacoesEnum,
  MESES_ANO,
  TipoChuveiroEnum,
  TipoMaquinaRoupaEnum,
  VALORES_AREA_EXTERNA,
  VALORES_BANHEIRO,
  VALORES_COZINHA,
  VALORES_LAVANDERIA,
  VALORES_REDUTORES,
} from "./constants";
import {
  ValoresChuveiro,
  ValoresBide,
  ValoresDescarga,
  ValoresBanheira,
  PessoaInterface,
  ValoresLavagemMaos,
  ValoresEscovacaoDentes,
  ValoresBarbeamento,
  AcionamentosCaixaDupla,
  BaseDimensionsInterface,
} from "./dto";

export function mapeiaCoords(mapa: number[], baseDim: BaseDimensionsInterface) {
  return mapa.map((coord: number, index: number) => {
    // Corrige coordenadas dos mapas de acordo com alinhamento da base
    if (index % 2 === 0) {
      // se for coord no eixo X (index par), corrige
      return coord * baseDim.scale + baseDim.left;
    }
    return coord * baseDim.scale;
  });
}

export function montaBaseDim(base, scaleFactor) {
  const baseDim: BaseDimensionsInterface = {
    grayscale: 0,
    height: 0,
    left: 0,
    scale: 0,
    width: 0,
  };

  const baseStyle = getComputedStyle(base);
  baseDim.left = Number(baseStyle.left.replaceAll("px", ""));
  baseDim.height = Number(baseStyle.height.replaceAll("px", ""));
  baseDim.width = Number(baseStyle.width.replaceAll("px", ""));
  baseDim.scale = Number(baseStyle.width.replaceAll("px", "")) / scaleFactor;

  return baseDim;
}

export function formataNumero(value: string | number, fractionDigits = 0) {
  if (value) {
    return String(
      Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(Number(value))
    );
  }
  return 0;
}

export function segToMin(segs: number): number {
  return segs / 60;
}

export function minToSeg(mins: number): number {
  return mins * 60;
}

export function minToMinAndSeg(val = 0) {
  const segTotal = minToSeg(val);
  const min = Math.floor(segTotal / 60);
  const seg = segTotal % 60;
  if (min > 0 && seg > 0) {
    return `${min}min e ${seg}s`;
  } else if (seg > 0) {
    return `${seg}s`;
  }
  return `${min}min`;
}

export function getPessoaIndexById(pessoas, id: number) {
  return findIndex(pessoas, (ps: PessoaInterface) => ps.id === id);
}

export function getConsumoBanheiraMes(
  valoresBanheira: ValoresBanheira[],
  habitacao = HabitacoesEnum.CASA
): number {
  if (valoresBanheira.length === 0) return 0;
  const valoresBanheiro = VALORES_BANHEIRO[habitacao];
  const usosMes = sum(
    valoresBanheira.map((el: ValoresBanheira) => el.banheiraMes)
  );
  return valoresBanheiro.litrosPorUsoBanheira * usosMes;
}

export function getConsumoChuveiroMes(
  tipo = TipoChuveiroEnum.CHUVEIRO_ELETRICO,
  valoresChuveiro: ValoresChuveiro[],
  habitacao = HabitacoesEnum.CASA,
  redutor = null
): number {
  if (valoresChuveiro.length === 0) return 0;
  let valoresBanheiro;

  if (redutor && tipo === TipoChuveiroEnum.DUCHA) {
    valoresBanheiro = VALORES_REDUTORES[habitacao].ducha[redutor];
  } else {
    valoresBanheiro = VALORES_BANHEIRO[habitacao];
  }

  const minutosPorSemana: number[] = valoresChuveiro.map(
    (el: ValoresChuveiro) => {
      return el.chuveiroMin * el.chuveiroSemana;
    }
  );

  let litrosPorMin = 0;
  if (redutor && tipo === TipoChuveiroEnum.DUCHA) {
    litrosPorMin = Number(valoresBanheiro);
  } else {
    litrosPorMin =
      tipo === TipoChuveiroEnum.CHUVEIRO_ELETRICO
        ? valoresBanheiro.litrosPorMinChuveiroEletrico
        : valoresBanheiro.litrosPorMinDucha;
  }

  return (
    litrosPorMin * sum(minutosPorSemana) * (DIAS_MES / DIAS_SEMANA) // calculo por mês
  );
}

export function getConsumoBideMes(
  valoresBide: ValoresBide[],
  habitacao = HabitacoesEnum.CASA
): number {
  if (valoresBide.length === 0) return 0;
  const valoresBanheiro = VALORES_BANHEIRO[habitacao];
  const minutosPorSemana: number[] = valoresBide.map((el: ValoresBide) => {
    return el.bideMin * el.bideSemana;
  });
  return (
    valoresBanheiro.litrosPorMinBide *
    sum(minutosPorSemana) *
    (DIAS_MES / DIAS_SEMANA) // calculo por mês
  );
}

export function getConsumoDescargaMes(
  valoresDescarga: ValoresDescarga[] | AcionamentosCaixaDupla[],
  habitacao = HabitacoesEnum.CASA,
  redutor = null
): number {
  if (valoresDescarga.length === 0) return 0;
  let totalPorPessoa;
  let valoresBanheiro;
  if (!redutor) {
    valoresBanheiro = VALORES_BANHEIRO[habitacao];
    totalPorPessoa = (valoresDescarga as ValoresDescarga[]).map(
      (el: ValoresDescarga) => {
        return (
          (el.simplesDia * valoresBanheiro.litrosPorUsoDescargaCompleta +
            el.duplaCompletaDia * valoresBanheiro.litrosPorUsoDescargaCompleta +
            el.duplaCurtaDia * valoresBanheiro.litrosPorUsoDescargaCurta +
            el.valvulaDia * valoresBanheiro.litrosPorUsoDescargaValvula) *
          DIAS_MES
        );
      }
    );
  } else {
    // COM REDUTORES
    valoresBanheiro = VALORES_REDUTORES[habitacao].privada;
    totalPorPessoa = (valoresDescarga as AcionamentosCaixaDupla[]).map(
      (el: AcionamentosCaixaDupla) => {
        return (
          (el.duplaCompletaDia * valoresBanheiro.completa +
            el.duplaCurtaDia * valoresBanheiro.curta) *
          DIAS_MES
        );
      }
    );
  }
  return sum(totalPorPessoa);
}

export function getConsumoLavarMaoMes(
  dadosLavagemMaos: ValoresLavagemMaos[],
  habitacao = HabitacoesEnum.CASA,
  redutor = null
) {
  if (dadosLavagemMaos.length === 0) return 0;
  let valoresBanheiro;

  if (redutor) {
    valoresBanheiro = VALORES_REDUTORES[habitacao].torneiraLavatorio[redutor];
  } else {
    valoresBanheiro = VALORES_BANHEIRO[habitacao];
  }
  const litrosPorMin = redutor
    ? valoresBanheiro
    : valoresBanheiro.litrosPorMinTorneiraLavatorio;
  const litrosMesPessoa: number[] = dadosLavagemMaos.map(
    (el: ValoresLavagemMaos) => {
      return el.lavagemMin * el.lavagemDia * litrosPorMin * DIAS_MES;
    }
  );
  return sum(litrosMesPessoa);
}

export function getConsumoEscovarDentesMes(
  dadosEscovacaoDentes: ValoresEscovacaoDentes[],
  habitacao = HabitacoesEnum.CASA,
  redutor = null
) {
  if (dadosEscovacaoDentes.length === 0) return 0;
  let valoresBanheiro;

  if (redutor) {
    valoresBanheiro = VALORES_REDUTORES[habitacao].torneiraLavatorio[redutor];
  } else {
    valoresBanheiro = VALORES_BANHEIRO[habitacao];
  }
  const litrosPorMin = redutor
    ? valoresBanheiro
    : valoresBanheiro.litrosPorMinTorneiraLavatorio;
  const litrosMesPessoa: number[] = dadosEscovacaoDentes.map(
    (el: ValoresEscovacaoDentes) => {
      return el.escovacaoMin * el.escovacaoDia * litrosPorMin * DIAS_MES;
    }
  );
  return sum(litrosMesPessoa);
}

export function getConsumoBarbearMes(
  dadosBarbeacao: ValoresBarbeamento[],
  habitacao = HabitacoesEnum.CASA,
  redutor = null
) {
  if (dadosBarbeacao.length === 0) return 0;
  let valoresBanheiro;

  if (redutor) {
    valoresBanheiro = VALORES_REDUTORES[habitacao].torneiraLavatorio[redutor];
  } else {
    valoresBanheiro = VALORES_BANHEIRO[habitacao];
  }
  const litrosPorMin = redutor
    ? valoresBanheiro
    : valoresBanheiro.litrosPorMinTorneiraLavatorio;
  const litrosMesPessoa: number[] = dadosBarbeacao.map(
    (el: ValoresBarbeamento) => {
      return (
        (el.barbaSemana / DIAS_SEMANA) * el.barbaMin * litrosPorMin * DIAS_MES
      );
    }
  );
  return sum(litrosMesPessoa);
}

export function getConsumoMaquinaLoucaMes(
  usosPorSemanaMaquinaLouca: number,
  habitacao = HabitacoesEnum.CASA
) {
  const valorMaquina = VALORES_COZINHA[habitacao].litrosPorCicloLavaLouca;
  return ((usosPorSemanaMaquinaLouca * valorMaquina) / DIAS_SEMANA) * DIAS_MES;
}

export function getConsumoLavagemLoucaMes(
  vezesPorSemana = 0,
  minutosPorUso = 0,
  habitacao = HabitacoesEnum.CASA,
  redutor = null
) {
  let valorPia;
  if (redutor) {
    valorPia = VALORES_REDUTORES[habitacao].torneiraPia[redutor];
  } else {
    valorPia = VALORES_COZINHA[habitacao].litrosPorMinTorneiraPia;
  }

  return ((vezesPorSemana * minutosPorUso * valorPia) / DIAS_SEMANA) * DIAS_MES;
}

export function getConsumoLavagemAlimentosMes(
  vezesPorSemana = 0,
  minutosPorUso = 0,
  habitacao = HabitacoesEnum.CASA,
  redutor = null
) {
  let valorPia;
  if (redutor) {
    valorPia = VALORES_REDUTORES[habitacao].torneiraPia[redutor];
  } else {
    valorPia = VALORES_COZINHA[habitacao].litrosPorMinTorneiraPia;
  }

  return ((vezesPorSemana * minutosPorUso * valorPia) / DIAS_SEMANA) * DIAS_MES;
}

export function getConsumoMaquinaRoupasMes(
  usosPorSemanaMaquinaRoupa: number,
  tipo = TipoMaquinaRoupaEnum.SUPERIOR as string
) {
  const valorMaquina = tipo
    ? VALORES_LAVANDERIA[tipo].litrosPorCicloLavaRoupas
    : 0;
  return ((usosPorSemanaMaquinaRoupa * valorMaquina) / DIAS_SEMANA) * DIAS_MES;
}

export function getConsumoLavagemRoupasMaoMes(
  vezesPorSemana = 0,
  minutosPorUso = 0,
  habitacao = HabitacoesEnum.CASA as string,
  redutor = null
) {
  let valorTorneira;
  if (redutor) {
    valorTorneira = VALORES_REDUTORES[habitacao].torneiraTanque[redutor];
  } else {
    valorTorneira = VALORES_LAVANDERIA[habitacao].litrosPorMinTorneiraTanque;
  }

  return (
    ((vezesPorSemana * minutosPorUso * valorTorneira) / DIAS_SEMANA) * DIAS_MES
  );
}

export function getConsumoLavagemPisoMes(
  vezesPorSemana = 0,
  minutosPorUso = 0,
  habitacao = HabitacoesEnum.CASA as string,
  redutor = null
) {
  let valorTorneira;
  if (redutor) {
    valorTorneira = VALORES_REDUTORES[habitacao].torneiraTanque[redutor];
  } else {
    valorTorneira = VALORES_LAVANDERIA[habitacao].litrosPorMinTorneiraTanque;
  }

  return (
    ((vezesPorSemana * minutosPorUso * valorTorneira) / DIAS_SEMANA) * DIAS_MES
  );
}

export function getConsumoRegaJardimMes(vezesPorSemana = 0, minutosPorUso = 0) {
  const valorTorneira = VALORES_AREA_EXTERNA.litrosPorMinTorneiraExterna;

  return (
    ((vezesPorSemana * minutosPorUso * valorTorneira) / DIAS_SEMANA) * DIAS_MES
  );
}

export function getConsumoPiscinaMes(
  volumePiscina = 0,
  preenchimentosPorAno = 0
) {
  const consumoPorAno =
    volumePiscina * preenchimentosPorAno + volumePiscina * 0.2; // 0.2 é um fator de reposiçao de agua
  return consumoPorAno / MESES_ANO;
}

export function getConsumoDuchaExternaMes(
  vezesPorMes = 0,
  minutosPorUso = 0,
  redutor = null
) {
  let valorDucha;
  if (redutor) {
    valorDucha = VALORES_REDUTORES.Casa.duchaExterna.restritorVazao;
  } else {
    valorDucha = VALORES_AREA_EXTERNA.litrosPorMinDuchaExterna;
  }

  return vezesPorMes * minutosPorUso * valorDucha;
}

export function getConsumoLavagemCarroMes(
  vezesPorMes = 0,
  minutosPorUso = 0,
  pressurizador = false
) {
  let valorTorneira;
  if (pressurizador) {
    valorTorneira = VALORES_REDUTORES.Casa.torneiraExterna.lavadoraPressurizada;
  } else {
    valorTorneira = VALORES_AREA_EXTERNA.litrosPorMinTorneiraExterna;
  }

  return vezesPorMes * minutosPorUso * valorTorneira;
}

export function getConsumoLavagemPatioMes(
  vezesPorMes = 0,
  minutosPorUso = 0,
  pressurizador = false
) {
  let valorTorneira;
  if (pressurizador) {
    valorTorneira = VALORES_REDUTORES.Casa.torneiraExterna.lavadoraPressurizada;
  } else {
    valorTorneira = VALORES_AREA_EXTERNA.litrosPorMinTorneiraExterna;
  }

  return vezesPorMes * minutosPorUso * valorTorneira;
}

export function getConsumoLavagemCalcadaMes(
  vezesPorMes = 0,
  minutosPorUso = 0,
  pressurizador = false
) {
  let valorTorneira;
  if (pressurizador) {
    valorTorneira = VALORES_REDUTORES.Casa.torneiraExterna.lavadoraPressurizada;
  } else {
    valorTorneira = VALORES_AREA_EXTERNA.litrosPorMinTorneiraExterna;
  }

  return vezesPorMes * minutosPorUso * valorTorneira;
}
