
import Vue from "vue";
import CardUsoRelatorio from "./CardUsoRelatorio.vue";
import MaquinaLouca from "../../assets/cozinha/maquinaLouca.svg";
import Pia from "../../assets/cozinha/pia.svg";
// redutores
import Pulverizador from "../../assets/redutores/pulverizador.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import { RedutoresNomes, RedutoresTorneiraPiaEnum } from "@/utils/constants";
import { useStore } from "@/store";
import { minToMinAndSeg } from "@/utils/functions";

export default Vue.extend({
  name: "ExpansionContentRelatorioCozinha",
  components: {
    CardUsoRelatorio,
    MaquinaLouca,
    Pia,
    // redutores
    Pulverizador,
    RestritorVazao,
  },
  data() {
    return {
      store: useStore(),
      RedutoresNomes,
      minToMinAndSeg,
      RedutoresTorneiraPiaEnum,
    };
  },
  computed: {
    pessoasStore() {
      return this.store.getPessoas;
    },
    nomeRedutorPia() {
      switch (this.store.configRedutores.piaCozinha) {
        case RedutoresTorneiraPiaEnum.RESTRITORVAZAO:
          return RedutoresNomes.restritorVazao;
        case RedutoresTorneiraPiaEnum.PULVERIZADOR:
          return RedutoresNomes.pulverizador;
        default:
          return "";
      }
    },
    possuiMaquinaLouca() {
      return this.store.getConfigHabitacao.temMaquinaLouca;
    },
    maquinaLouca() {
      if (this.possuiMaquinaLouca) {
        return parseFloat(this.store.getConsumoMaquinaLoucaMes);
      }
      return 0;
    },
    piaCozinha() {
      return (
        parseFloat(this.store.getConsumoLavagemLoucaMes()) +
        parseFloat(this.store.getConsumoLavagemAlimentosMes())
      );
    },
    piaCozinhaRedutor() {
      return (
        parseFloat(this.store.getConsumoLavagemLoucaMes(true)) +
        parseFloat(this.store.getConsumoLavagemAlimentosMes(true))
      );
    },
  },
});
