
import Vue from "vue";
import ResultadosCard from "../../components/ResultadosCard.vue";

export default Vue.extend({
  name: "CardUsoRelatorio",
  components: {
    ResultadosCard,
  },
  props: {
    titleUso: {
      type: String,
      default: "Title",
    },
    tipoSelecionado: {
      type: String,
      default: "TipoSelecionado",
    },
    nomeRedutor: {
      type: String,
      default: "NomeRedutor",
    },
    temRedutor: {
      type: Boolean,
      default: false,
    },
    temTipo: {
      type: Boolean,
      default: false,
    },
    numPessoas: {
      type: Number,
      default: 1,
    },
    consumo: {
      type: Number,
      default: 1,
    },
    consumoComRedutores: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selecionado: false,
      selecionado2: false,
    };
  },
  computed: {
    classBoxTitle() {
      return "d-flex justify-center subtitle-1 mt-4 font-weight-medium primary--text text--lighten-2";
    },
    classImgTitle() {
      return "pa-1 d-flex justify-center flex-column align-center subtitle-1 font-weight-light";
    },
    classCardUso() {
      return "mt-2 mx-0";
    },
    classCardTitleUso() {
      return "pl-0 py-0 primary--text pointerCursor";
    },
  },
});
