
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import { getConsumoLavagemPatioMes } from "@/utils/functions";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SubFormLavarPatio",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      lavagemPatioMes: 0,
      lavagemPatioMinutos: 0,
      pressurizador: false,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.lavagemPatio.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.lavagemPatio.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.lavagemPatioMes =
      this.store.getConsumoHabitacao.lavagemPatio.vezesPorMes;
    this.form.lavagemPatioMinutos = Number(
      this.store.getConsumoHabitacao.lavagemPatio.minutosPorUso
    );
    this.form.pressurizador = this.store.getConfigHabitacao.pressurizadorPatio;
  },
  computed: {
    labelPressurizador() {
      return this.form.pressurizador
        ? "Sim, utilizo lavadora pressurizada"
        : "Não utilizo lavadora pressurizada";
    },
    lavagemPatioCompleta() {
      return this.store.getConsumoHabitacao.lavagemPatio.feito;
    },
    totalLavagemPatio() {
      return getConsumoLavagemPatioMes(
        this.form.lavagemPatioMes,
        this.form.lavagemPatioMinutos,
        this.form.pressurizador
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaLavagemPatio() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setConsumoLavagemPatio(
        this.form.lavagemPatioMes,
        Number(this.form.lavagemPatioMinutos)
      );
      this.$root.$emit("toast-success");
    },
  },
  watch: {
    "form.pressurizador": {
      handler() {
        this.store.setLavagemPressurizadaPatio(this.form.pressurizador);
      },
    },
  },
});
