
import Vue from "vue";
import Banheira from "../../assets/banheiro/banheira.svg";
import Bide from "../../assets/banheiro/bide.svg";
import Ducha from "../../assets/banheiro/ducha.svg";
import CubaPia from "../../assets/banheiro/cubaPia.svg";
import Chuveiro from "../../assets/banheiro/chuveiro.svg";
import Privada from "../../assets/banheiro/privada.svg";
import PrivadaCaixa from "../../assets/banheiro/privadaCaixa.svg";
import PrivadaValvula from "../../assets/banheiro/privadaValvula.svg";
import CardUsoRelatorio from "./CardUsoRelatorio.vue";
// redutores
import Arejador from "../../assets/redutores/arejador.svg";
import Pulverizador from "../../assets/redutores/pulverizador.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import AcionamentoDuplo from "../../assets/redutores/acionamentoDuplo.svg";
import {
  RedutoresNomes,
  RedutoresTorneiraLavatorioEnum,
  TipoChuveiroEnum,
  TiposDescargaEnum,
  UsosBanheiroEnum,
} from "@/utils/constants";
import { useStore } from "@/store";
import { sum } from "lodash";
import { minToMinAndSeg } from "@/utils/functions";

export default Vue.extend({
  name: "ExpansionContentRelatorioBanheiro",
  components: {
    Privada,
    Chuveiro,
    Banheira,
    Bide,
    Ducha,
    CubaPia,
    CardUsoRelatorio,
    RestritorVazao,
    PrivadaCaixa,
    PrivadaValvula,
    // redutores
    Arejador,
    Pulverizador,
    AcionamentoDuplo,
  },
  data() {
    return {
      store: useStore(),
      TiposDescargaEnum,
      TipoChuveiroEnum,
      RedutoresNomes,
      RedutoresTorneiraLavatorioEnum,
      minToMinAndSeg,
    };
  },
  computed: {
    pessoasStore() {
      return this.store.getPessoas;
    },
    nomeRedutorPia() {
      switch (this.store.configRedutores.torneiraLavatorio) {
        case RedutoresTorneiraLavatorioEnum.RESTRITORVAZAO:
          return RedutoresNomes.restritorVazao;
        case RedutoresTorneiraLavatorioEnum.AREJADOR:
          return RedutoresNomes.arejador;
        case RedutoresTorneiraLavatorioEnum.PULVERIZADOR:
          return RedutoresNomes.pulverizador;
        default:
          return "";
      }
    },
    // VALORES CONSUMO
    banheira() {
      return this.store.getConfigHabitacao.temBanheira
        ? parseInt(
            this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BANHEIRA)
          )
        : 0;
    },
    descarga() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.DESCARGA)
      );
    },
    chuveiro() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.CHUVEIRO)
      );
    },
    bide() {
      return this.store.getConfigHabitacao.temBide
        ? parseInt(this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BIDE))
        : 0;
    },
    piaLavatorio() {
      const sumVal = sum([
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BARBEAMENTO),
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.LAVAGEM_MAOS),
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.ESCOVACAO),
      ]);
      return parseInt(`${sumVal}`);
    },
    // COM REDUTOR
    descargaRedutor() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.DESCARGA, true)
      );
    },
    chuveiroRedutor() {
      return parseInt(
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.CHUVEIRO, true)
      );
    },
    piaLavatorioRedutor() {
      const sumVal = sum([
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.BARBEAMENTO, true),
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.LAVAGEM_MAOS,
          true
        ),
        this.store.getConsumoUsoBanheiroMes(UsosBanheiroEnum.ESCOVACAO, true),
      ]);
      return parseInt(`${sumVal}`);
    },
  },
});
