
import Vue from "vue";
import CardUsoRelatorio from "./CardUsoRelatorio.vue";
import Carro from "../../assets/areaExterna/carro.svg";
import Piscina from "../../assets/areaExterna/piscina.svg";
import Plantas from "../../assets/areaExterna/plantas.svg";
import Chuveiro from "../../assets/areaExterna/chuveiro.svg";
import Mangueira from "../../assets/areaExterna/mangueira.svg";
// redutores
import Pressurizador from "../../assets/redutores/pressurizador.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import { RedutoresNomes } from "@/utils/constants";
import { useStore } from "@/store";
import { minToMinAndSeg } from "@/utils/functions";

export default Vue.extend({
  name: "ExpansionContentRelatorioAreaExterna",
  components: {
    CardUsoRelatorio,
    Carro,
    Piscina,
    Plantas,
    Chuveiro,
    Mangueira,
    // redutores
    Pressurizador,
    RestritorVazao,
  },
  data() {
    return {
      store: useStore(),
      RedutoresNomes,
      minToMinAndSeg,
    };
  },
  computed: {
    pessoasStore() {
      return this.store.getPessoas;
    },
    possuiCarro() {
      return this.store.getConfigHabitacao.temCarro;
    },
    possuiPiscina() {
      return this.store.getConfigHabitacao.temPiscina;
    },
    possuiDuchaExterna() {
      return this.store.getConfigHabitacao.temPiscina;
    },
    possuiJardim() {
      return this.store.getConfigHabitacao.temJardim;
    },
    // CONSUMO
    carro() {
      return parseFloat(this.store.getConsumoLavagemCarroMes());
    },
    piscina() {
      return parseFloat(this.store.getConsumoPiscinaMes);
    },
    duchaExterna() {
      return parseFloat(this.store.getConsumoDuchaExternaMes());
    },
    regaJardim() {
      return parseFloat(this.store.getConsumoRegaJardimMes);
    },
    mangueira() {
      return parseFloat(
        this.store.getConsumoLavagemPatioMes() +
          this.store.getConsumoLavagemCalcadaMes()
      );
    },
    // REDUTORES
    carroRedutores() {
      return parseFloat(this.store.getConsumoLavagemCarroMes(true));
    },
    duchaExternaRedutores() {
      return parseFloat(this.store.getConsumoDuchaExternaMes(true));
    },
    mangueiraRedutores() {
      return parseFloat(
        this.store.getConsumoLavagemPatioMes(true) +
          this.store.getConsumoLavagemCalcadaMes(true)
      );
    },
  },
});
