
import Vue from "vue";
import { RedutoresNomes, RedutoresTodosEnum } from "../../utils/constants";
import CustomText from "../../components/CustomText.vue";
import Pressurizador from "../../assets/redutores/pressurizador.svg";
import AcionamentoDuplo from "../../assets/redutores/acionamentoDuplo.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import Arejador from "../../assets/redutores/arejador.svg";
import Pulverizador from "../../assets/redutores/pulverizador.svg";

export default Vue.extend({
  name: "DialogRedutoresInfo",
  components: {
    CustomText,
    Pressurizador,
    RestritorVazao,
    AcionamentoDuplo,
    Arejador,
    Pulverizador,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    redutor: {
      type: String,
      default: null,
    },
  },
  computed: {
    nomeRedutor() {
      return RedutoresNomes[this.redutor];
    },
  },
  data() {
    return {
      RedutoresTodosEnum,
      RedutoresNomes,
      texto: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.`,
    };
  },
});
