
import Vue from "vue";
import TheToastSnackbar from "./components/TheToastSnackbar.vue";
import TheAppFooter from "./components/TheAppFooter.vue";
import TheNavDrawer from "./components/TheNavDrawer.vue";
import TheDialogConfirmacao from "./components/TheDialogConfirmacao.vue";
import DialogRedutoresFinalizados from "./components/DialogRedutoresFinalizados.vue";
import { useStore } from "./store";
import { EtapasDoPreenchimento } from "./utils/constants";

export default Vue.extend({
  name: "App",
  components: {
    TheToastSnackbar,
    TheAppFooter,
    TheNavDrawer,
    TheDialogConfirmacao,
    DialogRedutoresFinalizados,
  },
  data: () => ({
    store: useStore(),
    drawer: false,
    dialogFimRedutores: false,
    EtapasDoPreenchimento,
    animatedClass:
      "animate__heartBeat animate__infinite animate__animated animate__slower",
  }),
  mounted() {
    this.$root.$confirm = this.$refs.dialogConfirmacao.open;
    // se estiver na etapa III
    if (this.etapaPreenchimento === EtapasDoPreenchimento.FIM) {
      if (this.$route.name !== "FimPage") {
        this.$router.push({ name: "FimPage" });
      }
      return;
    }
    if (this.etapaPreenchimento === EtapasDoPreenchimento.AGUACHUVA) {
      if (this.$route.name !== "AguaChuva") {
        this.$router.push({ name: "AguaChuva" });
      }
      return;
    }
    // se estiver na etapa I sem escolher o basico ainda
    if (!this.habitacaoStore || !this.tipoUsoStore) {
      if (this.$route.name !== "LandingPage") {
        this.$router.push({ name: "LandingPage" });
      }
      return;
    }
    if (this.$route.name !== "SelecionarComodo") {
      this.$router.push({ name: "SelecionarComodo" });
    }
  },
  computed: {
    mainClass() {
      if (
        !["LandingPage", "InfoPage"].includes(this.$route.name) &&
        !this.$vuetify.breakpoint.smAndDown
      ) {
        return "mb-8";
      }
      return "";
    },
    inicioOk() {
      return !!this.habitacaoStore && !!this.tipoUsoStore;
    },
    habitacaoStore() {
      return this.store.getHabitacao || null;
    },
    tipoUsoStore() {
      return this.store.getTipoUso || null;
    },
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    icon() {
      switch (this.etapaPreenchimento) {
        case EtapasDoPreenchimento.CONSUMO:
          return "mdi-faucet";
        case EtapasDoPreenchimento.REDUTORES:
          return "mdi-water-opacity";
        case EtapasDoPreenchimento.AGUACHUVA:
          return "mdi-weather-pouring";
        case EtapasDoPreenchimento.FIM:
          return "mdi-check";
        default:
          return "";
      }
    },
    titleEtapa() {
      if (this.$route.name === "LandingPage") {
        return "IPT - Calculadora de água";
      }
      switch (this.etapaPreenchimento) {
        case EtapasDoPreenchimento.CONSUMO:
          return "I. Cálculo de consumo";
        case EtapasDoPreenchimento.REDUTORES:
          return "II. Redutores de consumo";
        case EtapasDoPreenchimento.AGUACHUVA:
          return "III. Uso de água de chuva";
        case EtapasDoPreenchimento.FIM:
          return "IV. Resultados";
        default:
          return "";
      }
    },
  },
  methods: {
    zerarStore() {
      this.store.zerarStore();
      if (this.$route.name !== "LandingPage") {
        this.$router.push({ name: "LandingPage" });
      }
    },
    calcular() {
      this.$router.push({ name: "ResultadosPage" });
    },
  },
});
