
import Vue from "vue";
import CustomText from "../components/CustomText.vue";
import { SimOuNaoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "OpcoesPanel",
  components: {
    CustomText,
  },
  props: {
    textoSim: {
      type: String,
      default: null,
    },
    textoNao: {
      type: String,
      default: null,
    },
    titulo: {
      type: String,
      default: null,
    },
    subtitulo: {
      type: String,
      default: null,
    },
    selecionado: {
      type: String,
      default: null,
    },
    fecharPainel: {
      type: Boolean,
      default: false,
    },
    comecaAberto: {
      type: Boolean,
      default: false,
    },
    iconeEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SimOuNaoEnum,
      model: null,
    };
  },
  mounted() {
    this.model = this.comecaAberto ? 0 : null;
  },
  methods: {
    btnStyle(tipo: string) {
      if (this.selecionado === null) return "filter: sepia(60%)";
      if (tipo === this.selecionado) {
        return "filter: grayscale(0%)";
      } else {
        return "filter: grayscale(100%)";
      }
    },
  },
  watch: {
    fecharPainel() {
      this.model = null;
    },
  },
});
