
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmarButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    voltarDisabled: {
      type: Boolean,
      default: false,
    },
    showVoltar: {
      type: Boolean,
      default: true,
    },
  },
});
