
import { SimOuNaoEnum } from "@/utils/constants";
import Vue from "vue";

export default Vue.extend({
  name: "TemOuNaoTemButtons",
  props: {
    textoSim: {
      type: String,
      default: null,
    },
    textoNao: {
      type: String,
      default: null,
    },
    selecionado: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      SimOuNaoEnum,
    };
  },
  methods: {
    btnStyle(tipo: string) {
      if (this.selecionado === null) return "filter: sepia(60%)";
      if (tipo === this.selecionado) {
        return "filter: grayscale(0%)";
      } else {
        return "filter: grayscale(100%)";
      }
    },
  },
});
