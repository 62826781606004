
import Vue from "vue";
import { useStore } from "@/store";
import CustomText from "../components/CustomText.vue";
import StepperAguaChuva from "../components/aguaChuva/StepperAguaChuva.vue";
import CardResultados from "../components/aguaChuva/CardResultados.vue";
import { AcoesEnum } from "@/utils/constants";

export default Vue.extend({
  name: "AguaChuva",
  components: {
    CustomText,
    StepperAguaChuva,
    CardResultados,
  },
  data: () => ({
    store: useStore(),
    iniciado: false,
    showResultados: false,
    stepperKey: true,
  }),
  computed: {
    dadosInfraColetaChuva() {
      return {
        reservatorioInferior:
          this.store.getConfigHabitacao.reservatorioInferior,
        reservatorioSuperior:
          this.store.getConfigHabitacao.reservatorioSuperior,
        areaCaptacao: this.store.getConfigHabitacao.areaCaptacao,
        localizacao: this.store.getConfigHabitacao.localizacao,
      };
    },
  },
  methods: {
    calcularDados() {
      this.showResultados = true;
    },
    flipKey() {
      this.showResultados = false;
      window.scrollTo(0, 0);
      this.stepperKey = !this.stepperKey;
    },
    async finalizar() {
      if (await this.$root.$confirm(AcoesEnum.FINALIZAR_ETAPA)) {
        this.store.finalizaEtapaChuva();
        this.$router.push({ name: "FimPage" });
      }
    },
  },
});
