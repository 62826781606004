
import Vue from "vue";
import { useStore } from "../store";
import {
  AcoesEnum,
  EtapasDoPreenchimento,
  HabitacoesEnum,
} from "../utils/constants";
import ButtonProximaEtapa from "./ButtonProximaEtapa.vue";

export default Vue.extend({
  name: "TheNavDrawer",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonProximaEtapa,
  },
  data: () => ({
    store: useStore(),
    EtapasDoPreenchimento,
    dialogConfirmar: false,
    acao: null,
  }),
  computed: {
    menu() {
      if (this.etapaPreenchimento === EtapasDoPreenchimento.CONSUMO) {
        return this.menuConsumo;
      } else if (this.etapaPreenchimento === EtapasDoPreenchimento.REDUTORES) {
        return this.menuRedutores;
      } else if (this.etapaPreenchimento === EtapasDoPreenchimento.AGUACHUVA) {
        return this.menuChuva;
      }
      return [];
    },
    menuConsumo() {
      return [
        {
          title: "Pagina inicial",
          icon: "mdi-home",
          to: { name: "LandingPage" },
          disabled: this.habitacaoOk && this.tipoUsoOk,
          class: "",
          completo: true,
          show: true,
        },
        {
          title: "Tipo de habitação",
          icon: "mdi-office-building",
          to: { name: "HabitacaoPage" },
          disabled: this.habitacaoOk && this.tipoUsoOk,
          class: "",
          completo: this.habitacaoOk,
          show: true,
        },
        {
          title: "Tipo de uso",
          icon: "mdi-account-group",
          to: { name: "TipoUsoPage" },
          disabled: this.habitacaoOk && this.tipoUsoOk,
          class: "",
          completo: this.tipoUsoOk,
          show: !!this.store.getHabitacao,
        },
        {
          title: "Cômodos",
          icon: "mdi-folder-home",
          to: { name: "SelecionarComodo" },
          disabled: false,
          class: "",
          completo: false,
          show: this.habitacaoOk && this.tipoUsoOk,
        },
        {
          title: "Banheiro",
          icon: "mdi-toilet",
          to: { name: "BanheiroPage" },
          disabled: false,
          class: "ml-4",
          completo: this.store.isBanheiroDone,
          show: this.habitacaoOk && this.tipoUsoOk,
        },
        {
          title: "Cozinha",
          icon: "mdi-stove",
          to: { name: "CozinhaPage" },
          disabled: false,
          class: "ml-4",
          completo: this.store.isCozinhaDone,
          show: this.habitacaoOk && this.tipoUsoOk,
        },
        {
          title: "Lavanderia",
          icon: "mdi-washing-machine",
          to: { name: "LavanderiaPage" },
          disabled: false,
          class: "ml-4",
          completo: this.store.isLavanderiaDone,
          show: this.habitacaoOk && this.tipoUsoOk,
        },
        {
          title: "Área externa",
          icon: "mdi-tree",
          to: { name: "AreaExternaPage" },
          disabled: false,
          class: "ml-4",
          completo: this.store.isAreaExternaDone,
          show: this.habitacaoOk && this.tipoUsoOk,
        },
      ];
    },
    menuRedutores() {
      return [
        {
          title: "Cômodo",
          icon: "mdi-folder-home",
          to: { name: "SelecionarComodo" },
          disabled:
            this.store.getEtapaPreenchimento === EtapasDoPreenchimento.CONSUMO,
          class: "",
          completo: false,
        },
        {
          title: "Banheiro",
          icon: "mdi-toilet",
          to: { name: "BanheiroRedutoresPage" },
          disabled: false,
          show: true,
          class: "ml-1",
          completo: this.store.getConsumoHabitacao.redutoresBanheiroDone,
        },
        {
          title: "Cozinha",
          icon: "mdi-stove",
          to: { name: "CozinhaRedutoresPage" },
          disabled: false,
          show: true,
          class: "ml-1",
          completo: this.store.getConsumoHabitacao.redutoresCozinhaDone,
        },
        {
          title: "Lavanderia",
          icon: "mdi-washing-machine",
          to: { name: "LavanderiaRedutoresPage" },
          disabled: false,
          show: true,
          class: "ml-1",
          completo: this.store.getConsumoHabitacao.redutoresLavanderiaDone,
        },
        {
          title: "Área externa",
          icon: "mdi-tree",
          to: { name: "AreaExternaPage" },
          disabled: false,
          show: this.store.getHabitacao === HabitacoesEnum.CASA,
          class: "ml-1",
          completo: this.store.getConsumoHabitacao.redutoresAreaExternaDone,
        },
      ];
    },
    menuChuva() {
      return [
        {
          title: "Água da chuva",
          icon: "mdi-weather-pouring",
          to: { name: "AguaChuva" },
          disabled: false,
          show: true,
          class: "ml-1",
          completo: false,
        },
      ];
    },
    exibirFinalizarEtapa() {
      return (
        (this.store.getEtapaPreenchimento === EtapasDoPreenchimento.REDUTORES &&
          this.store.etapaRedutoresDone) ||
        (this.store.getEtapaPreenchimento === EtapasDoPreenchimento.CONSUMO &&
          this.store.todosComodosDone)
      );
    },
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    habitacaoOk() {
      return !!this.store.getHabitacao;
    },
    tipoUsoOk() {
      return this.store.getPessoas.length > 0;
    },
  },
  methods: {
    async zerarStore() {
      if (await this.$root.$confirm(AcoesEnum.RECOMECAR)) {
        this.store.zerarStore();
        if (this.$route.name !== "LandingPage") {
          this.$router.push({ name: "LandingPage" });
        }
        this.$emit("input", false);
      }
    },
    async voltarEtapa() {
      if (await this.$root.$confirm(AcoesEnum.VOLTAR_ETAPA)) {
        if (
          this.store.getEtapaPreenchimento === EtapasDoPreenchimento.REDUTORES
        ) {
          this.$root.$emit("toast-success", "Etapa 'Redutores' desfeita");
          this.store.desfazerRedutores();
          if (this.$route.name !== "SelecionarComodo") {
            this.$router.push({ name: "SelecionarComodo" });
          }
          this.$emit("input", false);
        } else if (
          this.store.getEtapaPreenchimento === EtapasDoPreenchimento.AGUACHUVA
        ) {
          this.$root.$emit("toast-success", "Etapa 'Agua da chuva' desfeita");
          this.store.desfazerColetaAguaChuva();
          if (this.$route.name !== "RedutoresInfo") {
            this.$router.push({ name: "RedutoresInfo" });
          }
          this.$emit("input", false);
        } else if (
          this.store.getEtapaPreenchimento === EtapasDoPreenchimento.FIM
        ) {
          if (this.store.getHabitacao === HabitacoesEnum.CASA) {
            this.store.desfazerFim();
            if (this.$route.name !== "AguaChuva") {
              this.$router.push({ name: "AguaChuva" });
            }
            this.$emit("input", false);
          } else {
            this.store.desfazerRedutores();
            if (this.$route.name !== "SelecionarComodo") {
              this.$router.push({ name: "SelecionarComodo" });
            }
            this.$emit("input", false);
          }
        }
      }
    },
    async finalizarEtapa() {
      this.acao = AcoesEnum.FINALIZAR_ETAPA;
      if (
        this.store.getEtapaPreenchimento === EtapasDoPreenchimento.REDUTORES
      ) {
        this.$emit("exibe-dialog-redutores-completo");
        return;
      }
      if (await this.$root.$confirm(AcoesEnum.FINALIZAR_ETAPA)) {
        if (
          this.store.getEtapaPreenchimento === EtapasDoPreenchimento.CONSUMO
        ) {
          this.store.iniciaEtapaRedutores();
          this.$router.push({ name: "RedutoresInfo" });
        }
      }
    },
  },
});
