
import { StatusEtapasEnum } from "@/utils/constants";
import Vue from "vue";
import TabsUsuarios from "../TabsUsuarios.vue";
import FormLayoutUsuario from "../FormLayoutUsuario.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import { getConsumoLavarMaoMes, segToMin, minToSeg } from "@/utils/functions";
import { useStore } from "@/store";
import { ValoresLavagemMaos } from "@/utils/dto";
import { some } from "lodash";

export default Vue.extend({
  name: "SubFormLavagemMaos",
  components: {
    TabsUsuarios,
    FormLayoutUsuario,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    pessoas: [],
  }),
  mounted() {
    this.montaPessoas();
  },
  computed: {
    pessoaSelecionada() {
      return this.pessoas.find((p) => p.selecionada) || null;
    },
    pessoasStore() {
      return this.store.getPessoas;
    },
    todosCompletos() {
      return !some(this.pessoas, (p) => p.status !== StatusEtapasEnum.COMPLETA);
    },
  },
  methods: {
    montaPessoas() {
      // esvazia array
      this.pessoas = [];
      // flag para indicar quando o "proximo" foi encontrado (pessoa q deve estar selecionada)
      let selecionadoEncontrado = false;
      // popula array com dados da store
      this.pessoasStore.forEach((ps) => {
        this.pessoas.push({
          ...ps,
          status: ps.dadosBanheiro.lavagemMaos.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
          selecionada:
            !ps.dadosBanheiro.lavagemMaos.feito && !selecionadoEncontrado,
          etapas: [
            {
              status: ps.dadosBanheiro.lavagemMaos.feito
                ? StatusEtapasEnum.COMPLETA
                : StatusEtapasEnum.FAZENDO,
            },
            {
              status: ps.dadosBanheiro.lavagemMaos.feito
                ? StatusEtapasEnum.COMPLETA
                : StatusEtapasEnum.INCOMPLETA,
            },
          ],
          form: {
            lavagemDia: ps.dadosBanheiro.lavagemMaos.lavagemDia,
            lavagemSeg: minToSeg(
              Number(ps.dadosBanheiro.lavagemMaos.lavagemMin)
            ),
          },
        });
        selecionadoEncontrado = !ps.dadosBanheiro.lavagemMaos.feito;
      });
    },
    selecionaPessoa(pessoa) {
      let indexNovaPessoa;
      this.pessoas.forEach((p, index) => {
        this.$set(this.pessoas[index], "selecionada", false);
        if (pessoa.id === p.id) {
          indexNovaPessoa = index;
        }
      });
      // Delay para animação
      setTimeout(() => {
        this.$set(this.pessoas[indexNovaPessoa], "selecionada", true);
      }, 200);
    },
    totalLavagemMaos() {
      if (!this.pessoaSelecionada) {
        return 0;
      }
      return getConsumoLavarMaoMes(
        [
          {
            lavagemDia: this.pessoaSelecionada.form.lavagemDia,
            lavagemMin: segToMin(
              Number(this.pessoaSelecionada.form.lavagemSeg)
            ),
          },
        ],
        this.store.getHabitacao
      );
    },
    selecionaProxima() {
      const id = this.pessoaSelecionada.id;
      const novaPessoa = this.pessoas.find((p) => p.id === id + 1);
      this.pessoaSelecionada.selecionada = false;
      if (novaPessoa) {
        setTimeout(() => {
          novaPessoa.selecionada = true;
        }, 200);
      }
    },
    voltar() {
      // TODO resetar valores
      this.pessoaSelecionada.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.pessoaSelecionada.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
      this.pessoaSelecionada.status = StatusEtapasEnum.FAZENDO;
    },
    finalizaLavagem() {
      this.store.setConsumoLavagem(this.pessoaSelecionada.id, {
        lavagemDia: this.pessoaSelecionada.form.lavagemDia,
        lavagemMin: segToMin(Number(this.pessoaSelecionada.form.lavagemSeg)),
      } as ValoresLavagemMaos);
      this.pessoaSelecionada.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.pessoaSelecionada.etapas[1].status = StatusEtapasEnum.FAZENDO;
      this.pessoaSelecionada.status = StatusEtapasEnum.COMPLETA;
      // this.selecionaProxima();
      this.$root.$emit("toast-success");
    },
  },
  watch: {
    selecionado(newValue) {
      if (newValue && !this.pessoaSelecionada) {
        this.selecionaPessoa(this.pessoas[0]);
      }
    },
  },
});
