
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import {
  segToMin,
  minToSeg,
  getConsumoLavagemPisoMes,
} from "@/utils/functions";
import { useStore } from "@/store";

export default Vue.extend({
  name: "SubFormLavarPisos",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      lavagemPisoSemana: 0,
      lavagemPisoSegundos: 0,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.lavagemPiso.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.lavagemPiso.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.lavagemPisoSemana =
      this.store.getConsumoHabitacao.lavagemPiso.vezesPorSemana;
    this.form.lavagemPisoSegundos = minToSeg(
      Number(this.store.getConsumoHabitacao.lavagemPiso.minutosPorUso)
    );
  },
  computed: {
    lavagemPisoCompleta() {
      return this.store.getConsumoHabitacao.lavagemPiso.feito;
    },
    totalLavagemPiso() {
      return getConsumoLavagemPisoMes(
        this.form.lavagemPisoSemana,
        segToMin(Number(this.form.lavagemPisoSegundos)),
        this.store.getHabitacao
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaPiso() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setConsumoLavagemPiso(
        this.form.lavagemPisoSemana,
        segToMin(Number(this.form.lavagemPisoSegundos))
      );
      this.$root.$emit("toast-success");
    },
  },
});
