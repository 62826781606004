export enum AcoesEnum {
  VOLTAR_ETAPA = "Voltar etapa",
  FINALIZAR_ETAPA = "Finalizar etapa",
  RECOMECAR = "Recomeçar",
}

export enum EtapasDoPreenchimento {
  CONSUMO = "Consumo",
  REDUTORES = "Redutores",
  AGUACHUVA = "Coleta de água da chuva",
  FIM = "Fim",
}

export enum UsosSubstituiveisEnum {
  DESCARGA = "Descarga",
  REGA = "Rega de jardim",
  CARRO = "Lavagem de carro",
  PATIO = "Lavagem de pátio",
  CALCADA = "Lavagem de calçada",
}

export enum HabitacoesEnum {
  CASA = "Casa",
  APARTAMENTO = "Apartamento",
}

export enum TiposUsoEnum {
  INDIVIDUAL = "Individual",
  COLETIVO = "Coletivo",
}

export enum ComodosEnum {
  BANHEIRO = "Banheiro",
  COZINHA = "Cozinha",
  LAVANDERIA = "Lavanderia",
  AREA_EXTERNA = "Área Externa",
}

// AREA EXTERNA
export enum FormsAreaExternaEnum {
  REGA = "Rega de jardim",
  CARRO = "Lavagem de carro",
  MANGUEIRA = "Lavagem de pátio e calçada",
  PISCINA = "Piscina",
  CHUVEIRO = "Banho na área externa",
}

// LAVANDERIA/AREA DE SERVIÇO
export enum FormsLavanderiaEnum {
  MAQUINA_ROUPA = "Máquina de lavar roupa",
  TORNEIRA = "Torneira do tanque",
}

export enum TipoMaquinaRoupaEnum {
  SUPERIOR = "Abertura superior",
  FRONTAL = "Abertura frontal",
}

// COZINHA
export enum FormsCozinhaEnum {
  MAQUINA_LOUCA = "Máquina de lavar louça",
  TORNEIRA = "Torneira da pia",
}

export enum UsosCozinhaEnum {
  MAQUINA_LOUCA = "Máquina de lavar louça",
  LAVAGEM_LOUCA = "Lavagem manual de louça",
  LAVAGEM_ALIMENTOS = "Lavagem de alimentos",
}

// BANHEIRO
export enum FormsBanheiroEnum {
  BANHEIRA = "Banheira",
  BIDE = "Bidê",
  CHUVEIRO = "Chuveiro",
  DESCARGA = "Descarga",
  TORNEIRA = "Torneira do lavatório",
}

export enum TipoChuveiroEnum {
  CHUVEIRO_ELETRICO = "Chuveiro elétrico",
  DUCHA = "Ducha",
}

export enum TiposDescargaEnum {
  VALVULA = "Válvula",
  CAIXA_SIMPLES = "Caixa acionamento simples",
  CAIXA_DUPLA = "Caixa acionamento duplo",
}

export enum UsosTorneiraEnum {
  BARBA = "Barba",
  ESCOVACAO = "Escovação dos dentes",
  LAVAGEM_MAOS = "Lavagem das mãos",
}

export enum UsosBanheiroEnum {
  BARBEAMENTO = "barbeamento",
  ESCOVACAO = "escovacaoDentes",
  LAVAGEM_MAOS = "lavagemMaos",
  BANHEIRA = "banheira",
  BIDE = "bide",
  CHUVEIRO = "chuveiro",
  DESCARGA = "descarga",
}

export enum StatusEtapasEnum {
  COMPLETA = "COMPLETA",
  INCOMPLETA = "INCOMPLETA",
  FAZENDO = "FAZENDO",
}

export const MESES_ANO = 12;
export const DIAS_MES = 30;
export const DIAS_SEMANA = 7;
export enum ValoresReferenciaEnum {
  BAIXO = 110,
  MEDIO = 130,
  ALTO = 154,
  MUITO_ALTO = 178,
}

export enum SimOuNaoEnum {
  SIM = "SIM",
  NAO = "NAO",
}

export const coresNormal = [
  "amber",
  "orange",
  "deep-orange",
  "red",
  "pink",
  "purple",
  "deep-purple",
  "indigo",
  "blue",
  "light-blue",
  "cyan",
  "teal",
  "green",
  "light-green",
  "lime",
  "yellow",
  "brown",
  "blue-grey",
];

export const cores = [
  // "blue-grey", //1
  // "brown",
  // "yellow",
  // "lime",
  // "light-green",
  // "green",
  // "teal",
  "cyan",
  // "light-blue",
  "blue",
  "indigo",
  "deep-purple",
  "purple",
  "pink",
  // "red",
  "deep-orange",
  "orange",
  "amber",
];

export const VALORES_BANHEIRO = {
  Casa: {
    litrosPorMinDucha: 12, // QBD
    litrosPorMinChuveiroEletrico: 6, // QBC
    litrosPorUsoBanheira: 150, // QBB
    litrosPorUsoDescargaCompleta: 6.8, // QBS
    litrosPorUsoDescargaValvula: 10, // QBS
    litrosPorUsoDescargaCurta: 3, // QBM
    litrosPorMinTorneiraLavatorio: 9, // QTL
    litrosPorMinBide: 6, // QHI
  },
  Apartamento: {
    litrosPorMinDucha: 20, // QBD
    litrosPorMinChuveiroEletrico: 12, // QBC
    litrosPorUsoBanheira: 150, // QBB
    litrosPorUsoDescargaCompleta: 6.8, // QBS
    litrosPorUsoDescargaValvula: 10, // QBS
    litrosPorUsoDescargaCurta: 3, // QBM
    litrosPorMinTorneiraLavatorio: 15, // QTL
    litrosPorMinBide: 12, // QHI
  },
};

export const VALORES_COZINHA = {
  Casa: {
    litrosPorMinTorneiraPia: 9, // QTP
    litrosPorCicloLavaLouca: 12, // QLL
  },
  Apartamento: {
    litrosPorMinTorneiraPia: 15, // QTPa
    litrosPorCicloLavaLouca: 12, // QLLa
  },
};

export const VALORES_LAVANDERIA = {
  "Abertura superior": {
    litrosPorCicloLavaRoupas: 160, // QRQ
  },
  "Abertura frontal": {
    litrosPorCicloLavaRoupas: 100, // QRQ
  },
  Casa: {
    litrosPorMinTorneiraTanque: 15, // QTA
  },
  Apartamento: {
    litrosPorMinTorneiraTanque: 25, // QTAa
  },
};

export const VALORES_AREA_EXTERNA = {
  litrosPorMinTorneiraExterna: 12, // QTE
  litrosPorMinDuchaExterna: 20, // QDE
  litrosPorMinPressurizador: 5, // QPE
};

// REDUTORES
export enum UsosComRedutoresEnum {
  TORNEIRALAVATORIO = "Torneira do Lavatório",
  DUCHA = "Ducha",
  PRIVADA = "Privada",
}

export enum RedutoresTorneiraLavatorioEnum {
  AREJADOR = "arejador",
  PULVERIZADOR = "pulverizador",
  RESTRITORVAZAO = "restritorVazao",
}

export enum RedutoresTorneiraPiaEnum {
  PULVERIZADOR = "pulverizador",
  RESTRITORVAZAO = "restritorVazao",
}

export enum RedutoresTanqueEnum {
  PULVERIZADOR = "pulverizador",
  RESTRITORVAZAO = "restritorVazao",
}

export enum RedutoresPrivadaEnum {
  CAIXA_DUPLA = "caixaDupla",
}

export enum RedutoresDuchaEnum {
  RESTRITORVAZAO = "restritorVazao",
}

export enum RedutoresTorneiraExternaEnum {
  LAVADORAPRESSURIZADA = "lavadoraPressurizada",
}

export enum RedutoresTodosEnum {
  AREJADOR = "arejador",
  PULVERIZADOR = "pulverizador",
  RESTRITORVAZAO = "restritorVazao",
  CAIXA_DUPLA = "caixaDupla",
  LAVADORAPRESSURIZADA = "lavadoraPressurizada",
}

export const RedutoresNomes = {
  restritorVazao: "Restritor de vazão",
  caixaDupla: "Caixa dupla",
  arejador: "Arejador",
  pulverizador: "Pulverizador",
  lavadoraPressurizada: "Lavadora pressurizada",
};

export const VALORES_REDUTORES = {
  Casa: {
    // QTL
    torneiraLavatorio: {
      arejador: 6,
      pulverizador: 3,
      restritorVazao: 4,
    },
    // QBD
    ducha: {
      restritorVazao: 9,
    },
    privada: {
      curta: 3,
      completa: 6.8,
    },
    // QTP
    torneiraPia: {
      pulverizador: 4,
      restritorVazao: 6,
    },
    // QTA
    torneiraTanque: {
      pulverizador: 5,
      restritorVazao: 9,
    },
    // QTE
    torneiraExterna: {
      lavadoraPressurizada: 5,
    },
    duchaExterna: {
      restritorVazao: 9,
    },
  },
  Apartamento: {
    // QTL
    torneiraLavatorio: {
      arejador: 11,
      pulverizador: 3,
      restritorVazao: 6,
    },
    // QBD
    ducha: {
      restritorVazao: 15,
    },
    privada: {
      curta: 3,
      completa: 6.8,
    },
    // QTP
    torneiraPia: {
      // arejador: 4,
      pulverizador: 4,
      restritorVazao: 8,
    },
    // QTA
    torneiraTanque: {
      // arejador: 5,
      pulverizador: 5,
      restritorVazao: 15,
    },
  },
};

export const TAMANHOS_RESERVATORIOS = {
  superior: [100, 150, 250, 300, 500, 1000],
  inferior: [500, 1000, 1500, 2000, 2500, 3000, 5000, 10000],
};
