
import Vue from "vue";
import { useStore } from "@/store";
import { RedutoresDuchaEnum, RedutoresNomes } from "../../utils/constants";
import Chuveiro from "../../assets/areaExterna/chuveiro.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";

export default Vue.extend({
  name: "RedutoresListDuchaExterna",
  components: {
    Chuveiro,
    RestritorVazao,
    BarrasAntesDepoisRedutor,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresDuchaEnum,
      redutorSelecionado: null,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
    };
  },
  mounted() {
    this.redutorSelecionado = this.store.getConfigRedutores.duchaExterna;
  },
  computed: {
    antesRedutor(): number {
      return this.store.getConsumoDuchaExternaMes();
    },
    depoisRedutor(): number {
      return this.store.getConsumoDuchaExternaMes(this.redutorSelecionado);
    },
  },
  methods: {
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
  },
  watch: {
    redutorSelecionado() {
      this.store.setRedutorDuchaExterna(this.redutorSelecionado || null);
    },
  },
});
