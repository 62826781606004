var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.possuiMaquinaLouca)?_c('CardUsoRelatorio',{attrs:{"titleUso":"Lava-louça","numPessoas":_vm.pessoasStore.length,"consumo":_vm.maquinaLouca,"consumoComRedutores":_vm.maquinaLouca},scopedSlots:_vm._u([{key:"usoImg",fn:function(){return [_c('MaquinaLouca',{attrs:{"width":"25px"}})]},proxy:true},{key:"consumo",fn:function(){return [_c('v-card-text',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"accent"}},[_vm._v(" mdi-home-circle ")]),_c('b',[_vm._v("Habitação")]),_c('ul',[_c('li',[_vm._v(" Usos da lava-louças: "),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.store.consumoHabitacao.maquinaLouca.vezesPorSemana || 0)+" por semana ")])])])])],1)]},proxy:true}],null,false,2175943782)}):_vm._e(),_c('CardUsoRelatorio',{attrs:{"titleUso":"Pia da cozinha","numPessoas":_vm.pessoasStore.length,"consumo":_vm.piaCozinha,"consumoComRedutores":_vm.piaCozinhaRedutor,"temRedutor":_vm.store.configRedutores.piaCozinha !== null,"nomeRedutor":_vm.nomeRedutorPia},scopedSlots:_vm._u([{key:"usoImg",fn:function(){return [_c('Pia',{attrs:{"width":"27px"}})]},proxy:true},(_vm.store.configRedutores.piaCozinha !== null)?{key:"imgRedutor",fn:function(){return [(
          _vm.store.configRedutores.piaCozinha ===
          _vm.RedutoresTorneiraPiaEnum.RESTRITORVAZAO
        )?_c('RestritorVazao',{attrs:{"width":"70px"}}):_vm._e(),(
          _vm.store.configRedutores.piaCozinha ===
          _vm.RedutoresTorneiraPiaEnum.PULVERIZADOR
        )?_c('Pulverizador',{attrs:{"width":"70px"}}):_vm._e()]},proxy:true}:null,{key:"consumo",fn:function(){return [_c('v-card-text',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"accent"}},[_vm._v(" mdi-home-circle ")]),_c('b',[_vm._v("Habitação")]),_c('ul',[_c('li',[_vm._v(" Lavagem de louça: "),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.store.consumoHabitacao.lavagemLouca.vezesPorSemana || 0)+" por semana ")]),_c('li',[_vm._v(" "+_vm._s(_vm.minToMinAndSeg( _vm.store.consumoHabitacao.lavagemLouca.minutosPorUso ))+" cada ")])])]),_c('li',[_vm._v(" Lavagem de alimentos: "),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.store.consumoHabitacao.lavagemAlimentos.vezesPorSemana || 0)+" por semana ")]),_c('li',[_vm._v(" "+_vm._s(_vm.minToMinAndSeg( _vm.store.consumoHabitacao.lavagemAlimentos.minutosPorUso ))+" cada ")])])])])],1)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }