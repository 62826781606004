
import Vue from "vue";

export default Vue.extend({
  name: "TextSliderInputs",
  props: {
    value: {
      type: () => String || Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: "/ semana",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hintText() {
      switch (this.suffix) {
        case "seg":
          return this.hintTextSeg;
        default:
          return this.hintTextGenerico;
      }
    },
    hintTextSeg() {
      const min = Math.floor(this.value / 60);
      const seg = this.value % 60;
      const minStr = `${min} min`;
      const segStr = seg > 0 ? ` e ${seg} seg` : "";
      return `${minStr}${segStr}`;
    },
    hintTextGenerico() {
      return `${this.value} ${this.suffix}`;
    },
  },
});
