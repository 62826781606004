
import Vue from "vue";
import {
  DIAS_MES,
  MESES_ANO,
  UsosBanheiroEnum,
  ValoresReferenciaEnum,
} from "../utils/constants";
import { formataNumero } from "../utils/functions";
import { sum } from "lodash";
import { useStore } from "@/store";

export default Vue.extend({
  name: "ProgressTotalPerCapita",
  props: {
    calculoComRedutores: {
      type: Boolean,
      default: false,
    },
    largeText: {
      type: Boolean,
      default: false,
    },
    relatorioFinal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    formataNumero,
  }),
  mounted() {
    if (this.relatorioFinal) {
      this.$emit("litros-per-capta", this.totalPerCapita);
      this.$emit("litros-por-ano", this.totalMensal * MESES_ANO);
    }
  },
  computed: {
    // CONFIG HABITACAO
    possuiBanheira() {
      return this.store.getConfigHabitacao.temBanheira;
    },
    possuiBide() {
      return this.store.getConfigHabitacao.temBide;
    },
    // CONSUMO BANHEIRO
    banheira() {
      return parseFloat(
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.BANHEIRA,
          this.calculoComRedutores
        )
      );
    },
    descarga() {
      return parseFloat(
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.DESCARGA,
          this.calculoComRedutores
        )
      );
    },
    chuveiro() {
      return parseFloat(
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.CHUVEIRO,
          this.calculoComRedutores
        )
      );
    },
    bide() {
      return parseFloat(
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.BIDE,
          this.calculoComRedutores
        )
      );
    },
    piaLavatorio() {
      const sumVal = sum([
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.BARBEAMENTO,
          this.calculoComRedutores
        ),
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.LAVAGEM_MAOS,
          this.calculoComRedutores
        ),
        this.store.getConsumoUsoBanheiroMes(
          UsosBanheiroEnum.ESCOVACAO,
          this.calculoComRedutores
        ),
      ]);
      return parseFloat(`${sumVal}`);
    },
    // CONSUMO COZINHA
    maquinaLouca() {
      return parseFloat(this.store.getConsumoMaquinaLoucaMes);
    },
    piaCozinha() {
      return (
        parseFloat(
          this.store.getConsumoLavagemLoucaMes(this.calculoComRedutores)
        ) +
        parseFloat(
          this.store.getConsumoLavagemAlimentosMes(this.calculoComRedutores)
        )
      );
    },
    // CONSUMO LAVANDERIA
    maquinaRoupa() {
      return parseFloat(this.store.getConsumoMaquinaRoupaMes);
    },
    lavagemRoupaMao() {
      return parseFloat(
        this.store.getConsumoLavagemRoupasMaoMes(this.calculoComRedutores)
      );
    },
    lavagemPisos() {
      return parseFloat(
        this.store.getConsumoLavagemPisoMes(this.calculoComRedutores)
      );
    },
    // CONSUMO AREA EXTERNA
    piscina() {
      return parseFloat(this.store.getConsumoPiscinaMes);
    },
    duchaExterna() {
      return parseFloat(
        this.store.getConsumoDuchaExternaMes(this.calculoComRedutores)
      );
    },
    mangueira() {
      return parseFloat(
        this.store.getConsumoLavagemPatioMes(this.calculoComRedutores) +
          this.store.getConsumoLavagemCalcadaMes(this.calculoComRedutores)
      );
    },
    carro() {
      return parseFloat(
        this.store.getConsumoLavagemCarroMes(this.calculoComRedutores)
      );
    },
    regaJardim() {
      return parseFloat(this.store.getConsumoRegaJardimMes);
    },
    // DADOS BARRA
    totalMensal() {
      const consumoMensal: number =
        // banheiro
        (this.possuiBanheira ? this.banheira : 0) +
        this.piaLavatorio +
        this.descarga +
        this.chuveiro +
        (this.possuiBide ? this.bide : 0) +
        // cozinha
        this.maquinaLouca +
        this.piaCozinha +
        // area externa
        this.piscina +
        this.duchaExterna +
        this.mangueira +
        this.carro +
        this.regaJardim +
        // lavanderia
        this.maquinaRoupa +
        this.lavagemRoupaMao +
        this.lavagemPisos;
      return consumoMensal;
    },
    totalPerCapita() {
      return this.totalMensal / (DIAS_MES * this.store.getPessoas.length);
    },
    percentualBarra() {
      return (this.totalPerCapita / ValoresReferenciaEnum.MUITO_ALTO) * 100;
    },
    corBarra() {
      if (this.totalPerCapita <= ValoresReferenciaEnum.BAIXO)
        return "light-blue";
      else if (this.totalPerCapita <= ValoresReferenciaEnum.MEDIO)
        return "primary";
      else if (this.totalPerCapita <= ValoresReferenciaEnum.ALTO)
        return "deep-purple";
      else if (this.totalPerCapita <= ValoresReferenciaEnum.MUITO_ALTO)
        return "orange";
      else return "red darken-4";
    },
  },
});
