
import { useStore } from "@/store";
import { AcoesEnum, HabitacoesEnum } from "@/utils/constants";
import Vue from "vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import ExpansionResultadosComodos from "../components/ExpansionResultadosComodos.vue";
import StepperResultadosComodos from "../components/StepperResultadosComodos.vue";

export default Vue.extend({
  name: "DialogRedutoresFinalizados",
  components: {
    ProgressTotalPerCapita,
    ExpansionResultadosComodos,
    StepperResultadosComodos,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
  }),
  computed: {
    todosComodosDone() {
      return this.store.todosComodosDone;
    },
    isHabitacaoCasa() {
      return this.store.getHabitacao === HabitacoesEnum.CASA;
    },
  },
  methods: {
    async finalizaEtapaRedutores() {
      if (await this.$root.$confirm(AcoesEnum.FINALIZAR_ETAPA)) {
        this.store.finalizaEtapaRedutores();
        this.$emit("input", false);
        if (this.store.getHabitacao === HabitacoesEnum.CASA) {
          this.$router.push({ name: "AguaChuva" });
        } else {
          this.$router.push({ name: "FimPage" });
        }
      }
    },
  },
});
