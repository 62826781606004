
import Vue from "vue";
import { formataNumero } from "../utils/functions";

export default Vue.extend({
  name: "ConsumoField",
  props: {
    suffix: {
      type: String,
      default: "L/mês",
    },
    consumo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formataNumero,
    };
  },
});
