
import Vue from "vue";

export default Vue.extend({
  name: "StepperButtons",
  props: {
    okDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
