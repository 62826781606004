var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center",staticStyle:{"position":"relative"}},[_c('CozinhaBase',{style:(`filter: grayscale(${_vm.baseDim.grayscale}%); position: absolute; height: ${_vm.tamanhoBase}`),attrs:{"id":"CozinhaBase"}}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(_vm.etapaRedutores)?_c('v-icon',{class:`${hover ? '' : _vm.animationClassIcons} baseIcones`,style:(`
      top: ${_vm.pia.iconTop}px;
      left: ${_vm.pia.iconLeft}px;`),attrs:{"size":_vm.redutoresIconSize,"color":_vm.configRedutores.piaCozinha
          ? _vm.redutoresIconColorAfter
          : _vm.redutoresIconColorBefore},on:{"click":function($event){return _vm.$emit('click-redutores', _vm.FormsCozinhaEnum.TORNEIRA)}}},[_vm._v(" "+_vm._s(_vm.configRedutores.piaCozinha ? _vm.redutoresIconAfter : _vm.redutoresIconBefore)+" ")]):_vm._e()]}}])}),(_vm.baseRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pia.visivel),expression:"pia.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.pia.top}px;
      left: ${_vm.pia.left}px;
      height: auto;
      z-index: 1;`)},[_c('Pia',{class:_vm.pia.class,attrs:{"width":`${_vm.pia.width}px`}})],1):_vm._e(),(_vm.baseRendered)?_c('PiaAgua',{directives:[{name:"show",rawName:"v-show",value:(_vm.piaAgua.visivel),expression:"piaAgua.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.piaAgua.top}px;
      left: ${_vm.piaAgua.left}px;
      filter: grayscale(${_vm.piaAgua.grayscale}%) opacity(${_vm.opacidadeAgua}%);
      height: auto;
      z-index: 1;`),attrs:{"width":`${_vm.piaAgua.width}px`}}):_vm._e(),(_vm.baseRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maquinaLouca.visivel),expression:"maquinaLouca.visivel"}],class:_vm.animationClass,style:(`
      position: absolute;
      top: ${_vm.maquinaLouca.top}px;
      left: ${_vm.maquinaLouca.left}px;
      height: auto;
      z-index: 1;`)},[_c('MaquinaLouca',{class:_vm.maquinaLouca.class,attrs:{"width":`${_vm.maquinaLouca.width}px`,"fill-opacity":_vm.maquinaLouca.opacity}})],1):_vm._e(),(_vm.baseRendered)?_c('Geladeira',{directives:[{name:"show",rawName:"v-show",value:(_vm.geladeira.visivel),expression:"geladeira.visivel"}],style:(`
      position: absolute;
      top: ${_vm.geladeira.top}px;
      left: ${_vm.geladeira.left}px;
      filter: grayscale(${_vm.geladeira.grayscale}%);
      height: auto;
      z-index: 1;`),attrs:{"width":`${_vm.geladeira.width}px`}}):_vm._e(),_c('img',{style:(`width: 100%; z-index: 2; position: absolute;top: 0;left: 0;`),attrs:{"src":require("../../assets/cozinha/cozinha-vazia.png"),"usemap":"#image-map"}}),_c('map',{attrs:{"name":"image-map"}},[_c('area',{style:(_vm.etapaRedutores ? '' : 'cursor: pointer'),attrs:{"title":"maquinaLouca","coords":_vm.mapeiaCoords('maquinaLouca'),"shape":"poly"},on:{"click":function($event){return _vm.$emit('click-cozinha', _vm.FormsCozinhaEnum.MAQUINA_LOUCA)},"mouseenter":function($event){_vm.hoverImg = 'maquinaLouca'},"mouseleave":function($event){_vm.hoverImg = null}}}),_c('area',{style:(_vm.etapaRedutores ? '' : 'cursor: pointer'),attrs:{"title":"pia","coords":_vm.mapeiaCoords('pia'),"shape":"poly"},on:{"click":function($event){return _vm.$emit('click-cozinha', _vm.FormsCozinhaEnum.TORNEIRA)},"mouseenter":function($event){_vm.hoverImg = 'pia'},"mouseleave":function($event){_vm.hoverImg = null}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }