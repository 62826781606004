
import Vue from "vue";
import CardUsoRelatorio from "./CardUsoRelatorio.vue";
import MaquinaSuperior from "../../assets/lavanderia/maquinaSuperior.svg";
import MaquinaFrontal from "../../assets/lavanderia/maquinaFrontal.svg";
import Torneira from "../../assets/lavanderia/torneira.svg";
// redutores
import Pulverizador from "../../assets/redutores/pulverizador.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import {
  RedutoresNomes,
  RedutoresTanqueEnum,
  TipoMaquinaRoupaEnum,
} from "@/utils/constants";
import { useStore } from "@/store";
import { minToMinAndSeg } from "@/utils/functions";

export default Vue.extend({
  name: "ExpansionContentRelatorioLavanderia",
  components: {
    CardUsoRelatorio,
    MaquinaSuperior,
    MaquinaFrontal,
    Torneira,
    // redutores
    Pulverizador,
    RestritorVazao,
  },
  data() {
    return {
      store: useStore(),
      RedutoresNomes,
      minToMinAndSeg,
      RedutoresTanqueEnum,
      TipoMaquinaRoupaEnum,
    };
  },
  computed: {
    pessoasStore() {
      return this.store.getPessoas;
    },
    nomeRedutorTanque() {
      switch (this.store.configRedutores.tanque) {
        case RedutoresTanqueEnum.RESTRITORVAZAO:
          return RedutoresNomes.restritorVazao;
        case RedutoresTanqueEnum.PULVERIZADOR:
          return RedutoresNomes.pulverizador;
        default:
          return "";
      }
    },
    possuiMaquinaRoupa() {
      return this.store.getConfigHabitacao.temMaquinaRoupa;
    },
    tipoMaquinaRoupa() {
      return this.store.getConfigHabitacao.tipoMaquinaRoupa;
    },
    maquinaRoupa() {
      return parseFloat(this.store.getConsumoMaquinaRoupaMes);
    },
    tanque() {
      return (
        parseFloat(this.store.getConsumoLavagemRoupasMaoMes()) +
        parseFloat(this.store.getConsumoLavagemPisoMes())
      );
    },
    tanqueRedutor() {
      return (
        parseFloat(this.store.getConsumoLavagemRoupasMaoMes(true)) +
        parseFloat(this.store.getConsumoLavagemPisoMes(true))
      );
    },
  },
});
