
import Vue from "vue";
import { useStore } from "@/store";
import {
  AcoesEnum,
  DIAS_MES,
  UsosBanheiroEnum,
  UsosSubstituiveisEnum,
} from "@/utils/constants";
import { map, sum } from "lodash";
import CustomText from "../../components/CustomText.vue";

const coefAprov = 0.75;

export default Vue.extend({
  name: "CardResultados",
  components: {
    CustomText,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    store: useStore(),
    parseCsv: null,
    dadosBrutos: null,
    dadosProcessados: [],
    reservaSeguranca: 0,
    dadosMensais: {
      jan: { demanda: 0, volPot: 0 },
      fev: { demanda: 0, volPot: 0 },
      mar: { demanda: 0, volPot: 0 },
      abr: { demanda: 0, volPot: 0 },
      mai: { demanda: 0, volPot: 0 },
      jun: { demanda: 0, volPot: 0 },
      jul: { demanda: 0, volPot: 0 },
      ago: { demanda: 0, volPot: 0 },
      set: { demanda: 0, volPot: 0 },
      out: { demanda: 0, volPot: 0 },
      nov: { demanda: 0, volPot: 0 },
      dez: { demanda: 0, volPot: 0 },
    },
  }),
  computed: {
    usosSelecionados() {
      return this.store.getConfigCaptacaoAgua.usosSelecionados;
    },
    dadosInfraColetaChuva() {
      return {
        reservatorioInferior:
          this.store.getConfigCaptacaoAgua.reservatorioInferior,
        reservatorioSuperior:
          this.store.getConfigCaptacaoAgua.reservatorioSuperior,
        areaCaptacao: this.store.getConfigCaptacaoAgua.areaCaptacao,
        localizacao: this.store.getConfigCaptacaoAgua.localizacao,
      };
    },
    areaCap() {
      return this.store.getConfigCaptacaoAgua.areaCaptacao;
    },
    // descarga
    consumoDiarioDescargaLitros() {
      const usoMensal = this.store.getConsumoUsoBanheiroMes(
        UsosBanheiroEnum.DESCARGA,
        true
      );
      return usoMensal / DIAS_MES;
    },
    // rega jardim
    consumoDiarioRegaJardimLitros() {
      const usoMensal = this.store.getConsumoRegaJardimMes;
      return usoMensal / DIAS_MES;
    },
    // lavagem carro
    consumoDiarioLavagemCarroLitros() {
      const usoMensal = this.store.getConsumoLavagemCarroMes(true);
      return usoMensal / DIAS_MES;
    },
    // lavagem patio
    consumoDiarioLavagemPatioLitros() {
      const usoMensal = this.store.getConsumoLavagemPatioMes(true);
      return usoMensal / DIAS_MES;
    },
    // lavagem calçada
    consumoDiarioLavagemCalcadaLitros() {
      const usoMensal = this.store.getConsumoLavagemCalcadaMes(true);
      return usoMensal / DIAS_MES;
    },
    consumoDiarioUsosSelecionados() {
      let sum = 0;
      if (this.usosSelecionados.includes(UsosSubstituiveisEnum.DESCARGA)) {
        sum += this.consumoDiarioDescargaLitros;
      }
      if (this.usosSelecionados.includes(UsosSubstituiveisEnum.REGA)) {
        sum += this.consumoDiarioRegaJardimLitros;
      }
      if (this.usosSelecionados.includes(UsosSubstituiveisEnum.CARRO)) {
        sum += this.consumoDiarioLavagemCarroLitros;
      }
      if (this.usosSelecionados.includes(UsosSubstituiveisEnum.PATIO)) {
        sum += this.consumoDiarioLavagemPatioLitros;
      }
      if (this.usosSelecionados.includes(UsosSubstituiveisEnum.CALCADA)) {
        sum += this.consumoDiarioLavagemCalcadaLitros;
      }
      return sum;
    },
    demandaTotalAnual() {
      return sum(map(this.dadosMensais, (el) => el.demanda));
    },
    usoTotalAguaPotavel() {
      return sum(map(this.dadosMensais, (el) => el.volPot));
    },
  },
  async mounted() {
    await this.carregaDadaosChuva();
    this.reservaSeguranca =
      this.dadosInfraColetaChuva.reservatorioSuperior < 300 ? 50 : 100;
    this.calculaDados();
  },
  methods: {
    async carregaDadaosChuva() {
      if (this.store.getConfigCaptacaoAgua.arquivoDadosChuva) {
        const arq = this.store.getConfigCaptacaoAgua.arquivoDadosChuva.replace(
          ".js",
          ""
        );
        try {
          const modulo = await import(`../../assets/modulosEstacoes/${arq}.js`);
          this.dadosBrutos = modulo.dadosBrutos;
        } catch (err) {
          this.$root.$emit("toast-error", err);
        }
      }
    },
    calculaDados() {
      for (let index = 0; index < this.dadosBrutos.length; index++) {
        const el = this.dadosBrutos[index];
        const novoEl = {
          ...el,
          ofertaDiaria:
            el.chuvaMedia * this.dadosInfraColetaChuva.areaCaptacao * coefAprov,
          volIniDisp: 0,
          volFim: 0,
          volAguaPot: 0,
        };
        // calcula uso de agua potavel
        const demDia = this.consumoDiarioUsosSelecionados;
        const ofDia = novoEl.ofertaDiaria;
        const volIniDia =
          index === 0 ? 0 : this.dadosProcessados[index - 1].volFim;
        const volResSeg = this.reservaSeguranca;
        let volAguaPot = 0;
        if (volIniDia + ofDia - demDia > volResSeg) {
          volAguaPot = 0;
        } else {
          volAguaPot =
            volResSeg + demDia - (volIniDia + ofDia) > 0
              ? volResSeg + demDia - (volIniDia + ofDia)
              : 0;
        }

        // calculo do volume final do dia
        let volFimDia = 0;
        const resSup = this.dadosInfraColetaChuva.reservatorioSuperior;
        const resInf = this.dadosInfraColetaChuva.reservatorioInferior;
        if (volIniDia + ofDia - demDia > resSup + resInf) {
          volFimDia = resSup + resInf;
        } else if (volIniDia + ofDia - demDia > resInf) {
          volFimDia =
            resSup + resInf - volIniDia + ofDia - demDia > 0
              ? resSup + resInf - volIniDia + ofDia - demDia
              : 0;
        } else if (volIniDia + ofDia - demDia > volResSeg) {
          volFimDia =
            volIniDia + ofDia - demDia > 0 ? volIniDia + ofDia - demDia : 0;
        } else {
          volFimDia = volResSeg;
        }

        // atualiza novo elemento
        novoEl.volAguaPot = volAguaPot;
        novoEl.volFim = volFimDia;

        // joga novo item no array
        this.dadosProcessados.push(novoEl);
      }
      this.calculoMensal();
    },
    calculoMensal() {
      this.dadosProcessados.forEach((el) => {
        let mesKey;
        switch (el.dia.split("/")[0]) {
          case "01":
            mesKey = "jan";
            break;
          case "02":
            mesKey = "fev";
            break;
          case "03":
            mesKey = "mar";
            break;
          case "04":
            mesKey = "abr";
            break;
          case "05":
            mesKey = "mai";
            break;
          case "06":
            mesKey = "jun";
            break;
          case "07":
            mesKey = "jul";
            break;
          case "08":
            mesKey = "ago";
            break;
          case "09":
            mesKey = "set";
            break;
          case "10":
            mesKey = "out";
            break;
          case "11":
            mesKey = "nov";
            break;
          case "12":
            mesKey = "dez";
            break;
          default:
            break;
        }
        this.dadosMensais[mesKey].demanda += this.consumoDiarioUsosSelecionados;
        this.dadosMensais[mesKey].volPot += el.volAguaPot;
      });
    },
    percentualEconomizado(item) {
      if (item.volPot / item.demanda <= 1) {
        return 100 - (item.volPot / item.demanda) * 100;
      }
      return 0;
    },
    async finalizar() {
      if (await this.$root.$confirm(AcoesEnum.FINALIZAR_ETAPA)) {
        this.store.finalizaEtapaChuva();
        this.$router.push({ name: "FimPage" });
      }
    },
  },
});
