
import Vue from "vue";
import { formataNumero } from "@/utils/functions";
import { DIAS_MES, EtapasDoPreenchimento } from "@/utils/constants";
import { useStore } from "@/store";

export default Vue.extend({
  name: "TotalDoComodoCard",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    totalComRedutores: {
      type: Number,
      default: 0,
    },
    numPessoas: {
      type: Number,
      default: 1,
    },
    darkTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      formataNumero,
    };
  },
  computed: {
    showRedutor() {
      return this.store.getEtapaPreenchimento !== EtapasDoPreenchimento.CONSUMO;
    },
    percentualReducao() {
      if (this.total === 0) return 0;
      return (this.totalComRedutores / this.total) * 100;
    },
    totalPorDia() {
      return this.total / DIAS_MES;
    },
    totalPorDiaComRedutores() {
      return this.totalComRedutores / DIAS_MES;
    },
    totalPorPessoa() {
      return this.total / (DIAS_MES * this.numPessoas);
    },
    totalPorPessoaComRedutores() {
      return this.totalComRedutores / (DIAS_MES * this.numPessoas);
    },
  },
});
