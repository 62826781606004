
import { StatusEtapasEnum } from "@/utils/constants";
import Vue from "vue";

export default Vue.extend({
  name: "TabsHabitacao",
  props: {
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    completo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      StatusEtapasEnum,
    };
  },
});
