
import Vue from "vue";
import { HabitacoesEnum } from "../utils/constants";
import CasaIcon from "../assets/icones/iconeCasa.svg";
import PredioIcon from "../assets/icones/iconeApartamento.svg";
import { useStore } from "@/store";

export default Vue.extend({
  name: "HabitacaoPage",
  components: {
    CasaIcon,
    PredioIcon,
  },
  data: () => ({
    HabitacoesEnum,
    habitacao: null,
    store: useStore(),
  }),
  computed: {
    habitacaoStore() {
      return this.store.getHabitacao || null;
    },
    mainDivClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex flex-column align-center"
        : "d-flex mt-9";
    },
    imgHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 100 : 250;
    },
    cardClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex flex-column align-center mt-10"
        : "d-flex flex-column align-center mt-10 mx-8";
    },
    cardTitleClass() {
      return this.$vuetify.breakpoint.smAndDown
        ? "d-flex justify-center"
        : "d-flex justify-center text-h4";
    },
  },
  mounted() {
    this.habitacao = this.habitacaoStore;
  },
  methods: {
    selecionaHabitacao(tipo: string) {
      this.habitacao = tipo;
      this.store.setHabitacao(tipo);
      setTimeout(() => {
        this.$router.push({
          name: "TipoUsoPage",
        });
      }, 300);
    },
  },
});
