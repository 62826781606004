
import Vue from "vue";
import FormJardim from "./FormJardim.vue";
import FormCarro from "./FormCarro.vue";
import FormPiscina from "./FormPiscina.vue";
import FormDuchaExterna from "./FormDuchaExterna.vue";
import FormMangueira from "./FormMangueira.vue";
import { FormsAreaExternaEnum } from "../../utils/constants";

export default Vue.extend({
  name: "DialogAreaExterna",
  components: {
    FormJardim,
    FormCarro,
    FormPiscina,
    FormDuchaExterna,
    FormMangueira,
  },
  props: {
    formSelecionado: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    FormsAreaExternaEnum,
  }),
});
