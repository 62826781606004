
import Vue from "vue";

export default Vue.extend({
  name: "CustomAlert",
  props: {
    texto: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
});
