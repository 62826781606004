export const estrutura = {
  CO: {
    DF: [
      { estacao: "BRASILIA", arquivo: "INMET_CO_DF_A001_BRASILIA.js" },
      {
        estacao: "AGUAS EMENDADAS",
        arquivo: "INMET_CO_DF_A045_AGUAS EMENDADAS.js",
      },
    ],
    GO: [
      { estacao: "GOIANIA", arquivo: "INMET_CO_GO_A002_GOIANIA.js" },
      { estacao: "MORRINHOS", arquivo: "INMET_CO_GO_A003_MORRINHOS.js" },
      { estacao: "SAO SIMAO", arquivo: "INMET_CO_GO_A011_SAO SIMAO.js" },
      { estacao: "LUZIANIA", arquivo: "INMET_CO_GO_A012_LUZIANIA.js" },
      { estacao: "ARAGARCAS", arquivo: "INMET_CO_GO_A013_ARAGARCAS.js" },
      { estacao: "GOIAS", arquivo: "INMET_CO_GO_A014_GOIAS.js" },
      { estacao: "ITAPACI", arquivo: "INMET_CO_GO_A015_ITAPACI.js" },
      { estacao: "JATAI", arquivo: "INMET_CO_GO_A016_JATAI.js" },
      { estacao: "POSSE", arquivo: "INMET_CO_GO_A017_POSSE.js" },
      { estacao: "GOIANESIA", arquivo: "INMET_CO_GO_A022_GOIANESIA.js" },
      { estacao: "CAIAPONIA", arquivo: "INMET_CO_GO_A023_CAIAPONIA.js" },
      {
        estacao: "ALTO PARAISO DE GOIAS",
        arquivo: "INMET_CO_GO_A024_ALTO PARAISO DE GOIAS.js",
      },
      { estacao: "RIO VERDE", arquivo: "INMET_CO_GO_A025_RIO VERDE.js" },
      { estacao: "MINEIROS", arquivo: "INMET_CO_GO_A026_MINEIROS.js" },
      { estacao: "PARAUNA", arquivo: "INMET_CO_GO_A027_PARAUNA.js" },
      {
        estacao: "MONTE ALEGRE DE GOIAS",
        arquivo: "INMET_CO_GO_A032_MONTE ALEGRE DE GOIAS.js",
      },
      { estacao: "PIRES DO RIO", arquivo: "INMET_CO_GO_A033_PIRES DO RIO.js" },
      { estacao: "CATALAO", arquivo: "INMET_CO_GO_A034_CATALAO.js" },
      { estacao: "ITUMBIARA", arquivo: "INMET_CO_GO_A035_ITUMBIARA.js" },
      { estacao: "CRISTALINA", arquivo: "INMET_CO_GO_A036_CRISTALINA.js" },
      { estacao: "SILVANIA", arquivo: "INMET_CO_GO_A037_SILVANIA.js" },
    ],
    MS: [
      { estacao: "CAMPO GRANDE", arquivo: "INMET_CO_MS_A702_CAMPO GRANDE.js" },
      { estacao: "PONTA PORA", arquivo: "INMET_CO_MS_A703_PONTA PORA.js" },
      { estacao: "TRES LAGOAS", arquivo: "INMET_CO_MS_A704_TRES LAGOAS.js" },
      { estacao: "IVINHEMA", arquivo: "INMET_CO_MS_A709_IVINHEMA.js" },
      { estacao: "PARANAIBA", arquivo: "INMET_CO_MS_A710_PARANAIBA.js" },
      { estacao: "NHUMIRIM", arquivo: "INMET_CO_MS_A717_NHUMIRIM.js" },
      { estacao: "AQUIDAUANA", arquivo: "INMET_CO_MS_A719_AQUIDAUANA.js" },
      { estacao: "COXIM", arquivo: "INMET_CO_MS_A720_COXIM.js" },
      { estacao: "DOURADOS", arquivo: "INMET_CO_MS_A721_DOURADOS.js" },
      { estacao: "MIRANDA", arquivo: "INMET_CO_MS_A722_MIRANDA.js" },
      {
        estacao: "PORTO MURTINHO",
        arquivo: "INMET_CO_MS_A723_PORTO MURTINHO.js",
      },
      { estacao: "CORUMBA", arquivo: "INMET_CO_MS_A724_CORUMBA.js" },
      {
        estacao: "CHAPADAO DO SUL",
        arquivo: "INMET_CO_MS_A730_CHAPADAO DO SUL.js",
      },
      { estacao: "MARACAJU", arquivo: "INMET_CO_MS_A731_MARACAJU.js" },
      {
        estacao: "SAO GABRIEL DO OESTE",
        arquivo: "INMET_CO_MS_A732_SAO GABRIEL DO OESTE.js",
      },
      { estacao: "CASSILANDIA", arquivo: "INMET_CO_MS_A742_CASSILANDIA.js" },
      {
        estacao: "RIO BRILHANTE",
        arquivo: "INMET_CO_MS_A743_RIO BRILHANTE.js",
      },
      { estacao: "JUTI", arquivo: "INMET_CO_MS_A749_JUTI.js" },
      { estacao: "AMAMBAI", arquivo: "INMET_CO_MS_A750_AMAMBAI.js" },
      { estacao: "SETE QUEDAS", arquivo: "INMET_CO_MS_A751_SETE QUEDAS.js" },
      { estacao: "ITAQUIRAI", arquivo: "INMET_CO_MS_A752_ITAQUIRAI.js" },
      { estacao: "SIDROLANDIA", arquivo: "INMET_CO_MS_A754_SIDROLANDIA.js" },
      { estacao: "AGUA CLARA", arquivo: "INMET_CO_MS_A756_AGUA CLARA.js" },
      { estacao: "BELA VISTA", arquivo: "INMET_CO_MS_A757_BELA VISTA.js" },
      { estacao: "JARDIM", arquivo: "INMET_CO_MS_A758_JARDIM.js" },
      { estacao: "COSTA RICA", arquivo: "INMET_CO_MS_A760_COSTA RICA.js" },
      { estacao: "SONORA", arquivo: "INMET_CO_MS_A761_SONORA.js" },
    ],
    MT: [
      { estacao: "CUIABA", arquivo: "INMET_CO_MT_A901_CUIABA.js" },
      {
        estacao: "TANGARA DA SERRA",
        arquivo: "INMET_CO_MT_A902_TANGARA DA SERRA.js",
      },
      {
        estacao: "S.J. DO RIO CLARO",
        arquivo: "INMET_CO_MT_A903_S.J. DO RIO CLARO.js",
      },
      { estacao: "SORRISO", arquivo: "INMET_CO_MT_A904_SORRISO.js" },
      {
        estacao: "CAMPO NOVO DOS PARECIS",
        arquivo: "INMET_CO_MT_A905_CAMPO NOVO DOS PARECIS.js",
      },
      {
        estacao: "GUARANTA DO NORTE",
        arquivo: "INMET_CO_MT_A906_GUARANTA DO NORTE.js",
      },
      { estacao: "RONDONOPOLIS", arquivo: "INMET_CO_MT_A907_RONDONOPOLIS.js" },
      { estacao: "AGUA BOA", arquivo: "INMET_CO_MT_A908_AGUA BOA.js" },
      {
        estacao: "ALTO ARAGUAIA",
        arquivo: "INMET_CO_MT_A909_ALTO ARAGUAIA.js",
      },
      { estacao: "APIACAS", arquivo: "INMET_CO_MT_A910_APIACAS.js" },
      { estacao: "CAMPO VERDE", arquivo: "INMET_CO_MT_A912_CAMPO VERDE.js" },
      { estacao: "COMODORO", arquivo: "INMET_CO_MT_A913_COMODORO.js" },
      { estacao: "JUARA", arquivo: "INMET_CO_MT_A914_JUARA.js" },
      { estacao: "PARANATINGA", arquivo: "INMET_CO_MT_A915_PARANATINGA.js" },
      { estacao: "SINOP", arquivo: "INMET_CO_MT_A917_SINOP.js" },
      { estacao: "COTRIGUACU", arquivo: "INMET_CO_MT_A919_COTRIGUACU.js" },
      { estacao: "JUINA", arquivo: "INMET_CO_MT_A920_JUINA.js" },
      {
        estacao: "SAO FELIX  DO ARAGUAIA",
        arquivo: "INMET_CO_MT_A921_SAO FELIX  DO ARAGUAIA.js",
      },
      {
        estacao: "VILA BELA DA SANTISSIMA TRINDADE",
        arquivo: "INMET_CO_MT_A922_VILA BELA DA SANTISSIMA TRINDADE.js",
      },
      {
        estacao: "ALTA FLORESTA",
        arquivo: "INMET_CO_MT_A924_ALTA FLORESTA.js",
      },
      { estacao: "CARLINDA", arquivo: "INMET_CO_MT_A926_CARLINDA.js" },
      { estacao: "NOVA MARINGA", arquivo: "INMET_CO_MT_A928_NOVA MARINGA.js" },
      { estacao: "NOVA UBIRATA", arquivo: "INMET_CO_MT_A929_NOVA UBIRATA.js" },
      {
        estacao: "GAUCHA DO NORTE",
        arquivo: "INMET_CO_MT_A930_GAUCHA DO NORTE.js",
      },
      {
        estacao: "SANTO ANTONIO DO LESTE",
        arquivo: "INMET_CO_MT_A931_SANTO ANTONIO DO LESTE.js",
      },
      { estacao: "GUIRATINGA", arquivo: "INMET_CO_MT_A932_GUIRATINGA.js" },
      { estacao: "ITIQUIRA", arquivo: "INMET_CO_MT_A933_ITIQUIRA.js" },
      { estacao: "ALTO TAQUARI", arquivo: "INMET_CO_MT_A934_ALTO TAQUARI.js" },
      {
        estacao: "PORTO ESTRELA",
        arquivo: "INMET_CO_MT_A935_PORTO ESTRELA.js",
      },
      { estacao: "SALTO DO CEU", arquivo: "INMET_CO_MT_A936_SALTO DO CEU.js" },
      {
        estacao: "PONTES E LACERDA",
        arquivo: "INMET_CO_MT_A937_PONTES E LACERDA.js",
      },
    ],
  },
  NE: {
    AL: [
      { estacao: "MACEIO", arquivo: "INMET_NE_AL_A303_MACEIO.js" },
      {
        estacao: "PAO DE ACUCAR",
        arquivo: "INMET_NE_AL_A323_PAO DE ACUCAR.js",
      },
      {
        estacao: "PALMEIRA DOS INDIOS",
        arquivo: "INMET_NE_AL_A327_PALMEIRA DOS INDIOS.js",
      },
      { estacao: "ARAPIRACA", arquivo: "INMET_NE_AL_A353_ARAPIRACA.js" },
      { estacao: "CORURIPE", arquivo: "INMET_NE_AL_A355_CORURIPE.js" },
      {
        estacao: "SAO LUIS DO QUITUNDE",
        arquivo: "INMET_NE_AL_A356_SAO LUIS DO QUITUNDE.js",
      },
    ],
    BA: [
      { estacao: "SALVADOR", arquivo: "INMET_NE_BA_A401_SALVADOR.js" },
      { estacao: "BARREIRAS", arquivo: "INMET_NE_BA_A402_BARREIRAS.js" },
      {
        estacao: "LUIZ EDUARDO MAGALHAES",
        arquivo: "INMET_NE_BA_A404_LUIZ EDUARDO MAGALHAES.js",
      },
      { estacao: "CARAVELAS", arquivo: "INMET_NE_BA_A405_CARAVELAS.js" },
      {
        estacao: "CRUZ DAS ALMAS",
        arquivo: "INMET_NE_BA_A406_CRUZ DAS ALMAS.js",
      },
      { estacao: "ITIRUCU", arquivo: "INMET_NE_BA_A407_ITIRUCU.js" },
      { estacao: "ITABERABA", arquivo: "INMET_NE_BA_A408_ITABERABA.js" },
      { estacao: "ILHEUS", arquivo: "INMET_NE_BA_A410_ILHEUS.js" },
      { estacao: "MACAJUBA", arquivo: "INMET_NE_BA_A412_MACAJUBA.js" },
      {
        estacao: "FEIRA DE SANTANA",
        arquivo: "INMET_NE_BA_A413_FEIRA DE SANTANA.js",
      },
      {
        estacao: "VITORIA DA CONQUISTA",
        arquivo: "INMET_NE_BA_A414_VITORIA DA CONQUISTA.js",
      },
      {
        estacao: "SANTA RITA DE CASSIA",
        arquivo: "INMET_NE_BA_A415_SANTA RITA DE CASSIA.js",
      },
      { estacao: "CORRENTINA", arquivo: "INMET_NE_BA_A416_CORRENTINA.js" },
      {
        estacao: "BOM JESUS DA LAPA",
        arquivo: "INMET_NE_BA_A418_BOM JESUS DA LAPA.js",
      },
      { estacao: "ABROLHOS", arquivo: "INMET_NE_BA_A422_ABROLHOS.js" },
      { estacao: "REMANSO", arquivo: "INMET_NE_BA_A423_REMANSO.js" },
      { estacao: "IRECE", arquivo: "INMET_NE_BA_A424_IRECE.js" },
      { estacao: "LENCOIS", arquivo: "INMET_NE_BA_A425_LENCOIS.js" },
      { estacao: "GUANAMBI", arquivo: "INMET_NE_BA_A426_GUANAMBI.js" },
      { estacao: "PORTO SEGURO", arquivo: "INMET_NE_BA_A427_PORTO SEGURO.js" },
      {
        estacao: "SENHOR DO BONFIM",
        arquivo: "INMET_NE_BA_A428_SENHOR DO BONFIM.js",
      },
      { estacao: "BARRA", arquivo: "INMET_NE_BA_A429_BARRA.js" },
      { estacao: "PIATA", arquivo: "INMET_NE_BA_A430_PIATA.js" },
      { estacao: "CONDE", arquivo: "INMET_NE_BA_A431_CONDE.js" },
      { estacao: "BURITIRAMA", arquivo: "INMET_NE_BA_A432_BURITIRAMA.js" },
      { estacao: "BRUMADO", arquivo: "INMET_NE_BA_A433_BRUMADO.js" },
      { estacao: "AMARGOSA", arquivo: "INMET_NE_BA_A434_AMARGOSA.js" },
      { estacao: "QUEIMADAS", arquivo: "INMET_NE_BA_A436_QUEIMADAS.js" },
      { estacao: "UNA", arquivo: "INMET_NE_BA_A437_UNA.js" },
      { estacao: "MARAU", arquivo: "INMET_NE_BA_A438_MARAU.js" },
      { estacao: "IBOTIRAMA", arquivo: "INMET_NE_BA_A439_IBOTIRAMA.js" },
      { estacao: "JACOBINA", arquivo: "INMET_NE_BA_A440_JACOBINA.js" },
      { estacao: "SERRINHA", arquivo: "INMET_NE_BA_A441_SERRINHA.js" },
      {
        estacao: "EUCLIDES DA CUNHA",
        arquivo: "INMET_NE_BA_A442_EUCLIDES DA CUNHA.js",
      },
      { estacao: "DELFINO", arquivo: "INMET_NE_BA_A443_DELFINO.js" },
      { estacao: "VALENCA", arquivo: "INMET_NE_BA_A444_VALENCA.js" },
      { estacao: "IPIAU", arquivo: "INMET_NE_BA_A445_IPIAU.js" },
      { estacao: "ITAPETINGA", arquivo: "INMET_NE_BA_A446_ITAPETINGA.js" },
      { estacao: "BELMONTE", arquivo: "INMET_NE_BA_A447_BELMONTE.js" },
    ],
    CE: [
      { estacao: "FORTALEZA", arquivo: "INMET_NE_CE_A305_FORTALEZA.js" },
      { estacao: "SOBRAL", arquivo: "INMET_NE_CE_A306_SOBRAL.js" },
      { estacao: "GUARAMIRANGA", arquivo: "INMET_NE_CE_A314_GUARAMIRANGA.js" },
      { estacao: "BARBALHA", arquivo: "INMET_NE_CE_A315_BARBALHA.js" },
      { estacao: "IGUATU", arquivo: "INMET_NE_CE_A319_IGUATU.js" },
      { estacao: "TAUA", arquivo: "INMET_NE_CE_A324_TAUA.js" },
      { estacao: "QUIXERAMOBIM", arquivo: "INMET_NE_CE_A325_QUIXERAMOBIM.js" },
      { estacao: "MORADA NOVA", arquivo: "INMET_NE_CE_A332_MORADA NOVA.js" },
      { estacao: "JAGUARUANA", arquivo: "INMET_NE_CE_A339_JAGUARUANA.js" },
      { estacao: "CRATEUS", arquivo: "INMET_NE_CE_A342_CRATEUS.js" },
      { estacao: "CAMPOS SALES", arquivo: "INMET_NE_CE_A347_CAMPOS SALES.js" },
      { estacao: "JAGUARIBE", arquivo: "INMET_NE_CE_A358_JAGUARIBE.js" },
      { estacao: "ITAPIPOCA", arquivo: "INMET_NE_CE_A359_ITAPIPOCA.js" },
      { estacao: "ACARAU", arquivo: "INMET_NE_CE_A360_ACARAU.js" },
    ],
    MA: [
      { estacao: "SAO LUIS", arquivo: "INMET_NE_MA_A203_SAO LUIS.js" },
      { estacao: "BALSAS", arquivo: "INMET_NE_MA_A204_BALSAS.js" },
      { estacao: "CAROLINA", arquivo: "INMET_NE_MA_A205_CAROLINA.js" },
      { estacao: "CHAPADINHA", arquivo: "INMET_NE_MA_A206_CHAPADINHA.js" },
      { estacao: "GRAJAU", arquivo: "INMET_NE_MA_A207_GRAJAU.js" },
      {
        estacao: "FAROL de SANTANA",
        arquivo: "INMET_NE_MA_A217_FAROL de SANTANA.js",
      },
      { estacao: "PREGUICAS", arquivo: "INMET_NE_MA_A218_PREGUICAS.js" },
      { estacao: "TURIACU", arquivo: "INMET_NE_MA_A219_TURIACU.js" },
      { estacao: "BACABAL", arquivo: "INMET_NE_MA_A220_BACABAL.js" },
      {
        estacao: "BARRA DO CORDA",
        arquivo: "INMET_NE_MA_A221_BARRA DO CORDA.js",
      },
      { estacao: "COLINAS", arquivo: "INMET_NE_MA_A222_COLINAS.js" },
      {
        estacao: "ALTO PARNAIBA",
        arquivo: "INMET_NE_MA_A223_ALTO PARNAIBA.js",
      },
      { estacao: "ESTREITO", arquivo: "INMET_NE_MA_A224_ESTREITO.js" },
      { estacao: "IMPERATRIZ", arquivo: "INMET_NE_MA_A225_IMPERATRIZ.js" },
      { estacao: "CAXIAS", arquivo: "INMET_NE_MA_A237_CAXIAS.js" },
      { estacao: "BURITICUPU", arquivo: "INMET_NE_MA_A238_BURITICUPU.js" },
    ],
    PB: [
      { estacao: "AREIA", arquivo: "INMET_NE_PB_A310_AREIA.js" },
      {
        estacao: "CAMPINA GRANDE",
        arquivo: "INMET_NE_PB_A313_CAMPINA GRANDE.js",
      },
      { estacao: "JOAO PESSOA", arquivo: "INMET_NE_PB_A320_JOAO PESSOA.js" },
      { estacao: "PATOS", arquivo: "INMET_NE_PB_A321_PATOS.js" },
      { estacao: "SAO GONCALO", arquivo: "INMET_NE_PB_A333_SAO GONCALO.js" },
      { estacao: "MONTEIRO", arquivo: "INMET_NE_PB_A334_MONTEIRO.js" },
      { estacao: "CABACEIRAS", arquivo: "INMET_NE_PB_A348_CABACEIRAS.js" },
      { estacao: "CAMARATUBA", arquivo: "INMET_NE_PB_A352_CAMARATUBA.js" },
    ],
    PE: [
      { estacao: "RECIFE", arquivo: "INMET_NE_PE_A301_RECIFE.js" },
      { estacao: "PETROLINA", arquivo: "INMET_NE_PE_A307_PETROLINA.js" },
      { estacao: "ARCO VERDE", arquivo: "INMET_NE_PE_A309_ARCO VERDE.js" },
      { estacao: "GARANHUNS", arquivo: "INMET_NE_PE_A322_GARANHUNS.js" },
      { estacao: "SURUBIM", arquivo: "INMET_NE_PE_A328_SURUBIM.js" },
      { estacao: "CABROBO", arquivo: "INMET_NE_PE_A329_CABROBO.js" },
      { estacao: "CARUARU", arquivo: "INMET_NE_PE_A341_CARUARU.js" },
      { estacao: "IBIMIRIM", arquivo: "INMET_NE_PE_A349_IBIMIRIM.js" },
      {
        estacao: "SERRA TALHADA",
        arquivo: "INMET_NE_PE_A350_SERRA TALHADA.js",
      },
      { estacao: "FLORESTA", arquivo: "INMET_NE_PE_A351_FLORESTA.js" },
      { estacao: "PALMARES", arquivo: "INMET_NE_PE_A357_PALMARES.js" },
      { estacao: "OURICURI", arquivo: "INMET_NE_PE_A366_OURICURI.js" },
    ],
    PI: [
      { estacao: "PARNAIBA", arquivo: "INMET_NE_PI_A308_PARNAIBA.js" },
      { estacao: "FLORIANO", arquivo: "INMET_NE_PI_A311_FLORIANO.js" },
      { estacao: "TERESINA", arquivo: "INMET_NE_PI_A312_TERESINA.js" },
      {
        estacao: "BOM JESUS DO PIAUI",
        arquivo: "INMET_NE_PI_A326_BOM JESUS DO PIAUI.js",
      },
      { estacao: "PAULISTANA", arquivo: "INMET_NE_PI_A330_PAULISTANA.js" },
      {
        estacao: "SAO JOAO DO PIAUI",
        arquivo: "INMET_NE_PI_A331_SAO JOAO DO PIAUI.js",
      },
      { estacao: "PIRIPIRI", arquivo: "INMET_NE_PI_A335_PIRIPIRI.js" },
      {
        estacao: "ALVORADA DO GURGUEIA",
        arquivo: "INMET_NE_PI_A336_ALVORADA DO GURGUEIA.js",
      },
      { estacao: "CARACOL", arquivo: "INMET_NE_PI_A337_CARACOL.js" },
      { estacao: "PICOS", arquivo: "INMET_NE_PI_A343_PICOS.js" },
      {
        estacao: "SAO RAIMUNDO NONATO",
        arquivo: "INMET_NE_PI_A345_SAO RAIMUNDO NONATO.js",
      },
      { estacao: "URUCUI", arquivo: "INMET_NE_PI_A346_URUCUI.js" },
      { estacao: "OEIRAS", arquivo: "INMET_NE_PI_A354_OEIRAS.js" },
      {
        estacao: "CASTELO DO PIAUI",
        arquivo: "INMET_NE_PI_A361_CASTELO DO PIAUI.js",
      },
      {
        estacao: "VALENCA DO PIAUI",
        arquivo: "INMET_NE_PI_A363_VALENCA DO PIAUI.js",
      },
      { estacao: "GILBUES", arquivo: "INMET_NE_PI_A364_GILBUES.js" },
      {
        estacao: "CANTO DO BURITI",
        arquivo: "INMET_NE_PI_A365_CANTO DO BURITI.js",
      },
    ],
    RN: [
      { estacao: "NATAL", arquivo: "INMET_NE_RN_A304_NATAL.js" },
      { estacao: "CAICO", arquivo: "INMET_NE_RN_A316_CAICO.js" },
      { estacao: "MACAU", arquivo: "INMET_NE_RN_A317_MACAU.js" },
      { estacao: "MOSSORO", arquivo: "INMET_NE_RN_A318_MOSSORO.js" },
      { estacao: "APODI", arquivo: "INMET_NE_RN_A340_APODI.js" },
      { estacao: "CALCANHAR", arquivo: "INMET_NE_RN_A344_CALCANHAR.js" },
      { estacao: "SANTA CRUZ", arquivo: "INMET_NE_RN_A367_SANTA CRUZ.js" },
    ],
    SE: [
      { estacao: "ARACAJU", arquivo: "INMET_NE_SE_A409_ARACAJU.js" },
      { estacao: "ITABAIANINHA", arquivo: "INMET_NE_SE_A417_ITABAIANINHA.js" },
      { estacao: "POCO VERDE", arquivo: "INMET_NE_SE_A419_POCO VERDE.js" },
      { estacao: "CARIRA", arquivo: "INMET_NE_SE_A420_CARIRA.js" },
      { estacao: "BREJO GRANDE", arquivo: "INMET_NE_SE_A421_BREJO GRANDE.js" },
    ],
  },
  N: {
    AC: [
      {
        estacao: "PARQUE ESTADUAL CHANDLESS",
        arquivo: "INMET_N_AC_A102_PARQUE ESTADUAL CHANDLESS.js",
      },
      { estacao: "RIO BRANCO", arquivo: "INMET_N_AC_A104_RIO BRANCO.js" },
      { estacao: "PORTO WALTER", arquivo: "INMET_N_AC_A136_PORTO WALTER.js" },
      {
        estacao: "MARECHAL THAUMATURGO",
        arquivo: "INMET_N_AC_A137_MARECHAL THAUMATURGO.js",
      },
      { estacao: "FEIJO", arquivo: "INMET_N_AC_A138_FEIJO.js" },
      {
        estacao: "EPITACIOLANDIA",
        arquivo: "INMET_N_AC_A140_EPITACIOLANDIA.js",
      },
    ],
    AM: [
      { estacao: "MANAUS", arquivo: "INMET_N_AM_A101_MANAUS.js" },
      { estacao: "EIRUNEPE", arquivo: "INMET_N_AM_A109_EIRUNEPE.js" },
      { estacao: "BOCA DO ACRE", arquivo: "INMET_N_AM_A110_BOCA DO ACRE.js" },
      { estacao: "LABREA", arquivo: "INMET_N_AM_A111_LABREA.js" },
      { estacao: "HUMAITA", arquivo: "INMET_N_AM_A112_HUMAITA.js" },
      { estacao: "APUI", arquivo: "INMET_N_AM_A113_APUI.js" },
      { estacao: "COARI", arquivo: "INMET_N_AM_A117_COARI.js" },
      { estacao: "MANACAPURU", arquivo: "INMET_N_AM_A119_MANACAPURU.js" },
      { estacao: "AUTAZES", arquivo: "INMET_N_AM_A120_AUTAZES.js" },
      { estacao: "ITACOATIARA", arquivo: "INMET_N_AM_A121_ITACOATIARA.js" },
      { estacao: "MAUES", arquivo: "INMET_N_AM_A122_MAUES.js" },
      { estacao: "PARINTINS", arquivo: "INMET_N_AM_A123_PARINTINS.js" },
      { estacao: "URUCARA", arquivo: "INMET_N_AM_A124_URUCARA.js" },
      { estacao: "RIO URUBU", arquivo: "INMET_N_AM_A125_RIO URUBU.js" },
      {
        estacao: "PRESIDENTE FIGUEIREDO",
        arquivo: "INMET_N_AM_A126_PRESIDENTE FIGUEIREDO.js",
      },
      { estacao: "BARCELOS", arquivo: "INMET_N_AM_A128_BARCELOS.js" },
      { estacao: "MANICORE", arquivo: "INMET_N_AM_A133_MANICORE.js" },
      {
        estacao: "S. G. DA CACHOEIRA",
        arquivo: "INMET_N_AM_A134_S. G. DA CACHOEIRA.js",
      },
    ],
    AP: [{ estacao: "OIAPOQUE", arquivo: "INMET_N_AP_A242_OIAPOQUE.js" }],
    PA: [
      { estacao: "BELEM", arquivo: "INMET_N_PA_A201_BELEM.js" },
      { estacao: "CASTANHAL", arquivo: "INMET_N_PA_A202_CASTANHAL.js" },
      { estacao: "MEDICILANDIA", arquivo: "INMET_N_PA_A209_MEDICILANDIA.js" },
      { estacao: "PACAJA", arquivo: "INMET_N_PA_A210_PACAJA.js" },
      { estacao: "PLACAS", arquivo: "INMET_N_PA_A211_PLACAS.js" },
      { estacao: "PARAGOMINAS", arquivo: "INMET_N_PA_A212_PARAGOMINAS.js" },
      { estacao: "TOME ACU", arquivo: "INMET_N_PA_A213_TOME ACU.js" },
      {
        estacao: "RONDON DO PARA",
        arquivo: "INMET_N_PA_A214_RONDON DO PARA.js",
      },
      { estacao: "SALINOPOLIS", arquivo: "INMET_N_PA_A215_SALINOPOLIS.js" },
      { estacao: "BRAGANCA", arquivo: "INMET_N_PA_A226_BRAGANCA.js" },
      { estacao: "SOURE", arquivo: "INMET_N_PA_A227_SOURE.js" },
      { estacao: "TUCURUI", arquivo: "INMET_N_PA_A229_TUCURUI.js" },
      {
        estacao: "SERRA DOS CARAJAS",
        arquivo: "INMET_N_PA_A230_SERRA DOS CARAJAS.js",
      },
      { estacao: "ITAITUBA", arquivo: "INMET_N_PA_A231_ITAITUBA.js" },
      { estacao: "OBIDOS", arquivo: "INMET_N_PA_A232_OBIDOS.js" },
      {
        estacao: "SANTANA DO ARAGUAIA",
        arquivo: "INMET_N_PA_A233_SANTANA DO ARAGUAIA.js",
      },
      { estacao: "TUCUMA", arquivo: "INMET_N_PA_A234_TUCUMA.js" },
      {
        estacao: "NOVO REPARTIMENTO",
        arquivo: "INMET_N_PA_A235_NOVO REPARTIMENTO.js",
      },
      { estacao: "CAMETA", arquivo: "INMET_N_PA_A236_CAMETA.js" },
      { estacao: "MONTE ALEGRE", arquivo: "INMET_N_PA_A239_MONTE ALEGRE.js" },
      { estacao: "MARABA", arquivo: "INMET_N_PA_A240_MARABA.js" },
      {
        estacao: "CONCEICAO DO ARAGUAIA",
        arquivo: "INMET_N_PA_A241_CONCEICAO DO ARAGUAIA.js",
      },
      {
        estacao: "MINA DO PALITO",
        arquivo: "INMET_N_PA_A246_MINA DO PALITO.js",
      },
      { estacao: "CAPITAO POCO", arquivo: "INMET_N_PA_A248_CAPITAO POCO.js" },
    ],
    RO: [
      { estacao: "PORTO VELHO", arquivo: "INMET_N_RO_A925_PORTO VELHO.js" },
      { estacao: "VILHENA", arquivo: "INMET_N_RO_A938_VILHENA.js" },
      { estacao: "CACOAL", arquivo: "INMET_N_RO_A939_CACOAL.js" },
      { estacao: "ARIQUEMES", arquivo: "INMET_N_RO_A940_ARIQUEMES.js" },
    ],
    RR: [{ estacao: "BOA VISTA", arquivo: "INMET_N_RR_A135_BOA VISTA.js" }],
    TO: [
      { estacao: "PALMAS", arquivo: "INMET_N_TO_A009_PALMAS.js" },
      { estacao: "PARANA", arquivo: "INMET_N_TO_A010_PARANA.js" },
      { estacao: "PEIXE", arquivo: "INMET_N_TO_A018_PEIXE.js" },
      { estacao: "GURUPI", arquivo: "INMET_N_TO_A019_GURUPI.js" },
      { estacao: "PEDRO AFONSO", arquivo: "INMET_N_TO_A020_PEDRO AFONSO.js" },
      { estacao: "ARAGUAINA", arquivo: "INMET_N_TO_A021_ARAGUAINA.js" },
      { estacao: "DIANOPOLIS", arquivo: "INMET_N_TO_A038_DIANOPOLIS.js" },
      {
        estacao: "FORMOSO DO ARAGUAIA",
        arquivo: "INMET_N_TO_A039_FORMOSO DO ARAGUAIA.js",
      },
      { estacao: "MATEIROS", arquivo: "INMET_N_TO_A040_MATEIROS.js" },
      {
        estacao: "MARIANOPOLIS DO TO",
        arquivo: "INMET_N_TO_A041_MARIANOPOLIS DO TO.js",
      },
      { estacao: "CAMPOS LINDOS", arquivo: "INMET_N_TO_A043_CAMPOS LINDOS.js" },
      { estacao: "ARAGUATINS", arquivo: "INMET_N_TO_A044_ARAGUATINS.js" },
    ],
  },
  SE: {
    ES: [
      { estacao: "VITORIA", arquivo: "INMET_SE_ES_A612_VITORIA.js" },
      { estacao: "SANTA TERESA", arquivo: "INMET_SE_ES_A613_SANTA TERESA.js" },
      { estacao: "LINHARES", arquivo: "INMET_SE_ES_A614_LINHARES.js" },
      {
        estacao: "ALFREDO CHAVES",
        arquivo: "INMET_SE_ES_A615_ALFREDO CHAVES.js",
      },
      { estacao: "SAO MATEUS", arquivo: "INMET_SE_ES_A616_SAO MATEUS.js" },
      { estacao: "ALEGRE", arquivo: "INMET_SE_ES_A617_ALEGRE.js" },
      { estacao: "NOVA VENECIA", arquivo: "INMET_SE_ES_A623_NOVA VENECIA.js" },
      {
        estacao: "AFONSO CLAUDIO",
        arquivo: "INMET_SE_ES_A657_AFONSO CLAUDIO.js",
      },
    ],
    MG: [
      { estacao: "BARBACENA", arquivo: "INMET_SE_MG_A502_BARBACENA.js" },
      { estacao: "ARAXA", arquivo: "INMET_SE_MG_A505_ARAXA.js" },
      {
        estacao: "MONTES CLAROS",
        arquivo: "INMET_SE_MG_A506_MONTES CLAROS.js",
      },
      { estacao: "UBERLANDIA", arquivo: "INMET_SE_MG_A507_UBERLANDIA.js" },
      { estacao: "ALMENARA", arquivo: "INMET_SE_MG_A508_ALMENARA.js" },
      { estacao: "MONTE VERDE", arquivo: "INMET_SE_MG_A509_MONTE VERDE.js" },
      { estacao: "VICOSA", arquivo: "INMET_SE_MG_A510_VICOSA.js" },
      { estacao: "TIMOTEO", arquivo: "INMET_SE_MG_A511_TIMOTEO.js" },
      { estacao: "ITUIUTABA", arquivo: "INMET_SE_MG_A512_ITUIUTABA.js" },
      { estacao: "OURO BRANCO", arquivo: "INMET_SE_MG_A513_OURO BRANCO.js" },
      {
        estacao: "SAO JOAO DEL REI",
        arquivo: "INMET_SE_MG_A514_SAO JOAO DEL REI.js",
      },
      { estacao: "VARGINHA", arquivo: "INMET_SE_MG_A515_VARGINHA.js" },
      { estacao: "PASSOS", arquivo: "INMET_SE_MG_A516_PASSOS.js" },
      { estacao: "MURIAE", arquivo: "INMET_SE_MG_A517_MURIAE.js" },
      { estacao: "JUIZ DE FORA", arquivo: "INMET_SE_MG_A518_JUIZ DE FORA.js" },
      {
        estacao: "CAMPINA VERDE",
        arquivo: "INMET_SE_MG_A519_CAMPINA VERDE.js",
      },
      {
        estacao: "CONCEICAO DAS ALAGOAS",
        arquivo: "INMET_SE_MG_A520_CONCEICAO DAS ALAGOAS.js",
      },
      {
        estacao: "SERRA DOS AIMORES",
        arquivo: "INMET_SE_MG_A522_SERRA DOS AIMORES.js",
      },
      { estacao: "PATROCINIO", arquivo: "INMET_SE_MG_A523_PATROCINIO.js" },
      { estacao: "FORMIGA", arquivo: "INMET_SE_MG_A524_FORMIGA.js" },
      { estacao: "SACRAMENTO", arquivo: "INMET_SE_MG_A525_SACRAMENTO.js" },
      { estacao: "MONTALVANIA", arquivo: "INMET_SE_MG_A526_MONTALVANIA.js" },
      {
        estacao: "TEOFILO OTONI",
        arquivo: "INMET_SE_MG_A527_TEOFILO OTONI.js",
      },
      { estacao: "TRES MARIAS", arquivo: "INMET_SE_MG_A528_TRES MARIAS.js" },
      { estacao: "PASSA QUATRO", arquivo: "INMET_SE_MG_A529_PASSA QUATRO.js" },
      { estacao: "CALDAS", arquivo: "INMET_SE_MG_A530_CALDAS.js" },
      { estacao: "MARIA DA FE", arquivo: "INMET_SE_MG_A531_MARIA DA FE.js" },
      {
        estacao: "GOVERNADOR VALADARES",
        arquivo: "INMET_SE_MG_A532_GOVERNADOR VALADARES.js",
      },
      { estacao: "GUANHAES", arquivo: "INMET_SE_MG_A533_GUANHAES.js" },
      { estacao: "AIMORES", arquivo: "INMET_SE_MG_A534_AIMORES.js" },
      { estacao: "FLORESTAL", arquivo: "INMET_SE_MG_A535_FLORESTAL.js" },
      {
        estacao: "DORES DO INDAIA",
        arquivo: "INMET_SE_MG_A536_DORES DO INDAIA.js",
      },
      { estacao: "DIAMANTINA", arquivo: "INMET_SE_MG_A537_DIAMANTINA.js" },
      { estacao: "CURVELO", arquivo: "INMET_SE_MG_A538_CURVELO.js" },
      { estacao: "MOCAMBINHO", arquivo: "INMET_SE_MG_A539_MOCAMBINHO.js" },
      { estacao: "MANTENA", arquivo: "INMET_SE_MG_A540_MANTENA.js" },
      { estacao: "CAPELINHA", arquivo: "INMET_SE_MG_A541_CAPELINHA.js" },
      { estacao: "UNAI", arquivo: "INMET_SE_MG_A542_UNAI.js" },
      { estacao: "ESPINOSA", arquivo: "INMET_SE_MG_A543_ESPINOSA.js" },
      { estacao: "BURITIS", arquivo: "INMET_SE_MG_A544_BURITIS.js" },
      { estacao: "PIRAPORA", arquivo: "INMET_SE_MG_A545_PIRAPORA.js" },
      { estacao: "GUARDA-MOR", arquivo: "INMET_SE_MG_A546_GUARDA-MOR.js" },
      { estacao: "SAO ROMAO", arquivo: "INMET_SE_MG_A547_SAO ROMAO.js" },
      {
        estacao: "CHAPADA GAUCHA",
        arquivo: "INMET_SE_MG_A548_CHAPADA GAUCHA.js",
      },
      {
        estacao: "AGUAS VERMELHAS",
        arquivo: "INMET_SE_MG_A549_AGUAS VERMELHAS.js",
      },
      { estacao: "ITAOBIM", arquivo: "INMET_SE_MG_A550_ITAOBIM.js" },
      {
        estacao: "RIO PARDO DE MINAS",
        arquivo: "INMET_SE_MG_A551_RIO PARDO DE MINAS.js",
      },
      { estacao: "SALINAS", arquivo: "INMET_SE_MG_A552_SALINAS.js" },
      {
        estacao: "JOAO PINHEIRO",
        arquivo: "INMET_SE_MG_A553_JOAO PINHEIRO.js",
      },
      { estacao: "CARATINGA", arquivo: "INMET_SE_MG_A554_CARATINGA.js" },
      {
        estacao: "IBIRITE (ROLA MOCA)",
        arquivo: "INMET_SE_MG_A555_IBIRITE (ROLA MOCA).js",
      },
      { estacao: "MANHUACU", arquivo: "INMET_SE_MG_A556_MANHUACU.js" },
      {
        estacao: "CORONEL PACHECO",
        arquivo: "INMET_SE_MG_A557_CORONEL PACHECO.js",
      },
    ],
    RJ: [
      { estacao: "CAMBUCI", arquivo: "INMET_SE_RJ_A604_CAMBUCI.js" },
      {
        estacao: "ARRAIAL DO CABO",
        arquivo: "INMET_SE_RJ_A606_ARRAIAL DO CABO.js",
      },
      { estacao: "MACAE", arquivo: "INMET_SE_RJ_A608_MACAE.js" },
      { estacao: "RESENDE", arquivo: "INMET_SE_RJ_A609_RESENDE.js" },
      {
        estacao: "PICO DO COUTO",
        arquivo: "INMET_SE_RJ_A610_PICO DO COUTO.js",
      },
      { estacao: "VALENCA", arquivo: "INMET_SE_RJ_A611_VALENCA.js" },
    ],
    SP: [
      {
        estacao: "SAO PAULO - MIRANTE",
        arquivo: "INMET_SE_SP_A701_SAO PAULO - MIRANTE.js",
      },
      { estacao: "BAURU", arquivo: "INMET_SE_SP_A705_BAURU.js" },
      {
        estacao: "CAMPOS DO JORDAO",
        arquivo: "INMET_SE_SP_A706_CAMPOS DO JORDAO.js",
      },
      {
        estacao: "PRESIDENTE PRUDENTE",
        arquivo: "INMET_SE_SP_A707_PRESIDENTE PRUDENTE.js",
      },
      { estacao: "FRANCA", arquivo: "INMET_SE_SP_A708_FRANCA.js" },
      { estacao: "SAO CARLOS", arquivo: "INMET_SE_SP_A711_SAO CARLOS.js" },
      { estacao: "IGUAPE", arquivo: "INMET_SE_SP_A712_IGUAPE.js" },
      { estacao: "SOROCABA", arquivo: "INMET_SE_SP_A713_SOROCABA.js" },
      { estacao: "ITAPEVA", arquivo: "INMET_SE_SP_A714_ITAPEVA.js" },
      {
        estacao: "SAO MIGUEL ARCANJO",
        arquivo: "INMET_SE_SP_A715_SAO MIGUEL ARCANJO.js",
      },
      { estacao: "RANCHARIA", arquivo: "INMET_SE_SP_A718_RANCHARIA.js" },
      { estacao: "AVARE", arquivo: "INMET_SE_SP_A725_AVARE.js" },
      { estacao: "PIRACICABA", arquivo: "INMET_SE_SP_A726_PIRACICABA.js" },
      { estacao: "LINS", arquivo: "INMET_SE_SP_A727_LINS.js" },
      { estacao: "TAUBATE", arquivo: "INMET_SE_SP_A728_TAUBATE.js" },
      { estacao: "JALES", arquivo: "INMET_SE_SP_A733_JALES.js" },
      { estacao: "VALPARAISO", arquivo: "INMET_SE_SP_A734_VALPARAISO.js" },
      {
        estacao: "JOSE BONIFACIO",
        arquivo: "INMET_SE_SP_A735_JOSE BONIFACIO.js",
      },
      { estacao: "ARIRANHA", arquivo: "INMET_SE_SP_A736_ARIRANHA.js" },
      { estacao: "IBITINGA", arquivo: "INMET_SE_SP_A737_IBITINGA.js" },
      { estacao: "CASA BRANCA", arquivo: "INMET_SE_SP_A738_CASA BRANCA.js" },
      { estacao: "ITAPIRA", arquivo: "INMET_SE_SP_A739_ITAPIRA.js" },
      { estacao: "BARRA BONITA", arquivo: "INMET_SE_SP_A741_BARRA BONITA.js" },
      {
        estacao: "BARRA DO TURVO",
        arquivo: "INMET_SE_SP_A746_BARRA DO TURVO.js",
      },
      { estacao: "PRADOPOLIS", arquivo: "INMET_SE_SP_A747_PRADOPOLIS.js" },
      { estacao: "BARRETOS", arquivo: "INMET_SE_SP_A748_BARRETOS.js" },
      { estacao: "ITUVERAVA", arquivo: "INMET_SE_SP_A753_ITUVERAVA.js" },
      { estacao: "BARUERI", arquivo: "INMET_SE_SP_A755_BARUERI.js" },
    ],
  },
  S: {
    PR: [
      { estacao: "CURITIBA", arquivo: "INMET_S_PR_A807_CURITIBA.js" },
      { estacao: "IVAI", arquivo: "INMET_S_PR_A818_IVAI.js" },
      {
        estacao: "MAL. CANDIDO RONDON",
        arquivo: "INMET_S_PR_A820_MAL. CANDIDO RONDON.js",
      },
      {
        estacao: "JOAQUIM TAVORA",
        arquivo: "INMET_S_PR_A821_JOAQUIM TAVORA.js",
      },
      {
        estacao: "INACIO MARTINS",
        arquivo: "INMET_S_PR_A823_INACIO MARTINS.js",
      },
      { estacao: "ICARAIMA", arquivo: "INMET_S_PR_A824_ICARAIMA.js" },
      { estacao: "MARINGA", arquivo: "INMET_S_PR_A835_MARINGA.js" },
      { estacao: "NOVA FATIMA", arquivo: "INMET_S_PR_A842_NOVA FATIMA.js" },
      { estacao: "DOIS VIZINHOS", arquivo: "INMET_S_PR_A843_DOIS VIZINHOS.js" },
      { estacao: "FOZ DO IGUACU", arquivo: "INMET_S_PR_A846_FOZ DO IGUACU.js" },
      {
        estacao: "DIAMANTE DO NORTE",
        arquivo: "INMET_S_PR_A849_DIAMANTE DO NORTE.js",
      },
      { estacao: "PARANAPOEMA", arquivo: "INMET_S_PR_A850_PARANAPOEMA.js" },
      { estacao: "PLANALTO", arquivo: "INMET_S_PR_A855_PLANALTO.js" },
      { estacao: "CIDADE GAUCHA", arquivo: "INMET_S_PR_A869_CIDADE GAUCHA.js" },
      { estacao: "JAPIRA", arquivo: "INMET_S_PR_A871_JAPIRA.js" },
      { estacao: "VENTANIA", arquivo: "INMET_S_PR_A872_VENTANIA.js" },
      { estacao: "MORRETES", arquivo: "INMET_S_PR_A873_MORRETES.js" },
      {
        estacao: "SAO MATEUS DO SUL",
        arquivo: "INMET_S_PR_A874_SAO MATEUS DO SUL.js",
      },
      {
        estacao: "GENERAL CARNEIRO",
        arquivo: "INMET_S_PR_A875_GENERAL CARNEIRO.js",
      },
      { estacao: "CLEVELANDIA", arquivo: "INMET_S_PR_A876_CLEVELANDIA.js" },
    ],
    RS: [
      { estacao: "RIO GRANDE", arquivo: "INMET_S_RS_A802_RIO GRANDE.js" },
      { estacao: "SANTA MARIA", arquivo: "INMET_S_RS_A803_SANTA MARIA.js" },
      {
        estacao: "SANTANA DO LIVRAMENTO",
        arquivo: "INMET_S_RS_A804_SANTANA DO LIVRAMENTO.js",
      },
      { estacao: "SANTO AUGUSTO", arquivo: "INMET_S_RS_A805_SANTO AUGUSTO.js" },
      { estacao: "TORRES", arquivo: "INMET_S_RS_A808_TORRES.js" },
      { estacao: "URUGUAIANA", arquivo: "INMET_S_RS_A809_URUGUAIANA.js" },
      { estacao: "SANTA ROSA", arquivo: "INMET_S_RS_A810_SANTA ROSA.js" },
      { estacao: "CANGUCU", arquivo: "INMET_S_RS_A811_CANGUCU.js" },
      {
        estacao: "CACAPAVA DO SUL",
        arquivo: "INMET_S_RS_A812_CACAPAVA DO SUL.js",
      },
      { estacao: "RIO PARDO", arquivo: "INMET_S_RS_A813_RIO PARDO.js" },
      { estacao: "ALEGRETE", arquivo: "INMET_S_RS_A826_ALEGRETE.js" },
      { estacao: "BAGE", arquivo: "INMET_S_RS_A827_BAGE.js" },
      { estacao: "ERECHIM", arquivo: "INMET_S_RS_A828_ERECHIM.js" },
      {
        estacao: "SAO JOSE DOS AUSENTES",
        arquivo: "INMET_S_RS_A829_SAO JOSE DOS AUSENTES.js",
      },
      { estacao: "SAO BORJA", arquivo: "INMET_S_RS_A830_SAO BORJA.js" },
      { estacao: "QUARAI", arquivo: "INMET_S_RS_A831_QUARAI.js" },
      { estacao: "SAO GABRIEL", arquivo: "INMET_S_RS_A832_SAO GABRIEL.js" },
      { estacao: "SANTIAGO", arquivo: "INMET_S_RS_A833_SANTIAGO.js" },
      { estacao: "TRAMANDAI", arquivo: "INMET_S_RS_A834_TRAMANDAI.js" },
      { estacao: "JAGUARAO", arquivo: "INMET_S_RS_A836_JAGUARAO.js" },
      { estacao: "SOLEDADE", arquivo: "INMET_S_RS_A837_SOLEDADE.js" },
      { estacao: "CAMAQUA", arquivo: "INMET_S_RS_A838_CAMAQUA.js" },
      { estacao: "PASSO FUNDO", arquivo: "INMET_S_RS_A839_PASSO FUNDO.js" },
      {
        estacao: "BENTO GONCALVES",
        arquivo: "INMET_S_RS_A840_BENTO GONCALVES.js",
      },
      {
        estacao: "LAGOA VERMELHA",
        arquivo: "INMET_S_RS_A844_LAGOA VERMELHA.js",
      },
      {
        estacao: "SAO LUIZ GONZAGA",
        arquivo: "INMET_S_RS_A852_SAO LUIZ GONZAGA.js",
      },
      { estacao: "CRUZ ALTA", arquivo: "INMET_S_RS_A853_CRUZ ALTA.js" },
      {
        estacao: "FREDERICO WESTPHALEN",
        arquivo: "INMET_S_RS_A854_FREDERICO WESTPHALEN.js",
      },
      {
        estacao: "PALMEIRA DAS MISSOES",
        arquivo: "INMET_S_RS_A856_PALMEIRA DAS MISSOES.js",
      },
      { estacao: "MOSTARDAS", arquivo: "INMET_S_RS_A878_MOSTARDAS.js" },
      { estacao: "CANELA", arquivo: "INMET_S_RS_A879_CANELA.js" },
      { estacao: "VACARIA", arquivo: "INMET_S_RS_A880_VACARIA.js" },
      { estacao: "DOM PEDRITO", arquivo: "INMET_S_RS_A881_DOM PEDRITO.js" },
      { estacao: "TEUTONIA", arquivo: "INMET_S_RS_A882_TEUTONIA.js" },
      { estacao: "IBIRUBA", arquivo: "INMET_S_RS_A883_IBIRUBA.js" },
      {
        estacao: "Santa Vitoria do Palmar - Barra do Chui",
        arquivo: "INMET_S_RS_A899_Santa Vitoria do Palmar - Barra do Chui.js",
      },
    ],
    SC: [
      { estacao: "FLORIANOPOLIS", arquivo: "INMET_S_SC_A806_FLORIANOPOLIS.js" },
      { estacao: "URUSSANGA", arquivo: "INMET_S_SC_A814_URUSSANGA.js" },
      { estacao: "SAO JOAQUIM", arquivo: "INMET_S_SC_A815_SAO JOAQUIM.js" },
      {
        estacao: "NOVO HORIZONTE",
        arquivo: "INMET_S_SC_A816_NOVO HORIZONTE.js",
      },
      { estacao: "INDAIAL", arquivo: "INMET_S_SC_A817_INDAIAL.js" },
      { estacao: "JOACABA", arquivo: "INMET_S_SC_A841_JOACABA.js" },
      {
        estacao: "DIONISIO CERQUEIRA",
        arquivo: "INMET_S_SC_A848_DIONISIO CERQUEIRA.js",
      },
      {
        estacao: "BOM JARDIM DA SERRA - MORRO DA IGREJA",
        arquivo: "INMET_S_SC_A845_BOM JARDIM DA SERRA - MORRO DA IGREJA.js",
      },
      { estacao: "ITAPOA", arquivo: "INMET_S_SC_A851_ITAPOA.js" },
      {
        estacao: "SAO MIGUEL DO OESTE",
        arquivo: "INMET_S_SC_A857_SAO MIGUEL DO OESTE.js",
      },
      { estacao: "XANXERE", arquivo: "INMET_S_SC_A858_XANXERE.js" },
      { estacao: "CACADOR", arquivo: "INMET_S_SC_A859_CACADOR.js" },
      { estacao: "CURITIBANOS", arquivo: "INMET_S_SC_A860_CURITIBANOS.js" },
      { estacao: "RIO DO CAMPO", arquivo: "INMET_S_SC_A861_RIO DO CAMPO.js" },
      { estacao: "RIO NEGRINHO", arquivo: "INMET_S_SC_A862_RIO NEGRINHO.js" },
      { estacao: "ITUPORANGA", arquivo: "INMET_S_SC_A863_ITUPORANGA.js" },
      { estacao: "MAJOR VIEIRA", arquivo: "INMET_S_SC_A864_MAJOR VIEIRA.js" },
      {
        estacao: "Laguna  - Farol de Santa Marta",
        arquivo: "INMET_S_SC_A866_Laguna  - Farol de Santa Marta.js",
      },
      { estacao: "ARARANGUA", arquivo: "INMET_S_SC_A867_ARARANGUA.js" },
      { estacao: "ITAJAI", arquivo: "INMET_S_SC_A868_ITAJAI.js" },
    ],
  },
};
