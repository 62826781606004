
import Vue from "vue";
import { useStore } from "@/store";
import { UsosSubstituiveisEnum } from "@/utils/constants";
import Privada from "../../assets/banheiro/privada.svg";
import Carro from "../../assets/areaExterna/carro.svg";
import Mangueira from "../../assets/areaExterna/mangueira.svg";
import Plantas from "../../assets/areaExterna/plantas.svg";
import StepperButtons from "./StepperButtons.vue";

export default Vue.extend({
  name: "StepUsos",
  components: {
    Privada,
    Carro,
    Mangueira,
    Plantas,
    StepperButtons,
  },
  data: () => ({
    store: useStore(),
    UsosSubstituiveisEnum,
    usosSelecionados: [],
  }),
  mounted() {
    this.usosSelecionados.splice(0, this.usosSelecionados.length);
    this.usosSelecionados.push(
      ...this.store.getConfigCaptacaoAgua.usosSelecionados
    );
  },
  methods: {
    selecionaUsos() {
      this.store.setUsosSubstituidos(this.usosSelecionados);
      this.$emit("click-ok");
    },
  },
});
