
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import SubFormLavarCarro from "./SubFormLavarCarro.vue";
import TemOuNaoTemButtons from "../TemOuNaoTemButtons.vue";
import { useStore } from "@/store";
import { SimOuNaoEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormCarro",
  components: {
    LayoutCard,
    SubFormLavarCarro,
    TemOuNaoTemButtons,
  },
  data() {
    return {
      selecionado: false,
      store: useStore(),
    };
  },
  computed: {
    temCarroSimOuNao() {
      if (this.store.getConfigHabitacao.temCarro === true) {
        return SimOuNaoEnum.SIM;
      }
      if (this.store.getConfigHabitacao.temCarro === false) {
        return SimOuNaoEnum.NAO;
      }
      return null;
    },
  },
  methods: {
    subformSelecionado() {
      if (this.temCarroSimOuNao === SimOuNaoEnum.SIM) {
        this.selecionado = true;
      }
    },
    setCarro(valor: boolean) {
      this.store.setCarro(valor);
      if (!valor) {
        this.selecionado = false;
        this.goToProximoPasso();
      } else {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Carro completo!");
    },
  },
});
