var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"text-center mt-8"},[_c('h3',{staticClass:"font-weight-light"},[_vm._v(" Isso equivale a "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.formataNumero(_vm.numGaloes, 1)))]),_vm._v(" galões de 20L por dia por pessoa... ")])]),(_vm.numGaloes > _vm.galoesPorLinha)?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l(([...Array(parseInt(_vm.numGaloes)).keys()]),function(it,index){return _c('v-col',{key:it,staticClass:"text-center",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":parseInt(_vm.numGaloes) - 1 === index
              ? 'primary lighten-2'
              : 'primary',"size":"30"}},[_vm._v("mdi-bottle-tonic")])],1)}),1)]:_vm._l((_vm.fileirasGaloes),function(linha,index1){return _c('div',{key:`linhaGalao${index1}`,staticClass:"d-flex justify-space-around"},_vm._l((linha),function(gal,index2){return _c('div',{key:`galao${index2}`},[_c('v-icon',{attrs:{"size":"30","color":linha.length - 1 === parseInt(gal) &&
            _vm.fileirasGaloes.length - 1 === index1
              ? 'primary lighten-2'
              : 'primary'}},[_vm._v(" mdi-bottle-tonic ")])],1)}),0)}),_c('div',{staticClass:"text-center mt-4"},[_c('h3',{staticClass:"font-weight-light"},[_vm._v(" ...ou a "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.formataNumero(_vm.numCaminhoes, 1)))]),_vm._v(" caminhões pipa de 20.000L por ano ")])]),(_vm.numCaminhoes > _vm.caminhoesPorLinha)?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l(([...Array(parseInt(_vm.numCaminhoes)).keys()]),function(it,index){return _c('v-col',{key:it,staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":parseInt(_vm.numCaminhoes) - 1 === index
              ? 'primary lighten-2'
              : 'primary',"size":"60"}},[_vm._v("mdi-tanker-truck")])],1)}),1)]:_vm._l((_vm.fileirasCaminhoes),function(linha,index1){return _c('div',{key:`linhaCaminhao${index1}`,staticClass:"d-flex justify-space-around"},_vm._l((linha),function(cam,index2){return _c('div',{key:`caminhao${index2}`},[_c('v-icon',{attrs:{"size":"60","color":linha.length - 1 === parseInt(cam) &&
            _vm.fileirasCaminhoes.length - 1 === index1
              ? 'primary lighten-2'
              : 'primary'}},[_vm._v(" mdi-tanker-truck ")])],1)}),0)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }