
import Vue from "vue";
import { useStore } from "@/store";
import { formataNumero } from "@/utils/functions";
import TotalDoComodoCard from "../TotalDoComodoCard.vue";
import ResultadosCard from "../ResultadosCard.vue";
import Carro from "../../assets/areaExterna/carro.svg";
import Piscina from "../../assets/areaExterna/piscina.svg";
import Chuveiro from "../../assets/areaExterna/chuveiro.svg";
import Plantas from "../../assets/areaExterna/plantas.svg";
import Mangueira from "../../assets/areaExterna/mangueira.svg";
import { FormsAreaExternaEnum } from "@/utils/constants";

export default Vue.extend({
  name: "AreaExternaResultadosStep",
  components: {
    TotalDoComodoCard,
    ResultadosCard,
    Carro,
    Piscina,
    Chuveiro,
    Plantas,
    Mangueira,
  },
  data: () => ({
    formataNumero,
    store: useStore(),
    usoSelecionado: null,
    FormsAreaExternaEnum,
  }),
  computed: {
    possuiCarro() {
      return this.store.getConfigHabitacao.temCarro;
    },
    possuiPiscina() {
      return this.store.getConfigHabitacao.temPiscina;
    },
    possuiDuchaExterna() {
      return this.store.getConfigHabitacao.temPiscina;
    },
    possuiJardim() {
      return this.store.getConfigHabitacao.temJardim;
    },
    carro() {
      return parseFloat(this.store.getConsumoLavagemCarroMes());
    },
    piscina() {
      return parseFloat(this.store.getConsumoPiscinaMes);
    },
    duchaExterna() {
      return parseFloat(this.store.getConsumoDuchaExternaMes());
    },
    regaJardim() {
      return parseFloat(this.store.getConsumoRegaJardimMes);
    },
    mangueira() {
      return parseFloat(
        this.store.getConsumoLavagemPatioMes() +
          this.store.getConsumoLavagemCalcadaMes()
      );
    },
    carroRedutores() {
      return parseFloat(this.store.getConsumoLavagemCarroMes(true));
    },
    duchaExternaRedutores() {
      return parseFloat(this.store.getConsumoDuchaExternaMes(true));
    },
    mangueiraRedutores() {
      return parseFloat(
        this.store.getConsumoLavagemPatioMes(true) +
          this.store.getConsumoLavagemCalcadaMes(true)
      );
    },
    total() {
      return (
        this.carro +
        this.piscina +
        this.duchaExterna +
        this.regaJardim +
        this.mangueira
      );
    },
    totalRedutor() {
      return (
        this.carroRedutores +
        this.piscina +
        this.duchaExternaRedutores +
        this.regaJardim +
        this.mangueiraRedutores
      );
    },
  },
});
