
import Vue from "vue";
import CustomText from "../components/CustomText.vue";

export default Vue.extend({
  name: "RedutoresInfo",
  components: {
    CustomText,
  },
});
