
import Vue from "vue";
import DialogBanheiro from "../components/banheiro/DialogBanheiro.vue";
import DialogComodoFinalizado from "../components/DialogComodoFinalizado.vue";
import CardFormsBanheiro from "../components/banheiro/CardFormsBanheiro.vue";
import IlustracaoBanheiro from "../components/banheiro/IlustracaoBanheiro.vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import CardListUsosBanheiro from "../components/banheiro/CardListUsosBanheiro.vue";
import { FormsBanheiroEnum } from "../utils/constants";
import { useStore } from "@/store";

export default Vue.extend({
  name: "BanheiroPage",
  components: {
    DialogBanheiro,
    DialogComodoFinalizado,
    IlustracaoBanheiro,
    CardFormsBanheiro,
    ProgressTotalPerCapita,
    CardListUsosBanheiro,
  },
  data: () => ({
    FormsBanheiroEnum,
    store: useStore(),
    dialog: false,
    dialogFinalizado: false,
    dialogRedutores: false,
    formSelecionado: null,
  }),
  computed: {
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
    banheiroFinalizado() {
      return this.store.isBanheiroDone;
    },
  },
  methods: {
    abreForm(form: string) {
      this.dialog = true;
      this.formSelecionado = form;
    },
    fechaForm() {
      this.formSelecionado = null;
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog && this.banheiroFinalizado) {
        this.dialogFinalizado = true;
      }
    },
  },
});
