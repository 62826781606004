
import Vue from "vue";
import { useStore } from "@/store";
import {
  RedutoresTorneiraPiaEnum,
  RedutoresNomes,
} from "../../utils/constants";
import Pia from "../../assets/cozinha/pia.svg";
import RestritorVazao from "../../assets/redutores/restritorVazao.svg";
import Pulverizador from "../../assets/redutores/pulverizador.svg";
import BarrasAntesDepoisRedutor from "../BarrasAntesDepoisRedutor.vue";
import DialogRedutoresInfo from "./DialogRedutoresInfo.vue";

export default Vue.extend({
  name: "RedutoresListTorneiraPia",
  components: {
    Pia,
    RestritorVazao,
    Pulverizador,
    BarrasAntesDepoisRedutor,
    DialogRedutoresInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store: useStore(),
      RedutoresTorneiraPiaEnum,
      redutorSelecionado: null,
      RedutoresNomes,
      redutorInfo: null,
      dialogInfo: false,
    };
  },
  mounted() {
    this.redutorSelecionado = this.store.getConfigRedutores.piaCozinha;
  },
  computed: {
    antesRedutor(): number {
      const soma =
        this.store.getConsumoLavagemAlimentosMes() +
        this.store.getConsumoLavagemLoucaMes();
      return soma;
    },
    depoisRedutor(): number {
      const soma =
        this.store.getConsumoLavagemAlimentosMes(this.redutorSelecionado) +
        this.store.getConsumoLavagemLoucaMes(this.redutorSelecionado);
      return soma;
    },
  },
  methods: {
    abreInfoRedutor(redutor) {
      this.redutorInfo = redutor;
      this.dialogInfo = true;
    },
  },
  watch: {
    redutorSelecionado() {
      this.store.setRedutorTorneiraPia(this.redutorSelecionado || null);
    },
  },
});
