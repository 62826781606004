
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import SubFormLavarPatio from "./SubFormLavarPatio.vue";
import SubFormLavarCalcada from "./SubFormLavarCalcada.vue";
import { useStore } from "@/store";

enum SubForms {
  LAVAR_PATIO = "LAVAR_PATIO",
  LAVAR_CALCADA = "LAVAR_CALCADA",
}

export default Vue.extend({
  name: "FormMangueira",
  components: {
    LayoutCard,
    SubFormLavarPatio,
    SubFormLavarCalcada,
  },
  data() {
    return {
      SubForms,
      selecionado: SubForms.LAVAR_PATIO,
      store: useStore(),
    };
  },
  methods: {
    subformSelecionado(subform: string) {
      this.selecionado = subform;
    },
    goToProximoPasso(subform: string) {
      switch (subform) {
        case SubForms.LAVAR_PATIO:
          if (!this.store.getConsumoHabitacao.lavagemCalcada.feito) {
            this.selecionado = SubForms.LAVAR_CALCADA;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Mangueira completa!");
          }
          break;
        case SubForms.LAVAR_CALCADA:
          if (!this.store.getConsumoHabitacao.lavagemPatio.feito) {
            this.selecionado = SubForms.LAVAR_PATIO;
          } else {
            this.$emit("fechar");
            this.$root.$emit("toast-success", "Mangueira completa!");
          }
          break;
        default:
      }
    },
  },
});
