
import Vue from "vue";
import BanheiroBase from "../../assets/banheiro/base.svg";
import Banheira from "../../assets/banheiro/banheira.svg";
import BanheiraAgua from "../../assets/banheiro/banheiraAgua.svg";
import Bide from "../../assets/banheiro/bide.svg";
import BideAgua from "../../assets/banheiro/bideAgua.svg";
import Box from "../../assets/banheiro/box.svg";
import Chuveiro from "../../assets/banheiro/chuveiro.svg";
import ChuveiroAgua from "../../assets/banheiro/chuveiroAgua.svg";
import Ducha from "../../assets/banheiro/ducha.svg";
import DuchaAgua from "../../assets/banheiro/duchaAgua.svg";
import Planta from "../../assets/banheiro/planta.svg";
import Privada from "../../assets/banheiro/privada.svg";
import PrivadaCaixa from "../../assets/banheiro/privadaCaixa.svg";
import PrivadaValvula from "../../assets/banheiro/privadaValvula.svg";
import PrivadaAgua from "../../assets/banheiro/privadaAgua.svg";
import Cesto from "../../assets/banheiro/cesto.svg";
import Pia from "../../assets/banheiro/pia.svg";
import PiaAgua from "../../assets/banheiro/piaAgua.svg";
import PiaBarba from "../../assets/banheiro/piaBarba.svg";
import PiaEscova from "../../assets/banheiro/piaEscova.svg";
import {
  FormsBanheiroEnum,
  TipoChuveiroEnum,
  TiposDescargaEnum,
  UsosBanheiroEnum,
  EtapasDoPreenchimento,
} from "../../utils/constants";
import { useStore } from "@/store";
import { mapeiaCoords, montaBaseDim } from "@/utils/functions";

const opacidadeAgua = 75;

export default Vue.extend({
  name: "IlustracaoBanheiro",
  props: {
    etapaPreenchimento: {
      type: String,
      default: null,
    },
  },
  components: {
    BanheiroBase,
    Banheira,
    BanheiraAgua,
    Bide,
    BideAgua,
    Box,
    Chuveiro,
    ChuveiroAgua,
    Ducha,
    DuchaAgua,
    Planta,
    Privada,
    PrivadaCaixa,
    PrivadaValvula,
    PrivadaAgua,
    Cesto,
    Pia,
    PiaAgua,
    PiaBarba,
    PiaEscova,
  },
  data() {
    return {
      TiposDescargaEnum,
      TipoChuveiroEnum,
      redutores: true,
      // redutores
      redutoresIconSize: 25,
      redutoresIconBefore: "mdi-water-alert",
      redutoresIconAfter: "mdi-water-check",
      redutoresIconColorBefore: "accent", // blue darken-2",
      redutoresIconColorAfter: "green darken-2",
      animationClassIcons:
        "animate__animated animate__shakeY animate__slower animate__infinite",
      // outros
      FormsBanheiroEnum,
      UsosBanheiroEnum,
      animationClass: "animate__animated animate__bounce",
      opacidadeAgua,
      store: useStore(),
      baseRendered: false,
      hoverImg: null,
      baseDim: {
        left: 0,
        height: 0,
        width: 0,
        scale: 0,
        grayscale: 0,
      },
      mapa: {
        banheira: [101, 633, 351, 487, 452, 546, 450, 634, 202, 780, 100, 719],
        chuveiro: [
          350, 254, 350, 484, 451, 545, 452, 634, 474, 648, 601, 572, 601, 255,
          477, 178,
        ],
        pia: [
          878, 430, 956, 472, 956, 594, 964, 634, 914, 775, 863, 773, 834, 671,
        ],
        privada: [
          778, 539, 811, 558, 825, 607, 804, 695, 763, 718, 743, 705, 723, 686,
          713, 647, 750, 624, 762, 562,
        ],
        bide: [
          747, 604, 690, 567, 620, 608, 637, 671, 681, 688, 712, 675, 707, 648,
          747, 622,
        ],
      },
    };
  },
  created() {
    window.addEventListener("resize", this.setBaseDimensions);
  },
  mounted() {
    this.setBaseDimensions();
  },
  computed: {
    tamanhoBase() {
      if (this.$vuetify.breakpoint.mdOnly) {
        return "65vh";
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return "72vh";
      }
      if (this.$vuetify.breakpoint.xlOnly) {
        return "75vh";
      }
      return "auto";
    },
    configRedutores() {
      return this.store.getConfigRedutores;
    },
    etapaRedutores() {
      return this.etapaPreenchimento === EtapasDoPreenchimento.REDUTORES;
    },
    banheira() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isBanheiraDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "banheira" && !this.store.isBanheiraDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: this.store.getConfigHabitacao.temBanheira !== false,
        class: classe,
        width: this.baseDim.width * 0.349,
        left: 100 * this.baseDim.scale + this.baseDim.left,
        top: 485 * this.baseDim.scale,
      };
    },
    banheiraAgua() {
      return {
        visivel:
          this.store.getConfigHabitacao.temBanheira !== false &&
          this.store.isBanheiraDone,
        grayscale:
          this.hoverImg === "banheira" || this.store.isBanheiraDone ? 0 : 100,
        width: this.baseDim.width * 0.273,
        left: 139 * this.baseDim.scale + this.baseDim.left,
        top: 520 * this.baseDim.scale,
      };
    },
    cesto() {
      return {
        visivel: this.store.getConfigHabitacao.temBanheira === false,
        // grayscale: this.hoverImg === "banheira" ? 0 : 100,
        grayscale: 0,
        width: this.baseDim.width * 0.319,
        left: 84 * this.baseDim.scale + this.baseDim.left,
        top: 536 * this.baseDim.scale,
      };
    },
    bide() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isBideDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "bide" && !this.store.isBideDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: this.store.getConfigHabitacao.temBide !== false,
        class: classe,
        width: this.baseDim.width * 0.128,
        left: 620 * this.baseDim.scale + this.baseDim.left,
        top: 568 * this.baseDim.scale,
      };
    },
    bideAgua() {
      return {
        visivel:
          this.store.getConfigHabitacao.temBide !== false &&
          this.store.isBideDone,
        grayscale: this.hoverImg === "bide" || this.store.isBideDone ? 0 : 100,
        width: this.baseDim.width * 0.043,
        left: 641 * this.baseDim.scale + this.baseDim.left,
        top: 586 * this.baseDim.scale,
      };
    },
    box() {
      return {
        visivel: true,
        grayscale:
          this.hoverImg === "chuveiro" || this.store.isChuveiroDone ? 0 : 100,
        width: this.baseDim.width * 0.25,
        left: 348 * this.baseDim.scale + this.baseDim.left,
        top: 250 * this.baseDim.scale,
        iconLeft: 435 * this.baseDim.scale + this.baseDim.left,
        iconTop: 140 * this.baseDim.scale,
      };
    },
    chuveiro() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isChuveiroDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "chuveiro" && !this.store.isChuveiroDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel:
          this.store.getConfigHabitacao.tipoChuveiro ===
          TipoChuveiroEnum.CHUVEIRO_ELETRICO,
        class: classe,
        width: this.baseDim.width * 0.031,
        left: 504 * this.baseDim.scale + this.baseDim.left,
        top: 233 * this.baseDim.scale,
      };
    },
    chuveiroAgua() {
      return {
        visivel:
          this.store.isChuveiroDone &&
          this.store.getConfigHabitacao.tipoChuveiro ===
            TipoChuveiroEnum.CHUVEIRO_ELETRICO,
        grayscale:
          this.hoverImg === "chuveiro" || this.store.isChuveiroDone ? 0 : 100,
        width: this.baseDim.width * 0.124,
        left: 418 * this.baseDim.scale + this.baseDim.left,
        top: 263 * this.baseDim.scale,
      };
    },
    ducha() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isChuveiroDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "chuveiro" && !this.store.isChuveiroDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel:
          this.store.getConfigHabitacao.tipoChuveiro ===
            TipoChuveiroEnum.DUCHA ||
          this.store.getConfigHabitacao.tipoChuveiro === null,
        class: classe,
        width: this.baseDim.width * 0.055,
        left: 485 * this.baseDim.scale + this.baseDim.left,
        top: 214 * this.baseDim.scale,
      };
    },
    duchaAgua() {
      return {
        visivel:
          this.store.isChuveiroDone &&
          this.store.getConfigHabitacao.tipoChuveiro === TipoChuveiroEnum.DUCHA,
        grayscale:
          this.hoverImg === "chuveiro" || this.store.isChuveiroDone ? 0 : 100,
        width: this.baseDim.width * 0.124,
        left: 400 * this.baseDim.scale + this.baseDim.left,
        top: 246 * this.baseDim.scale,
      };
    },
    planta() {
      return {
        visivel: this.store.getConfigHabitacao.temBide === false,
        // grayscale: this.hoverImg === "bide" ? 0 : 100,
        grayscale: 0,
        width: this.baseDim.width * 0.13,
        left: 603 * this.baseDim.scale + this.baseDim.left,
        top: 524 * this.baseDim.scale,
      };
    },
    privada() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isDescargaDone) {
          classe = "svg-shadow-done";
        } else if (this.hoverImg === "privada" && !this.store.isDescargaDone) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.112,
        left: 710 * this.baseDim.scale + this.baseDim.left,
        top: 541 * this.baseDim.scale,
        iconLeft: 725 * this.baseDim.scale + this.baseDim.left,
        iconTop: 451 * this.baseDim.scale,
      };
    },
    privadaValvula() {
      return {
        visivel:
          this.store.getConfigHabitacao.tipoDescarga ===
          TiposDescargaEnum.VALVULA,
        grayscale: this.hoverImg === "privada" ? 0 : 100,
        width: this.baseDim.width * 0.025,
        left: 783 * this.baseDim.scale + this.baseDim.left,
        top: 485 * this.baseDim.scale,
      };
    },
    privadaCaixa() {
      return {
        visivel: [
          TiposDescargaEnum.CAIXA_DUPLA,
          TiposDescargaEnum.CAIXA_SIMPLES,
        ].includes(this.store.getConfigHabitacao.tipoDescarga),
        grayscale: this.hoverImg === "privada" ? 0 : 100,
        width: this.baseDim.width * 0.102,
        left: 755 * this.baseDim.scale + this.baseDim.left,
        top: 528 * this.baseDim.scale,
      };
    },
    privadaAgua() {
      return {
        visivel: this.store.isDescargaDone,
        grayscale:
          this.hoverImg === "privada" || this.store.isDescargaDone ? 0 : 100,
        width: this.baseDim.width * 0.056,
        left: 727 * this.baseDim.scale + this.baseDim.left,
        top: 642 * this.baseDim.scale,
      };
    },
    pia() {
      let classe;
      if (this.etapaRedutores) {
        classe = "";
      } else {
        if (this.store.isTorneiraLavatorioDone) {
          classe = "svg-shadow-done";
        } else if (
          this.hoverImg === "pia" &&
          !this.store.isTorneiraLavatorioDone
        ) {
          classe = "svg-shadow";
        } else {
          classe = "svg-shadow-grey";
        }
      }
      return {
        visivel: true,
        class: classe,
        width: this.baseDim.width * 0.128,
        left: 834 * this.baseDim.scale + this.baseDim.left,
        top: 430 * this.baseDim.scale,
        iconLeft: 864 * this.baseDim.scale + this.baseDim.left,
        iconTop: 400 * this.baseDim.scale,
      };
    },
    piaAgua() {
      return {
        visivel: this.store.isTorneiraLavatorioDone,
        grayscale:
          this.hoverImg === "pia" || this.store.isTorneiraLavatorioDone
            ? 0
            : 100,
        width: this.baseDim.width * 0.07,
        left: 849 * this.baseDim.scale + this.baseDim.left,
        top: 626 * this.baseDim.scale,
      };
    },
    piaBarba() {
      return {
        visivel: true,
        grayscale:
          this.hoverImg === "pia" || this.store.isTorneiraLavatorioDone
            ? 0
            : 100,
        width: this.baseDim.width * 0.029,
        left: 858 * this.baseDim.scale + this.baseDim.left,
        top: 573 * this.baseDim.scale,
      };
    },
    piaEscova() {
      return {
        visivel: true,
        grayscale:
          this.hoverImg === "pia" || this.store.isTorneiraLavatorioDone
            ? 0
            : 100,
        width: this.baseDim.width * 0.016,
        left: 931 * this.baseDim.scale + this.baseDim.left,
        top: 600 * this.baseDim.scale,
      };
    },
  },
  methods: {
    setBaseDimensions() {
      this.baseRendered = false;
      setTimeout(() => {
        const base = document.getElementById("banheiroBase");
        if (base) {
          this.baseDim = montaBaseDim(base, 1002);
          this.baseRendered = true;
        }
      }, 100);
    },
    mapeiaCoords(shape: string) {
      return mapeiaCoords(this.mapa[shape], this.baseDim);
    },
  },
});
