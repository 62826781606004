
import Vue from "vue";
import CustomText from "./CustomText.vue";

export default Vue.extend({
  name: "FormItem",
  props: {
    pessoa: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    CustomText,
  },
});
