
import Vue from "vue";
import FormMaquinaRoupa from "./FormMaquinaRoupa.vue";
import FormTorneira from "./FormTorneira.vue";
import { FormsLavanderiaEnum } from "../../utils/constants";

export default Vue.extend({
  name: "DialogLavanderia",
  components: {
    FormMaquinaRoupa,
    FormTorneira,
  },
  props: {
    formSelecionado: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    FormsLavanderiaEnum,
  }),
});
