
import Vue from "vue";
import { StatusEtapasEnum } from "@/utils/constants";
import TabsHabitacao from "../TabsHabitacao.vue";
import FormLayoutHabitacao from "../FormLayoutHabitacao.vue";
import FormItem from "../FormItem.vue";
import TextSliderInputs from "../TextSliderInputs.vue";
import ConsumoField from "../ConsumoField.vue";
import ConfirmarButton from "../ConfirmarButton.vue";
import UltimoPassoButton from "../UltimoPassoButton.vue";
import { useStore } from "@/store";
import { getConsumoPiscinaMes } from "@/utils/functions";

export default Vue.extend({
  name: "SubFormPiscina",
  components: {
    TabsHabitacao,
    FormLayoutHabitacao,
    FormItem,
    TextSliderInputs,
    ConsumoField,
    ConfirmarButton,
    UltimoPassoButton,
  },
  props: {
    selecionado: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    store: useStore(),
    form: {
      volumePiscina: 0,
      piscinaCheiaAno: 0,
    },
    etapas: [],
  }),
  mounted() {
    this.etapas.push(
      ...[
        {
          status: this.store.getConsumoHabitacao.piscina.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.FAZENDO,
        },
        {
          status: this.store.getConsumoHabitacao.piscina.feito
            ? StatusEtapasEnum.COMPLETA
            : StatusEtapasEnum.INCOMPLETA,
        },
      ]
    );
    this.form.volumePiscina = this.store.getConsumoHabitacao.piscina.volume;
    this.form.piscinaCheiaAno =
      this.store.getConsumoHabitacao.piscina.vezesPorAno;
  },
  computed: {
    piscinaCompleta() {
      return this.store.isPiscinaDone;
    },
    totalPiscina() {
      return getConsumoPiscinaMes(
        this.form.volumePiscina,
        this.form.piscinaCheiaAno
      );
    },
  },
  methods: {
    voltar() {
      this.etapas[0].status = StatusEtapasEnum.FAZENDO;
      this.etapas[1].status = StatusEtapasEnum.INCOMPLETA;
    },
    finalizaPiscina() {
      this.etapas[0].status = StatusEtapasEnum.COMPLETA;
      this.etapas[1].status = StatusEtapasEnum.FAZENDO;

      this.store.setDadosPiscina(
        this.form.volumePiscina,
        this.form.piscinaCheiaAno
      );
      this.$root.$emit("toast-success");
    },
  },
});
