var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.mostraFooter)?_c('v-footer',{attrs:{"color":"primary lighten-1","padless":"","fixed":!_vm.$vuetify.breakpoint.smAndDown}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[(!_vm.inicioOk)?[_c('IconeFooter',{attrs:{"rota":"HabitacaoPage","selecionado":_vm.selecionado('HabitacaoPage'),"preenchido":_vm.selecionado('HabitacaoPage') ? false : !!_vm.habitacaoStore},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.habitacaoStore === _vm.HabitacoesEnum.APARTAMENTO)?_c('PredioIcon',{style:(_vm.selecionado('HabitacaoPage')
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}}):_c('CasaIcon',{style:(_vm.selecionado('HabitacaoPage')
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}})]},proxy:true}],null,false,1130707405)}),_c('IconeFooter',{attrs:{"rota":"TipoUsoPage","selecionado":_vm.selecionado('TipoUsoPage'),"preenchido":_vm.selecionado('TipoUsoPage') ? false : !!_vm.tipoUsoStore},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.tipoUsoStore === _vm.TiposUsoEnum.INDIVIDUAL)?_c('PessoaIcon',{style:(_vm.selecionado('TipoUsoPage')
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}}):_c('GrupoPessoasIcon',{style:(_vm.selecionado('TipoUsoPage')
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}})]},proxy:true}],null,false,23010291)})]:(_vm.mostraIconesComodos)?[_c('IconeFooter',{attrs:{"rota":_vm.isEtapaRedutores ? 'BanheiroRedutoresPage' : 'BanheiroPage',"selecionado":_vm.selecionado(
            _vm.isEtapaRedutores ? 'BanheiroRedutoresPage' : 'BanheiroPage'
          ),"preenchido":(!_vm.isEtapaRedutores && _vm.banheiroFinalizado) ||
          (_vm.isEtapaRedutores && _vm.store.getConfigRedutores.redutoresBanheiroDone)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BanheiroIcon',{style:(_vm.selecionado('BanheiroPage') || _vm.isEtapaRedutores
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}})]},proxy:true}],null,false,856787757)}),_c('IconeFooter',{attrs:{"rota":_vm.isEtapaRedutores ? 'CozinhaRedutoresPage' : 'CozinhaPage',"selecionado":_vm.selecionado(
            _vm.isEtapaRedutores ? 'CozinhaRedutoresPage' : 'CozinhaPage'
          ),"preenchido":(!_vm.isEtapaRedutores && _vm.cozinhaFinalizada) ||
          (_vm.isEtapaRedutores && _vm.store.getConfigRedutores.redutoresCozinhaDone)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CozinhaIcon',{style:(_vm.selecionado('CozinhaPage') || _vm.isEtapaRedutores
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}})]},proxy:true}],null,false,1896574029)}),_c('IconeFooter',{attrs:{"rota":_vm.isEtapaRedutores ? 'LavanderiaRedutoresPage' : 'LavanderiaPage',"selecionado":_vm.selecionado(
            _vm.isEtapaRedutores ? 'LavanderiaRedutoresPage' : 'LavanderiaPage'
          ),"preenchido":(!_vm.isEtapaRedutores && _vm.lavanderiaFinalizada) ||
          (_vm.isEtapaRedutores &&
            _vm.store.getConfigRedutores.redutoresLavanderiaDone)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('LavanderiaIcon',{style:(_vm.selecionado('LavanderiaPage') || _vm.isEtapaRedutores
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight}})]},proxy:true}],null,false,1332169485)}),(_vm.habitacaoStore === _vm.HabitacoesEnum.CASA)?_c('IconeFooter',{attrs:{"rota":_vm.isEtapaRedutores ? 'AreaExternaRedutoresPage' : 'AreaExternaPage',"selecionado":_vm.selecionado(
            _vm.isEtapaRedutores ? 'AreaExternaRedutoresPage' : 'AreaExternaPage'
          ),"preenchido":(!_vm.isEtapaRedutores && _vm.areaExternaFinalizada) ||
          (_vm.isEtapaRedutores &&
            _vm.store.getConfigRedutores.redutoresAreaExternaDone)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('QuintalIcon',{style:(_vm.selecionado('AreaExternaPage') || _vm.isEtapaRedutores
                ? 'filter: grayscale(0%)'
                : 'filter: grayscale(100%)'),attrs:{"height":_vm.iconPixelHeight,"width":_vm.iconPixelHeight}})]},proxy:true}],null,false,3532597725)}):_vm._e()]:_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }