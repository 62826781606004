
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import PrivadaValvula from "../../assets/banheiro/privadaValvula.svg";
import PrivadaCaixa from "../../assets/banheiro/privadaCaixa.svg";
import OpcoesPanel from "../OpcoesPanel.vue";
import SubFormDescarga from "./SubFormDescarga.vue";
import { useStore } from "@/store";
import { TiposDescargaEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormDescarga",
  components: {
    LayoutCard,
    OpcoesPanel,
    PrivadaValvula,
    PrivadaCaixa,
    SubFormDescarga,
  },
  data() {
    return {
      TiposDescargaEnum,
      selecionado: false,
      store: useStore(),
      itemSelecionado: null,
      fecharPainel: false,
    };
  },
  mounted() {
    this.itemSelecionado = this.store.getConfigHabitacao.tipoDescarga;
  },
  computed: {
    tituloOpcoes() {
      if (this.store.getConfigHabitacao.tipoDescarga) {
        return "Tipo selecionado";
      }
      return "Selecione um tipo";
    },
    subtitulo() {
      return this.itemSelecionado || null;
    },
  },
  methods: {
    subformClick() {
      if (this.store.getConfigHabitacao.tipoDescarga) {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Descarga completa!");
    },
  },
  watch: {
    itemSelecionado() {
      this.store.setDescarga(this.itemSelecionado);
      this.fecharPainel = !this.fecharPainel;
      this.selecionado = true;
    },
  },
});
