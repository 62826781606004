
import Vue from "vue";
import LayoutCard from "../LayoutCard.vue";
import Chuveiro from "../../assets/banheiro/chuveiro.svg";
import Ducha from "../../assets/banheiro/ducha.svg";
import OpcoesPanel from "../OpcoesPanel.vue";
import SubFormChuveiro from "./SubFormChuveiro.vue";
import { useStore } from "@/store";
import { TipoChuveiroEnum } from "@/utils/constants";

export default Vue.extend({
  name: "FormChuveiro",
  components: {
    LayoutCard,
    OpcoesPanel,
    Chuveiro,
    Ducha,
    SubFormChuveiro,
  },
  data() {
    return {
      TipoChuveiroEnum,
      selecionado: false,
      store: useStore(),
      itemSelecionado: null,
      fecharPainel: false,
    };
  },
  mounted() {
    this.itemSelecionado = this.store.getConfigHabitacao.tipoChuveiro;
  },
  computed: {
    tituloOpcoes() {
      if (this.store.getConfigHabitacao.tipoChuveiro) {
        return "Tipo selecionado";
      }
      return "Selecione um tipo";
    },
    subtitulo() {
      return this.itemSelecionado || null;
    },
  },
  methods: {
    subformClick() {
      if (this.store.getConfigHabitacao.tipoChuveiro) {
        this.selecionado = true;
      }
    },
    goToProximoPasso() {
      this.$emit("fechar");
      this.$root.$emit("toast-success", "Chuveiro completo!");
    },
  },
  watch: {
    itemSelecionado() {
      this.store.setChuveiro(this.itemSelecionado);
      this.fecharPainel = !this.fecharPainel;
      this.selecionado = true;
    },
  },
});
