
import Vue from "vue";
import IlustracaoBanheiro from "../components/banheiro/IlustracaoBanheiro.vue";
import ProgressTotalPerCapita from "../components/ProgressTotalPerCapita.vue";
import DialogRedutoresConsumo from "../components/DialogRedutoresConsumo.vue";
import DialogRedutoresFinalizados from "../components/DialogRedutoresFinalizados.vue";
import ButtonFinalizaComodo from "../components/redutores/ButtonFinalizaComodo.vue";
import CardRedutoresLists from "../components/redutores/CardRedutoresLists.vue";
import { useStore } from "@/store";
import { ComodosEnum, EtapasDoPreenchimento } from "@/utils/constants";

export default Vue.extend({
  name: "BanheiroRedutoresPage",
  components: {
    CardRedutoresLists,
    DialogRedutoresFinalizados,
    DialogRedutoresConsumo,
    IlustracaoBanheiro,
    ProgressTotalPerCapita,
    ButtonFinalizaComodo,
  },
  data: () => ({
    EtapasDoPreenchimento,
    store: useStore(),
    dialog: false,
    dialogRedutores: false,
    dialogFimRedutores: false,
    usoRedutoresSelecionado: null,
    styleBotao: null,
  }),
  mounted() {
    setTimeout(() => {
      const box = document.getElementById("divIlustra");
      if (box) {
        this.styleBotao = `position: relative; top: ${
          box.clientHeight - 100
        }px; width: 100%`;
      }
    }, 500);
  },
  computed: {
    etapaPreenchimento() {
      return this.store.getEtapaPreenchimento || null;
    },
  },
  methods: {
    abreRedutores(usoRedutoresSelecionado) {
      this.usoRedutoresSelecionado = usoRedutoresSelecionado;
      this.dialogRedutores = true;
    },
    finalizaComodo() {
      this.store.finalizaComodo(ComodosEnum.BANHEIRO);
      this.$root.$emit("toast-success", "Cômodo finalizado");
      this.$router.push({ name: "SelecionarComodo" });
    },
  },
});
